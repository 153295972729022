import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 14;
  height: 50px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  padding: 0 40px;
  @media (max-width: 999px) {
    transition: top 0.3s;
    top: ${props => (props.shortHeader ? '70px' : '')};
  }
`;

export const Category = styled.div`
  width: 30%;
  text-align: left;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const SelectCategoryToScroll = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const CurrentCategory = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
`;

export const Icon = styled.div`
  position: absolute;
  left: calc(100% - 25px);
  &.active {
    transform: rotate(180deg);
  }
`;

export const Dropdown = styled.ul`
  position: absolute;
  list-style: none;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 100%;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  max-height: 330px;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 15;
  margin-bottom: 0;
  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

export const Option = styled.li`
  padding: 10px;
  transition: background-color 0.3s;
  font-size: 16px;
  position: relative;
  padding: 15px 20px;
  border-bottom: 1px solid #f5f0eb;
  &:hover {
    background-color: #f1f1f1;
    font-weight: 500;
  }
  &.selected {
    background-color: #e6e6e6;
    font-weight: 500;
  }
`;

export const Search = styled.div`
  width: 70%;
  display: flex;
  padding-left: 5%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const SearchBox = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  cursor: text;
  width: 100%;
`;

export const InputSearch = styled.input`
  border: 0;
  margin-left: 5px;
  padding: 5px;
  width: 100%;
`;
