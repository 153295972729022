/* eslint-disable func-names */
/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import currencyFormatter from 'currency-formatter';

const USER_KEY = `${window.location.host}__USER_NEW`;
const CART_KEY = `${window.location.host}_CART_KEY`;
const TABLE_ACCOUNT_MODE = `${window.location.host}_TABLE_ACCOUNT_MODE`;
const TAKE_AWAY_KEY = `${window.location.host}_TAKE_AWAY_KEY`;
const CASHBACK_CONFIG = `${window.location.host}_CASHBACK_CONFIG`;
const ORDER_REVIEW = `${window.location.host}_ORDER_REVIEW`;
const TEMP_ORDERS = `${window.location.host}_ORDERS`;
const STORAGE_USER = `${window.location.host}_TEMP_USER`;
const SELECTED_ADDRESS = `${window.location.host}_SELECTED_ADDRESS_NEW`;
const SELECTED_STORE = `${window.location.host}_SELECTED_STORE_NEW`;
const TRACK_ID = `${window.location.host}_TRACK_ID`;
/**
 *
 * @param {number} storeId
 * @returns string
 */
const OPEN_HOUR_STORE = storeId =>
  `${window.location.host}_OPEN_HOUR_STORE_${storeId}`;
const TABLE_ACCOUNT_ORDER = `${window.location.host}_TABLE_ACCOUNT_ORDER`;

export const countSelectedOptions = step => {
  const formDataItems = step.options.filter(
    ({ id }) => step.formData[id] && step.formData[id].checked === true
  );

  const countSelectedOptionals = step.options.reduce(
    (previusValue, currentValue, index, items) => {
      return previusValue + items[index].quantity;
    },
    formDataItems.length
  );
  return countSelectedOptionals;
};

/**
 *
 * @param {string} url
 * @returns string
 */
export const getAssetFolter = url => {
  const subdomain = url.split('/')[2].split('.')[0];

  return `https://d26aylt6qzro7d.cloudfront.net/site-icons/${subdomain}`;
};

export const createStoreEncodedName = storeName =>
  `${storeName?.replace(/[^a-z0-9]|\s+|\r?\n|\r/gim, '-')}`;

export const createStoreEncodedCategoryName = storeName =>
  `${storeName?.replace(/[^a-z0-9\u00C0-\u017F]|\s+|\r?\n|\r/gim, '-')}`;

export const toBlr = value => {
  return currencyFormatter.format(value / 100, {
    symbol: 'R$ ',
    decimal: ',',
    thousand: '.',
    precision: 2,
  });
};

export const sumOptions = options => {
  const total = options.reduce((previusValue, currentValue, index, items) => {
    return previusValue + items[index].quantity;
  }, 0);
  return total;
};

export const calcProductTotalValue = ({
  cartProduct,
  multiplyQuantity = false,
  format = true,
}) => {
  if (!cartProduct) return;
  const { price, priceDelivery, wizardSteps = [] } = cartProduct;
  let totalPrice = priceDelivery || price || 0;
  wizardSteps.forEach(variation => {
    const options = variation.options
      .filter(
        ({ quantity, id }) =>
          quantity ||
          (variation.formData[id] && variation.formData[id].checked === true)
      )
      .map(item => ({
        ...item,
        quantity: item.quantity || 1,
      }));
    if (options.length === 0) return;
    const multi = variation.type === 2 ? 1 / sumOptions(options) : 1;
    options.forEach(option => {
      totalPrice += (option.price || 0) * option.quantity * multi;
    });
  });
  if (multiplyQuantity) {
    totalPrice *= cartProduct.quantity;
  }

  if (format === undefined || format === true) {
    // eslint-disable-next-line consistent-return
    return toBlr(totalPrice);
  }
  // eslint-disable-next-line consistent-return
  return totalPrice;
};

export const calcTotalCart = ({
  cartProducts = [],
  deliveryFee = 0,
  format = true,
  discount = 0,
  cashback = 0,
}) => {
  const subtotal = cartProducts.reduce(
    (previusValue, currentValue, index, items) => {
      const sum = calcProductTotalValue({
        cartProduct: items[index],
        sumTotal: true,
        format: false,
        multiplyQuantity: true,
      });
      return previusValue + sum;
    },
    0
  );

  const total = subtotal + deliveryFee - discount - cashback;

  // if total is negative number
  if (Math.sign(total) === -1) {
    if (format) return toBlr(deliveryFee);
    return deliveryFee;
  }

  if (format) return toBlr(total);
  return total;
};

export const calcPartialCashback = (currentCustomerValue, cart) => {
  return currentCustomerValue >= cart.total
    ? cart.total - cart.discount
    : currentCustomerValue;
};

export const getFraction = decimal => {
  let denominator = 1;
  for (; (decimal * denominator) % 1 !== 0; denominator += 1);
  return { numerator: decimal * denominator, denominator };
};

export const getMinPrice = product => {
  if (product.price) {
    return toBlr(product.price);
  }
  let minProductPrice = 0;
  if (product.items) {
    product.items.forEach(item => {
      if (item.min >= 1) {
        const [optionMinPrice] = item.items.sort((a, b) => a.price - b.price);
        if (!optionMinPrice) return;
        minProductPrice += optionMinPrice.price * item.min;
      }
    });
  }
  return toBlr(minProductPrice);
};

export const isUserLogged = () => {
  const user = localStorage.getItem(USER_KEY);

  if (!user) return false;
  return true;
};

export const logout = () => {
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(CASHBACK_CONFIG);
};

export const getTrackId = () => localStorage.getItem(TRACK_ID);

export const setTrackId = salesTrackId =>
  localStorage.setItem(TRACK_ID, salesTrackId);

export const getUser = () => {
  const user = localStorage.getItem(USER_KEY);
  if (!user) return null;
  return JSON.parse(user);
};

export const storeUser = customer => {
  const newCustomer = { ...customer };
  delete newCustomer.addressList;
  localStorage.setItem(USER_KEY, JSON.stringify(newCustomer));
};

export const storeCart = cart => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
};

export const clearCart = () => {
  localStorage.removeItem(CART_KEY);
};

export const getCart = () => {
  const cart = localStorage.getItem(CART_KEY);
  if (!cart) return null;
  return JSON.parse(cart);
};

export const storeCashback = cashbackConfigs => {
  localStorage.setItem(CASHBACK_CONFIG, JSON.stringify(cashbackConfigs));
};

export const removeCashback = () => {
  localStorage.removeItem(CASHBACK_CONFIG);
};

export const getCashback = () => {
  const cashback = localStorage.getItem(CASHBACK_CONFIG);
  if (!cashback) return {};
  return JSON.parse(cashback);
};

export const getSelectedAddress = () => {
  const address = localStorage.getItem(SELECTED_ADDRESS);
  if (!address || address === 'undefined' || address === 'null') return null;
  const addressObjecrt = JSON.parse(address);
  if (
    !addressObjecrt.zip ||
    `${addressObjecrt.zip}`.replace('-', '').length < 8
  ) {
    return null;
  }

  return addressObjecrt;
};

export const storeSelectedAddress = address => {
  localStorage.setItem(SELECTED_ADDRESS, JSON.stringify(address));
};

export const removeSelectedAddress = () => {
  localStorage.removeItem(SELECTED_ADDRESS);
};

export const getSelectedStore = () => {
  const store = localStorage.getItem(SELECTED_STORE);
  if (!store) return null;
  return JSON.parse(store);
};

export const storeSelectedStore = store => {
  localStorage.setItem(SELECTED_STORE, JSON.stringify(store));
};
/**
 *
 * @param {number} storeId
 * @param {string} openHours
 */
export const storeOpenHours = (storeId, openHours) => {
  localStorage.setItem(
    OPEN_HOUR_STORE(storeId),
    JSON.stringify({ storeId, openHours })
  );
};
/**
 *
 * @param {number} storeId
 * @returns string | null
 */
export const getStoreOpenHours = storeId => {
  const hours = localStorage.getItem(OPEN_HOUR_STORE(storeId));
  if (!hours) {
    return null;
  }
  return JSON.parse(hours).openHours;
};

export const removeSelectedStore = () => {
  localStorage.removeItem(SELECTED_STORE);
};

export const getTableAccountOrder = () => {
  const orderId = sessionStorage.getItem(TABLE_ACCOUNT_ORDER);
  if (!orderId) return null;
  return orderId;
};

export const storeTableAccountOrder = orderId => {
  sessionStorage.setItem(TABLE_ACCOUNT_ORDER, orderId);
};

export const getTempIdOrders = hasLogin => {
  if (hasLogin) return null;
  const orders = localStorage.getItem(TEMP_ORDERS);
  const parsedOrders = JSON.parse(orders);
  if (parsedOrders) return parsedOrders.orders;
  return null;
};

export const setTempIdOrders = (id, hasLogin) => {
  if (hasLogin) return;

  const orders = getTempIdOrders();
  if (orders) {
    orders.push(id);
    localStorage.setItem(TEMP_ORDERS, JSON.stringify({ orders }));
  } else {
    localStorage.setItem(TEMP_ORDERS, JSON.stringify({ orders: [id] }));
  }
};

export const setStorageUser = customer => {
  localStorage.setItem(STORAGE_USER, JSON.stringify(customer));
};

export const getStorageUser = () => {
  const customer = localStorage.getItem(STORAGE_USER);
  return JSON.parse(customer);
};

export const removeStorageUser = () => {
  localStorage.removeItem(STORAGE_USER);
};

export const removeTakeAwayMode = () => {
  sessionStorage.removeItem(TAKE_AWAY_KEY);
};

export const storeTakeAwayMode = isTakeAway => {
  sessionStorage.setItem(TAKE_AWAY_KEY, JSON.stringify(isTakeAway));
};

export const getTakeAwayMode = () => {
  const isTakeAway = JSON.parse(
    sessionStorage.getItem(TAKE_AWAY_KEY) || 'false'
  );
  return !!isTakeAway;
};

export const removeTableAccountMode = () => {
  sessionStorage.removeItem(TABLE_ACCOUNT_MODE);
};

export const storeTableAccountMode = isTakeAway => {
  sessionStorage.setItem(TABLE_ACCOUNT_MODE, JSON.stringify(isTakeAway));
};

export const getTableAccountMode = () => {
  const QRMode = sessionStorage.getItem(TABLE_ACCOUNT_MODE);
  if (!QRMode) return null;
  return JSON.parse(QRMode);
};

export const storeOrderReview = order => {
  localStorage.setItem(ORDER_REVIEW, JSON.stringify(order));
};

export const removeOrderReview = () => {
  localStorage.removeItem(ORDER_REVIEW);
};

export const orderReview = () => {
  const order = localStorage.getItem(ORDER_REVIEW);
  if (!order) return {};
  return JSON.parse(order);
};

export const statusToText = status => {
  const statuses = {
    20: 'Criado',
    30: 'Recebido na loja',
    50: 'Em produção',
    70: 'Saiu para entrega',
    60: 'Pronto para entrega',
    80: 'Finalizado',
    200: 'Cancelado',
  };
  return statuses[parseInt(status, 10)] || status;
};

export const validateCpf = cpf => {
  if (!cpf) return false;
  const cpfNumber = cpf.replace(/[^\d]+/g, '');
  if (cpfNumber === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpfNumber.length !== 11 ||
    cpfNumber === '00000000000' ||
    cpfNumber === '11111111111' ||
    cpfNumber === '22222222222' ||
    cpfNumber === '33333333333' ||
    cpfNumber === '44444444444' ||
    cpfNumber === '55555555555' ||
    cpfNumber === '66666666666' ||
    cpfNumber === '77777777777' ||
    cpfNumber === '88888888888' ||
    cpfNumber === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i += 1)
    add += parseInt(cpfNumber.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpfNumber.charAt(9), 10)) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i += 1)
    add += parseInt(cpfNumber.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpfNumber.charAt(10), 10)) return false;
  return true;
};

const searchProduct = (productId, items) => {
  const product = items.find(item => item.id === productId);
  return product;
};

export const findProductOnCategories = (productId, categories) => {
  let product;
  categories.forEach(categoryLevel1 => {
    const productFindedLevel1 = searchProduct(productId, categoryLevel1.items);
    if (productFindedLevel1) product = productFindedLevel1;

    categoryLevel1.categories.forEach(categoryLevel2 => {
      const productFindedLevel2 = searchProduct(
        productId,
        categoryLevel2.items
      );
      if (productFindedLevel2) product = productFindedLevel2;
    });
  });
  return product;
};

export const findCategory = (categoryId, categories) => {
  const categoryFindedLevel1 = categories.find(
    category => `${category.id}` === `${categoryId}`
  );
  if (categoryFindedLevel1)
    return { category: categoryFindedLevel1, parentCategoryId: false };

  let category;
  categories.forEach(categoryLevel1 => {
    const categoryFindedLevel2 = categoryLevel1.categories.find(
      categoryLvl2 => `${categoryLvl2.id}` === `${categoryId}`
    );
    if (categoryFindedLevel2)
      category = {
        category: categoryFindedLevel2,
        parentCategoryId: categoryLevel1.id,
      };
  });

  if (!category) {
    return { category: false, parentCategoryId: false };
  }
  return category;
};

// format to xxxxx-xxx
export const formatCep = cep => [cep.slice(0, 5), cep.slice(5)].join('-');

// format to xxx.xxx.xxx-xx
export const formatCPF = cpf =>
  cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

// format to xx.xxx.xxx/xxxx-xx
export const formatCNPJ = cnpj => {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

// format to (xx) xxxxx-xxxx
export const formatPhone = phone => {
  if (phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
};

// se for uma cor muito clara retorna false
export const colorIsReadableOnBackgroundWhite = color => {
  if (!color) {
    return true;
  }

  if (color.search('#') === -1) {
    return false;
  }

  const c = color.substring(1);
  const rgb = parseInt(c, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  if (luma > 190) return false;
  return true;
};

export const getUniqueByKey = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()];
};

export const findElementPosition = obj => {
  let curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
      // eslint-disable-next-line no-param-reassign
    } while ((obj = obj.offsetParent));
    return curtop;
  }
  return false;
};

export const isMobile = () => window.innerWidth <= 1000;

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const getDeliveryFee = deliveryFee => {
  if (deliveryFee !== undefined) {
    return deliveryFee;
  }
  if (getSelectedStore() && getSelectedStore().deliveryFee) {
    return getSelectedStore().deliveryFee;
  }
  return 0;
};

export const usePromoCode = ({
  percent,
  discountId,
  money,
  zeroDeliveryFee,
  promocodeId,
  discountDescription,
  applyPromocode,
  applyZeroDelivery,
}) => {
  if (zeroDeliveryFee) {
    applyZeroDelivery({
      zeroDeliveryFee,
      promocodeId,
      discountDescription,
      discountId,
    });
  } else {
    applyPromocode({
      percent,
      money,
      zeroDeliveryFee,
      promocodeId,
      discountDescription,
      discountId,
    });
  }
};

export const redirectToStore = ({ history, store }) => {
  if (!store || !store.name) {
    history.push(`/`);
  }
  history.push(`/loja/${createStoreEncodedName(store.name)}`);
};

export const croppText = (text, cropLength = 80) => {
  if (!text) {
    return ' ';
  }
  if (text.length <= cropLength) {
    return text;
  }
  return `${text.substring(0, cropLength)}...`;
};
