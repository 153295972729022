import React from 'react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { Col, FormText, Input, Row } from 'reactstrap';
import { useInternalContext } from '../../Context';
import { useImageContext } from '../../ImageContext';
import { calcProductTotalValue, getSelectedStore } from '../../util';
import { MoneyLabel, ProductName } from './style';

const CartResume = ({
  product,
  wizardSteps,
  sumProduct,
  startStep,
  updateObservation,
  subtractProduct,
  categoryTextColor,
}) => {
  const { openImageViewer } = useImageContext();
  const { config } = useInternalContext();
  const selectedStore = getSelectedStore();
  return product ? (
    <>
      <Row>
        {product.image && (
          <Col
            xs={12}
            style={{ textAlign: 'center' }}
            onClick={() => openImageViewer(product.image)}
          >
            <img
              style={{ width: 'auto', height: 180, maxWidth: 400 }}
              src={`https://d26aylt6qzro7d.cloudfront.net/${
                selectedStore?.merchantId || config.merchantId
              }/${product.image}`}
              alt="produto do sistema o pdv da empresa OPDV"
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <ProductName color={categoryTextColor}>{product.name}</ProductName>
          <br />
          {product.description}
        </Col>
        <Col xs={12} style={{ textAlign: 'center' }}>
          {!startStep && (
            <Row style={{ marginTop: 7 }}>
              <Col>
                <FaMinusCircle
                  color="red"
                  size={24}
                  style={{ cursor: 'pointer' }}
                  onClick={subtractProduct}
                />
                <span style={{ margin: '0.5em' }}>{product.quantity || 0}</span>
                {!product.redeemId && (
                  <FaPlusCircle
                    color="green"
                    size={24}
                    style={{ cursor: 'pointer' }}
                    onClick={sumProduct}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row style={{ paddingRight: 24, marginTop: '1.5em' }}>
        <Col xs={12}>
          {!startStep && (
            <Row>
              <Col
                lg={10}
                xs={8}
                style={{
                  fontSize: '0.9em',
                  textAlign: 'right',
                  color: categoryTextColor,
                }}
              >
                Valor total
              </Col>
              <Col xs={4} lg={2} style={{ textAlign: 'right' }}>
                <MoneyLabel color={categoryTextColor}>
                  {calcProductTotalValue({
                    cartProduct: { ...product, wizardSteps },
                    multiplyQuantity: true,
                    format: true,
                  })}
                </MoneyLabel>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {!startStep && updateObservation && (
        <Row style={{ marginTop: '1.5em' }}>
          <Col xs={12}>
            <Input
              placeholder="Observações"
              style={{ width: '100%' }}
              value={product.observation}
              maxlength="124"
              onChange={({ target: { value: observation } }) =>
                updateObservation({ ...product, observation })
              }
            />
            {product.observation && (
              <FormText>Limite de 124 caracteres</FormText>
            )}
          </Col>
        </Row>
      )}
    </>
  ) : null;
};

export default CartResume;
