import moment from 'moment';
import React from 'react';
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  CardFooter,
  ModalFooter,
} from 'reactstrap';
import { statusToText, toBlr } from '../../util';
import { HeaderSmallLabel } from '../../views/Orders/style';
import { Button } from '../button';
import {
  HeaderBigLabel,
  Info,
  InfoProduct,
  InfoSubProduct,
  RowInfo,
  RowProduct,
  RowSubProduct,
} from './style';
import { StarRating } from '../starRating';

export const ModalOrder = ({ order, isOpen, setIsOpen }) => {
  const toggleModal = () => setIsOpen(!isOpen);

  const orderStatusMessage = () => {
    const orderStatus = order.status < 80 ? 'vai ser' : 'foi';
    const orderType =
      order.orderType === 0 ? 'retirado na loja:' : 'entregue no endereço:';
    return `O pedido ${orderStatus} ${orderType}`;
  };

  if (!order) return <div />;
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader>
        <Row>
          <Col xs={7} lg={12}>
            <Row>
              <Col style={{ textAlign: 'left' }} sm={12} lg={4}>
                <HeaderSmallLabel> #{order.id}</HeaderSmallLabel>
              </Col>
              <Col style={{ textAlign: 'left' }} sm={12} lg={8}>
                <HeaderSmallLabel>
                  {moment(order.createdDate).local().format('DD/MM/YYYY HH:mm')}
                </HeaderSmallLabel>
              </Col>
            </Row>
          </Col>
          <Col sm={5} lg={12}>
            <Row>
              <Col style={{ textAlign: 'left' }}>
                <HeaderBigLabel>{statusToText(order.status)}</HeaderBigLabel>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} style={{ textAlign: 'left', fontWeight: 'bold' }}>
            {orderStatusMessage()}
          </Col>
          <Col xs={12} style={{ textAlign: 'left' }}>
            {order.orderType === 0 && (
              <span>
                {order.storeName}: {order.storeAddress} {order.storeNumber}{' '}
                {order.storeComplement}
              </span>
            )}
            {order.orderType !== 0 && (
              <span>
                {order.orderAddress} {order.orderNumber} {order.orderComplement}
              </span>
            )}
          </Col>
          {order.scheduled === 0 && order.status < 80 && (
            <Col xs={12} style={{ textAlign: 'left' }}>
              <span>
                Previsão:{' '}
                {moment(order.deliveryForecastDate)
                  .local()
                  .format('DD/MM/YYYY HH:mm')}
              </span>
            </Col>
          )}
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        <CardFooter>
          <RowInfo style={{ flexDirection: 'column' }}>
            <span>Produtos</span>
            <hr />
          </RowInfo>
          {order?.items
            ?.filter(({ parentId }) => !parentId)
            .map(product => (
              <RowProduct key={`product_detail_${product.id}`}>
                <InfoProduct>
                  <span>
                    {product.quantity}x{' '}
                    {product.digitalName || product.productName}
                  </span>
                  <span>{toBlr(product.price)}</span>
                </InfoProduct>
                {product.subItems?.map(subItem => (
                  <RowSubProduct key={`subItem_detail_${product.id}`}>
                    <InfoSubProduct>
                      <span>
                        {subItem.quantity}x{' '}
                        {subItem.digitalName || subItem.productName}
                      </span>
                      {/* <span>{toBlr(subItem.productPrice)}</span> */}
                    </InfoSubProduct>
                  </RowSubProduct>
                ))}
              </RowProduct>
            ))}
          <RowInfo style={{ flexDirection: 'column' }}>
            <hr />
          </RowInfo>
          <RowInfo>
            <span>Subtotal: </span>
            <Info>{toBlr(order.subtotal)}</Info>
          </RowInfo>
          {order.deliveryFee > 0 && (
            <RowInfo>
              <span>Frete: </span>
              <Info>{toBlr(order.deliveryFee)}</Info>
            </RowInfo>
          )}
          {!!order.discount && (
            <RowInfo>
              <span>Desconto: </span>
              <Info>{toBlr(order.discount)}</Info>
            </RowInfo>
          )}
          {!!order.cashback && (
            <RowInfo>
              <span>Cashback: </span>
              <Info>{toBlr(order.cashback)}</Info>
            </RowInfo>
          )}
          <RowInfo style={{ fontWeight: 500 }}>
            <span>Total: </span>
            <Info style={{ fontWeight: 500 }}>
              {toBlr(
                order.cashback ? order.total - order.cashback : order.total
              )}
            </Info>
          </RowInfo>
        </CardFooter>
        <Row>
          {order.reviewScore && (
            <Col xs={12} style={{ marginTop: '10px' }}>
              <span style={{ fontWeight: 'bold', marginTop: '5px' }}>
                Avaliação do pedido:
              </span>
              <div>
                <StarRating
                  size={20}
                  value={order.reviewScore}
                  readonly={order.reviewScore}
                />
              </div>
            </Col>
          )}
          {order.reviewComment && (
            <Col xs={12}>
              <span style={{ fontSize: '14px' }}>{order.reviewComment}</span>
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button outline color="secondary" onClick={toggleModal}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};
