import React from 'react';
import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { PatternFormat } from 'react-number-format';
import Loading from '../loading';

export const NewAddress = ({
  onChange,
  isOpen,
  toggle,
  loadingCep,
  createAddress,
  addressInformation,
}) => (
  <Modal size="lg" isOpen={isOpen} toggle={toggle}>
    <form onSubmit={createAddress}>
      <ModalHeader style={{ textAlign: 'center' }}>Novo endereço</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>Informe seu CEP</Label>
              <PatternFormat
                disabled={loadingCep}
                format="#####-###"
                mask="_"
                required
                value={addressInformation?.zip}
                onChange={({ target: { value: cep } }) => {
                  onChange('cep')(cep);
                  onChange('zip')(cep);
                }}
                placeholder="CEP"
                customInput={Input}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {loadingCep && (
            <Col xs={12}>
              <Loading />
            </Col>
          )}
          {!loadingCep && addressInformation.error && (
            <Col xs={12}>
              <span style={{ color: 'red' }}>{addressInformation.error}</span>
            </Col>
          )}
          {!loadingCep &&
            addressInformation.zip &&
            !addressInformation.hideAdddress && (
              <>
                <Col lg={8} sm={12}>
                  <FormGroup>
                    <Label>Endereço</Label>
                    <Input
                      value={addressInformation.street}
                      placeholder="Endereço"
                      required
                      onChange={({ target: { value: street } }) =>
                        onChange('street')(street)
                      }
                      disabled={!addressInformation.canEditStreet}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} sm={12}>
                  <FormGroup>
                    <Label>Número</Label>
                    <Input
                      value={addressInformation.number}
                      placeholder="Número"
                      required
                      onChange={({ target: { value: number } }) =>
                        onChange('number')(number)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={8} sm={12}>
                  <FormGroup>
                    <Label>Complemento</Label>
                    <Input
                      value={addressInformation.complement}
                      onChange={({ target: { value: complement } }) =>
                        onChange('complement')(complement)
                      }
                      placeholder="Número"
                    />
                  </FormGroup>
                </Col>
              </>
            )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" disabled={!addressInformation.number}>
          Confirmar
        </Button>
      </ModalFooter>
    </form>
  </Modal>
);
