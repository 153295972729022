import * as Sentry from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';
import { configureAnchors } from 'react-scrollable-anchor';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { isMobile } from 'react-device-detect';
import { Route, Router } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/swiper.min.css';

import { useInternalContext } from './Context';
import Loading from './components/loading';
import { TabsMobile } from './components/tabs';
import { useCashbackContext } from './contexts/CartContext';
import { GlobalStyle } from './globalStyles';
import history from './history';
import {
  fetchDeliveryInformationV2,
  fetchStores,
  getConfigurations,
} from './service';
import {
  createStoreEncodedName,
  getSelectedAddress,
  getSelectedStore,
  getTakeAwayMode,
  removeCashback,
  removeSelectedStore,
  removeTableAccountMode,
  storeSelectedStore,
} from './util';
import { isStoreOpen } from './util/hour';
import { getCheckoutComponent } from './util/routes';
import { startTracks } from './util/track';
import { Cart } from './views/Cart';
import Cashback from './views/Cashback';
import CreateAccount from './views/CreateAccount';
import FastRegister from './views/FastRegister';
import Forgot from './views/ForgotPassword';
import Header from './views/Header';
import Home from './views/Home';
import { Info } from './views/Info';
import Login from './views/Login';
import OrderReview from './views/OrderReview';
import Orders from './views/Orders';
import Store from './views/Store';
import Success from './views/Success';
import { User } from './views/User';

configureAnchors({ offset: -120, scrollDuration: 200 });

const SentryRoute = Sentry.withSentryRouting(Route);

/** @returns {React.JSX.Element} */
const App = () => {
  const isOpenIntervalRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const {
    setConfig,
    config,
    tableAccountMode,
    setTableAccountMode,
    setIsTakeAwayMode,
    isTakeAwayMode: isTakeAwayModeContext,
  } = useInternalContext();
  const { setCashbackConfigs } = useCashbackContext();

  const createRoute = (component, path, mainStyles = true) => {
    const Wrapper = ({ children }) => {
      if (mainStyles) {
        return (
          <div
            style={{
              width: '100%',
              position: 'relative',
              marginTop: 120,
            }}
          >
            {children}
          </div>
        );
      }
      return <>{children}</>;
    };
    return (
      <Wrapper>
        <SentryRoute exact path={`/${path}`} component={component} />
        <SentryRoute exact path={`/oaks/${path}`} component={component} />
        <SentryRoute
          exact
          path={`/sushinamoto/${path}`}
          component={component}
        />
        <SentryRoute exact path={`/macncheese/${path}`} component={component} />
        <SentryRoute exact path={`/mozza/${path}`} component={component} />
        <SentryRoute exact path={`/oburgues/${path}`} component={component} />
        <SentryRoute exact path={`/extouro/${path}`} component={component} />
        <SentryRoute exact path={`/ofornes/${path}`} component={component} />
        <SentryRoute exact path={`/sahur/${path}`} component={component} />
        <SentryRoute exact path={`/seuvidal/${path}`} component={component} />
      </Wrapper>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getConfigurations()
      .then(data => {
        const { pathname, search } = window.location;
        const pathArray = pathname.split('/');
        const queryURLParams = new URLSearchParams(search);
        const isTableAccountMode =
          queryURLParams.has('tableId') ||
          queryURLParams.has('accountId') ||
          tableAccountMode;
        const isTakeAwayMode =
          pathArray.includes('retirada-loja') || isTakeAwayModeContext;

        setConfig({
          ...data,
          hasLogin: isTableAccountMode || isTakeAwayMode ? 'NO' : data.hasLogin,
          hasScheduledOrder:
            isTableAccountMode || isTakeAwayMode
              ? 'NO'
              : data.hasScheduledOrder,
          hoursAfterShceduled:
            isTableAccountMode || isTakeAwayMode
              ? null
              : data.hoursAfterShceduled,
        });

        if (isTableAccountMode && !tableAccountMode) {
          const accountId = queryURLParams.get('accountId');
          const accountName = queryURLParams.get('accountName');
          const tableId = queryURLParams.get('tableId');
          const tableName = queryURLParams.get('tableName');

          removeCashback();
          setCashbackConfigs({});

          setTableAccountMode({
            account: accountId
              ? {
                  name: accountName,
                  id: accountId,
                }
              : null,
            table: tableId
              ? {
                  name: tableName,
                  id: tableId,
                }
              : null,
          });
        }

        if (pathArray.includes('retirada-loja')) {
          console.log('# setting is take way from store');
          removeSelectedStore();
          removeTableAccountMode();
          removeCashback();
          setCashbackConfigs({});
          setIsTakeAwayMode(true);
        } else {
          setIsTakeAwayMode(false);
        }

        if (pathArray.includes('loja')) {
          const storeIndex = pathArray.findIndex(key => key === 'loja');
          const storeName = pathArray[storeIndex + 1];
          fetchStores(data.id).then(async stores => {
            const store = stores.find(
              ({ name }) => createStoreEncodedName(name) === storeName
            );

            const oldSelectedStore = getSelectedStore()
              ? { ...getSelectedStore() }
              : null;

            if (store) {
              const deliveryStores = await fetchDeliveryInformationV2({
                zip: getSelectedAddress() ? getSelectedAddress().zip : null,
                number: getSelectedAddress()
                  ? getSelectedAddress().number
                  : null,
                street: getSelectedAddress()
                  ? getSelectedAddress().street
                  : null,
                merchantId: data.merchantId,
                hoursAfterShceduled: data?.hoursAfterShceduled || 0,
                digitalMerchantId: data.id,
              });
              const storeDeliveryFee = deliveryStores.find(
                ({ storeId }) => storeId === store.id
              )?.deliveryFee;
              removeSelectedStore();
              const type = () => {
                if (getTakeAwayMode()) {
                  return 'takeaway';
                }
                return oldSelectedStore?.type || 'NONE';
              };
              startTracks(store, history);
              console.log('# salvando loja: ', {
                ...store,
                deliveryFee: storeDeliveryFee,
                type: type(),
              });
              storeSelectedStore({
                ...store,
                deliveryFee: storeDeliveryFee,
                type: type(),
              });
            }

            if (store && stores.length === 1) {
              startTracks(store, history);
              storeSelectedStore({
                ...store,
                type:
                  store.takeAway &&
                  data.hasTakeAway !== 'NO' &&
                  data.hasDelivery === 'NO'
                    ? 'takeaway'
                    : 'NONE',
              });
            }

            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        toast.warn(
          'Ops! Tivemos um erro ao carregar a página, tente novamente mais tarde'
        );
      });
    isOpenIntervalRef.current = setInterval(() => {
      const isOpen = isStoreOpen();
      if (isOpen === null) {
        return;
      }
      const store = getSelectedStore();
      if (store && store.open !== (isOpen ? 1 : 0)) {
        storeSelectedStore({ ...store, open: isOpen });
      }
    }, 1000);
    return () => {
      clearInterval(isOpenIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !config) {
    return <Loading />;
  }

  return (
    <div className="App">
      <GlobalStyle config={config} />
      <ToastContainer position={isMobile ? 'bottom-center' : 'top-right'} />
      <Router history={history}>
        <div style={{ overflowX: 'hidden' }}>
          <Header />
          {createRoute(Home, '', false)}
          {createRoute(Home, 'home', false)}
          {createRoute(Home, 'open/:sku', false)}
          {createRoute(Home, 'home/open/:sku', false)}
          {createRoute(Login, 'login')}
          {createRoute(Orders, 'orders')}
          {createRoute(CreateAccount, 'create')}
          {createRoute(Forgot, 'password')}
          {createRoute(Success, 'success/:id')}
          {createRoute(Store, 'cardapio')}
          {createRoute(Store, 'loja/:storeName')}
          {createRoute(Store, 'loja/:storeName/:sku')}
          {createRoute(Store, 'loja/:storeName/:id/edit')}
          {createRoute(getCheckoutComponent(tableAccountMode), 'checkout')}
          {createRoute(FastRegister, 'fidelidade')}
          {createRoute(Info, 'info')}
          {createRoute(User, 'me')}
          {createRoute(Cart, 'cart')}
          {createRoute(Cashback, 'cashback')}
          {createRoute(OrderReview, 'order/:orderId')}
          <TabsMobile />
        </div>
      </Router>
    </div>
  );
};

export default App;
