import moment from 'moment';
import React, { useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Input,
  Row,
} from 'reactstrap';
import { useInternalContext } from '../../Context';
import { Button } from '../../components/button';
import { StarRating } from '../../components/starRating';
import { MAX_LENGTH } from '../../constants';
import { sendOrderReview } from '../../service';
import { getUser, orderReview, removeOrderReview } from '../../util';
import { CardOrderReview, Subtext, Text } from './style';

const OrderReview = () => {
  const user = getUser();
  const params = useParams();
  const history = useHistory();
  const order = orderReview();

  const { config, score, setScore, defaultComment, setDefaultComment } =
    useInternalContext();

  const [comment, setComment] = useState(defaultComment);
  const [count, setCount] = useState(0);

  const handleChangeComment = e => {
    const { value } = e.target;
    setCount(value.length);
    setComment(value);
  };

  const returnToPage = () => {
    history.goBack();
    removeOrderReview();
    setDefaultComment('');
  };

  const saveOrderReview = async () => {
    const { hasLogin } = config;

    if (!score) {
      toast.warn('Escolha de 1 a 5 estrelas para classificar o pedido!');
      return;
    }

    const token = user?.token;
    const orderId = parseInt(params.orderId, 10);

    try {
      const body = { orderId, score, comment };
      await sendOrderReview({ body, token, hasLogin });
      toast.success('Avaliação salva com sucesso!');
    } catch (error) {
      toast.error(error.userMessage || 'Ocorreu um erro ao salvar avaliação!');
    } finally {
      returnToPage();
    }
  };

  return (
    <CardOrderReview>
      <CardHeader>
        <BsChevronLeft
          size={24}
          onClick={returnToPage}
          style={{ float: 'left', cursor: 'pointer' }}
        />
        <CardText>Avaliação do Pedido</CardText>
      </CardHeader>
      <CardBody>
        <CardTitle tag="h5">{order.storeName}</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          {moment(order.createdDate).local().format('DD/MM/YYYY, HH:mm')}
        </CardSubtitle>
      </CardBody>
      <CardBody>
        <Row>
          <Col>
            <Text>O que você achou do pedido?</Text>
            <StarRating
              showTooltip
              value={score}
              onClick={rating => {
                setScore(rating);
                setDefaultComment(comment);
              }}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Text>Comentário</Text>
            <Input
              type="textarea"
              maxLength={MAX_LENGTH}
              defaultValue={defaultComment}
              onChange={handleChangeComment}
              placeholder="Conte-nos o que aconteceu... (opcional)"
              style={{ height: '110px' }}
            />
            <Subtext>
              {count}/{MAX_LENGTH}
            </Subtext>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Button
          outline
          config={config}
          onClick={saveOrderReview}
          style={{ width: '100%', maxWidth: '370px', padding: 4 }}
        >
          Enviar avaliação
        </Button>
      </CardFooter>
    </CardOrderReview>
  );
};

export default OrderReview;
