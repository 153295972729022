import React, { useEffect } from 'react';
import { isMobile } from '../../util';
import { Container } from './styles';

const ImageViewer = ({ onClose, isOpen, src }) => {
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen, onClose]);

  return (
    <>
      <Container isOpen={isOpen} onClick={onClose}>
        <div>
          <p>
            {isMobile() ? 'Toque' : 'Clique'} em qualquer lugar da tela para
            fechar a imagem
          </p>
          <img src={src} alt="opcional do produto" />
        </div>
      </Container>
    </>
  );
};

export default ImageViewer;
