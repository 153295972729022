import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { StateProvider } from './Context';
import { ImageContextProvider } from './ImageContext';
import { CartProvider } from './contexts/CartContext';
import { CookieProvider } from './contexts/CookieContext';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://c1ce59d65477c478c74ef6550d76ba83@o212735.ingest.sentry.io/4506503980187648',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [/./],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StateProvider>
      <CookieProvider>
        <CartProvider>
          <ImageContextProvider>
            <App />
          </ImageContextProvider>
        </CartProvider>
      </CookieProvider>
    </StateProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
