import styled from 'styled-components';

export const Line = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-bottom: 20px;

  h2 {
    font-size: 1.15rem;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    white-space: nowrap;
    font-size: 0.85rem;
    margin-left: 5px;
  }
`;
