import styled from 'styled-components';

import { Card, Label } from 'reactstrap';

export const CardOrderReview = styled(Card)`
  width: 100%;
  max-width: 800px;
  margin-bottom: 70px;

  @media (min-width: 1024px) {
    margin: 140px auto;
  }
`;

export const Text = styled(Label)`
  display: block;
`;

export const Subtext = styled(Label)`
  float: right;
  color: #6c757d;
`;
