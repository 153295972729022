import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  debounce,
  getTableAccountMode,
  getTakeAwayMode,
  getUser,
  storeSelectedStore,
  storeTableAccountMode,
  storeTakeAwayMode,
} from './util';

const InternalContext = createContext({});

export const StateProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedStore, setSelectedStore] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [tableAccountMode, setTableAccountMode] = useState(
    getTableAccountMode()
  );

  const [accountSelected, setAccountSelected] = useState(
    getTableAccountMode()?.account
  );
  const [tableSelected, setTableSelected] = useState(
    getTableAccountMode()?.table
  );
  const [defaultComment, setDefaultComment] = useState('');
  const [score, setScore] = useState(0);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1000);
  };

  useEffect(() => {
    if (tableAccountMode) {
      const { account, table } = tableAccountMode;
      if (!account && !table) return;
      if (account) {
        setAccountSelected(account);
      }
      if (table) {
        setTableSelected(table);
      }
      storeTableAccountMode(tableAccountMode);
    }
  }, [tableAccountMode]);

  
  const setIsTakeAwayMode = useCallback(isTakeAwayMode => {
    console.log('# storeing isTakeAwayMode: ', isTakeAwayMode);
    storeTakeAwayMode(isTakeAwayMode);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 100));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 100));
    };
  }, []);

  useEffect(() => {
    if (selectedStore) {
      storeSelectedStore(selectedStore);
    }
  }, [selectedStore]);

  useEffect(() => {
    const data = getUser();
    setUser(data);
  }, []);

  return (
    <InternalContext.Provider
      value={{
        user,
        setUser,
        setConfig,
        selectedStore,
        setSelectedStore,
        config,
        isMobile,
        tableAccountMode,
        setTableAccountMode,
        accountSelected,
        tableSelected,
        isTakeAwayMode: getTakeAwayMode(),
        setIsTakeAwayMode,
        score,
        setScore,
        defaultComment,
        setDefaultComment,
      }}
    >
      {children}
    </InternalContext.Provider>
  );
};

export const useInternalContext = () => useContext(InternalContext);
