import styled from 'styled-components';

export const RowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  @media (max-width: 1000px) {
    font-size: 0.875rem;
  }
`;

export const Info = styled.span``;

export const RowProduct = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  @media (max-width: 1000px) {
    font-size: 0.875rem;
  }
`;

export const InfoProduct = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const RowSubProduct = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  font-size: 0.9rem;
  font-weight: lighter;
`;

export const InfoSubProduct = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const HeaderSmallLabel = styled.span`
  font-size: 0.87em;
  @media (max-width: 1000px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const HeaderBigLabel = styled.span`
  font-size: 1em;
  @media (max-width: 1000px) {
    font-size: calc(12px + 1.5vmin);
  }
`;
