import React, { Fragment } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { useCartContext } from '../../contexts/CartContext';
import { calcProductTotalValue, getUser, toBlr } from '../../util';
import { trackEvent } from '../../util/track';
import { Button } from '../button';
import CartItem from './item';
import { ProductCartLabel, ProductCartPrice } from './style';

const CartBody = ({ editAction, removeAction }) => {
  const { removeFromCart, subFromCart, sumFromCart, cart } = useCartContext();

  const subtractProductFromCart = productData => {
    if (productData.quantity === 1) {
      confirmAlert({
        title: 'Remover produto',
        message: 'Deseja remover este produto do carrinho?',
        buttons: [
          {
            label: 'Sim',
            onClick: () => {
              trackEvent({
                name: 'removeProductFromCart',
                data: {
                  productId: productData.id,
                  productName: productData.name,
                  user: getUser(),
                },
              });

              removeFromCart(productData.cartId);
            },
          },
          {
            label: 'Não',
          },
        ],
      });
    } else {
      trackEvent({
        name: 'subtractProductQuantity',
        data: {
          productId: productData.id,
          productName: productData.name,
          user: getUser(),
        },
      });
      subFromCart(productData.cartId);
    }
  };

  const sumProductFromCart = productData => {
    sumFromCart(productData.cartId);
    trackEvent({
      name: 'sumProductQuantity',
      data: {
        productId: productData.id,
        productName: productData.name,
        user: getUser(),
      },
    });
  };

  return (
    <>
      {cart.products.length === 0 && (
        <Row
          style={{
            overflow: 'hidden!important',
            fontWeight: 'bold',
          }}
        >
          <Col xs={12}>Seu carrinho está vazio</Col>
        </Row>
      )}
      {cart.products.map(product => (
        <Fragment key={`${product.cartId}`}>
          <Row>
            <Col xs={8} lg={6}>
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '0.8em',
                      fontWeight: 'bold',
                      wordBreak: 'initial',
                    }}
                  >
                    <span style={{ marginRight: '0.5em' }}>
                      {product.quantity}x
                    </span>
                    {product.name}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={4} lg={6}>
              {!product.redeemId && (
                <Row>
                  <Col>
                    {subtractProductFromCart && (
                      <FaMinusCircle
                        color="red"
                        size={24}
                        style={{ cursor: 'pointer' }}
                        onClick={() => subtractProductFromCart(product)}
                      />
                    )}
                    <span style={{ margin: '0.5em' }}>{product.quantity}</span>
                    {sumProductFromCart && (
                      <FaPlusCircle
                        color="green"
                        size={24}
                        style={{ cursor: 'pointer' }}
                        onClick={() => sumProductFromCart(product)}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {product.redeemId && !editAction && (
                <FaMinusCircle
                  color="red"
                  size={24}
                  style={{ cursor: 'pointer' }}
                  onClick={() => subtractProductFromCart(product)}
                />
              )}
            </Col>
            {product.observation && product.observation !== '' && (
              <Col
                xs={12}
                style={{ fontSize: '0.7em', textAlign: 'left' }}
              >{`* ${product.observation}`}</Col>
            )}
          </Row>
          {product.price >= 0 && (
            <Row>
              <CartItem multiple={product.wizardSteps} />
              <Col xs={6}>
                <ProductCartLabel>Total unitário:</ProductCartLabel>
              </Col>
              <Col xs={6}>
                <ProductCartPrice>
                  {calcProductTotalValue({
                    cartProduct: { ...product },
                    format: true,
                    multiplyQuantity: false,
                  })}
                </ProductCartPrice>
              </Col>
              <Col xs={6}>
                <ProductCartLabel>Total Item:</ProductCartLabel>
              </Col>
              <Col xs={6}>
                <ProductCartPrice>
                  {calcProductTotalValue({
                    cartProduct: { ...product },
                    format: true,
                    multiplyQuantity: true,
                  })}
                </ProductCartPrice>
              </Col>
            </Row>
          )}
          {product.price < 0 && (
            <Row>
              <Col xs={6}>
                <ProductCartLabel>Valor do desconto:</ProductCartLabel>
              </Col>
              <Col xs={6}>
                <ProductCartPrice>{toBlr(product.price)}</ProductCartPrice>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: 4 }}>
            {editAction && !product.redeemId && (
              <Col xs={4}>
                <Button
                  outline
                  style={{ minWidth: 95 }}
                  onClick={() => editAction(product)}
                >
                  Editar
                </Button>
              </Col>
            )}
            {removeAction && (
              <Col xs={4}>
                <Button
                  outline
                  color="danger"
                  style={{ minWidth: 95 }}
                  onClick={() => removeAction(product)}
                >
                  Remover
                </Button>
              </Col>
            )}
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
        </Fragment>
      ))}
    </>
  );
};
export default CartBody;
