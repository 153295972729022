/* eslint-disable no-param-reassign */
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { useInternalContext } from '../../Context';
import { ProductBase } from './productBase';
import { ProductCount } from './style';

export const SingleChoiseStep = ({
  option,
  step,
  wizardSteps,
  updateWizardSteps,
  index,
}) => {
  const { config } = useInternalContext();
  const {
    image: productImageURL,
    name: productName,
    price: productPrice,
    description: productDescription,
    id: productId,
  } = option;

  const handleChecked = () => {
    if (!step.formData[productId]) {
      step.formData[productId] = { checked: false };
    }
    step.formData[productId].checked = !step.formData[productId].checked;
    step.options
      .filter(optionData => optionData.id !== productId)
      .forEach(item => {
        step.formData[item.id] = {
          checked: false,
        };
      });
    const wizardStepsToState = wizardSteps.map(stepWizard => {
      if (step.id !== stepWizard.id) return stepWizard;
      return {
        ...stepWizard,
        options: stepWizard.options.map(optionStep => {
          if (optionStep.id !== option.id) return optionStep;
          return {
            ...optionStep,
            quantity: null,
          };
        }),
      };
    });
    updateWizardSteps(wizardStepsToState);
    const modalBody = document.querySelector('.modal-body');

    const nextElement = document.querySelector(`#step_${index + 1}`);
    if (nextElement) {
      modalBody.scrollTop = nextElement.offsetTop;
    } else {
      // Faz o scroll até o fim da página
      modalBody.scrollTop = modalBody.scrollHeight - modalBody.clientHeight;
    }
  };

  return (
    <div
      aria-hidden="true"
      className="group-item"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => handleChecked()}
    >
      <ProductBase
        productDescription={productDescription}
        productImageURL={productImageURL}
        productName={productName}
        productPrice={productPrice}
      />
      <ProductCount config={config} style={{ padding: '0 0.5rem' }}>
        <FormGroup check style={{ padding: 0, height: 'calc(10px + 2vmin)' }}>
          <Label check>
            <Input
              type="radio"
              checked={
                (step.formData[productId] &&
                  step.formData[productId].checked) ||
                false
              }
              onChange={() => {}}
              style={{
                margin: 0,
                position: 'inherit',
                width: 'calc(10px + 2vmin)',
                height: 'calc(10px + 2vmin)',
              }}
            />
          </Label>
        </FormGroup>
      </ProductCount>
    </div>
  );
};
