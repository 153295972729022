import { getSelectedAddress, getSelectedStore } from '.';
import { trackSale } from '../service';

export const track = (cart, isCheckout, userPhone) => {
  if (!userPhone) {
    return;
  }
  const store = getSelectedStore();
  trackSale({
    mainPhone: userPhone,
    storeId: store.id,
    cart: {
      ...cart,
      selectedStore: getSelectedStore(),
      selectedAddress: getSelectedAddress(),
    },
    isCheckout,
  }).catch(() => {});
};
