import React, { useEffect, useRef, useState } from 'react';
import { FaPiggyBank, FaTicketAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { CardFooter, Col, Row } from 'reactstrap';

import { useInternalContext } from '../../Context';
import { useCartContext, useCashbackContext } from '../../contexts/CartContext';
import {
  calcPartialCashback,
  calcTotalCart,
  getDeliveryFee,
  getSelectedStore,
  getTakeAwayMode,
  getUser,
  isUserLogged,
  toBlr,
  usePromoCode,
} from '../../util';
import { trackEvent } from '../../util/track';
import { Button } from '../button';
import ModalApplyPromocode from '../modalApplyPromocode';
import { ButtonIcon, ProductPrice, ProductPriceRow } from './style';

export const CartFooterNormal = ({
  removePromocode,
  openStoreSelection,
  storeDetail,
  buttonGoToCheckout = true,
  hidePromocode = false,
  fixed = false,
}) => {
  const [promocodeModal, setPromocodeModal] = useState(false);
  const [heightCartFooter, setHeightCartFooter] = useState(0);
  const { config } = useInternalContext();
  const { cart, removeCashback, applyCashback } = useCartContext();
  const {
    cashbackConfigs: { active: isCasbackActive, currentCustomerValue },
  } = useCashbackContext();
  const history = useHistory();
  const cartFooter = useRef(null);
  const deliveryFee = getDeliveryFee(cart.deliveryFee);
  const cashbackPartialValue = calcPartialCashback(currentCustomerValue, cart);

  useEffect(() => {
    if (cartFooter?.current?.clientHeight) {
      setHeightCartFooter(cartFooter.current.clientHeight);
    }
  }, [cartFooter, cart]);

  const goPayment = async () => {
    trackEvent({
      name: 'willNavigateToCart',
      data: {
        isLoggedUser: isUserLogged(),
        user: getUser(),
      },
    });
    console.log('# isTakeAwayMode: ', getTakeAwayMode());
    if (
      !getSelectedStore() ||
      (getSelectedStore().type === 'NONE' && !getTakeAwayMode())
    ) {
      openStoreSelection();
      return;
    }

    const paymentFunctions = {
      false: () => history.push('/login'),
      true: () => history.push('/checkout'),
    };
    paymentFunctions[config.hasLogin === 'NO' || isUserLogged()]();
  };

  if (cart?.products?.length === 0) {
    return <div />;
  }
  return (
    <div
      ref={cartFooter}
      style={
        fixed
          ? {
              width: '100%',
              position: 'fixed',
              top: `calc(100% - (70px + ${heightCartFooter}px))`,
              background: 'white',
              margin: ' 0 -15px',
            }
          : {}
      }
    >
      <CardFooter>
        <ModalApplyPromocode
          open={promocodeModal}
          onToggle={() => setPromocodeModal(prevState => !prevState)}
          applyPromoCode={usePromoCode}
          cart={cart}
        />
        {cart && (
          <>
            <Row>
              {!hidePromocode && (
                <Col xs="12" style={{ textAlign: 'center' }}>
                  <button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: 14,
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      border: 0,
                      background: 'none',
                      width: '100%',
                      color: config.textCategoryColor || '#000',
                    }}
                    type="button"
                    onClick={() => {
                      if (cart.promocodeId) {
                        removePromocode();
                      } else {
                        setPromocodeModal(true);
                      }
                    }}
                  >
                    <div>
                      <div>
                        <FaTicketAlt
                          color={config.textCategoryColor || '#000'}
                        />
                        <p style={{ marginBottom: 3, marginLeft: 10 }}>
                          {cart.promocodeId
                            ? 'Remover cupom de desconto'
                            : 'Aplicar cupom de desconto'}
                        </p>
                      </div>
                      {cart.promocodeId && (
                        <p style={{ fontSize: 10, fontWeight: 200 }}>
                          {cart.discountDescription}
                        </p>
                      )}
                    </div>
                  </button>
                  <hr style={{ margin: '10px 0' }} />
                </Col>
              )}
              {isCasbackActive === 'YES' && cashbackPartialValue !== 0 && (
                <Col xs="12" style={{ textAlign: 'center' }}>
                  <ButtonIcon
                    type="button"
                    onClick={() => {
                      if (cart.cashback) {
                        removeCashback();
                      } else {
                        applyCashback();
                      }
                    }}
                    textCategoryColor={config.textCategoryColor}
                  >
                    <FaPiggyBank
                      color={config.textCategoryColor || '#000'}
                      style={{ marginBottom: 3 }}
                    />
                    <p style={{ marginBottom: 3, marginLeft: 10 }}>
                      {cart.cashback
                        ? `Remover ${toBlr(cashbackPartialValue)} de Cashback`
                        : `Utilizar ${toBlr(cashbackPartialValue)} de Cashback`}
                    </p>
                  </ButtonIcon>
                  <hr style={{ margin: '10px 0' }} />
                </Col>
              )}
            </Row>
            <ProductPriceRow>
              <span>Subtotal: </span>
              <ProductPrice>
                {calcTotalCart({
                  cartProducts: [...cart.products],
                })}
              </ProductPrice>
            </ProductPriceRow>
            {(deliveryFee > 0 || cart.zeroDelivery) && (
              <ProductPriceRow>
                <span>Frete: </span>
                <ProductPrice>{toBlr(deliveryFee)}</ProductPrice>
              </ProductPriceRow>
            )}
            {!!cart.discount && (
              <ProductPriceRow>
                <span>Desconto: </span>
                <ProductPrice>{toBlr(cart.discount)}</ProductPrice>
              </ProductPriceRow>
            )}
            {!!cart.cashback && (
              <ProductPriceRow>
                <span>Cashback: </span>
                <ProductPrice>{toBlr(cart.cashback)}</ProductPrice>
              </ProductPriceRow>
            )}
            <ProductPriceRow style={{ fontWeight: 500 }}>
              <span>Total: </span>
              <ProductPrice style={{ fontWeight: 500 }}>
                {calcTotalCart({
                  cartProducts: [...cart.products],
                  deliveryFee,
                  discount: cart.discount,
                  cashback: cart.cashback,
                })}
              </ProductPrice>
            </ProductPriceRow>
          </>
        )}
      </CardFooter>
      {buttonGoToCheckout && storeDetail && cart.products.length > 0 && (
        <CardFooter>
          <Button
            disabled={
              !storeDetail.open || storeDetail.orderMinValue > cart.total
            }
            onClick={goPayment}
          >
            Fazer pedido
          </Button>
          {storeDetail.orderMinValue > cart.total && (
            <div style={{ color: 'red' }}>
              Valor mínimo: {toBlr(storeDetail.orderMinValue)}
            </div>
          )}
        </CardFooter>
      )}
    </div>
  );
};
