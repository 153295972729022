import { Checkout } from '../views/Checkout';
import { CheckoutTableAccountMode } from '../views/Checkout/checkoutTableAccountMode';

export const getCheckoutComponent = tableAccountMode => {
  if (tableAccountMode) {
    return CheckoutTableAccountMode;
  }

  return Checkout;
};
