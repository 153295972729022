import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 20;
  top: calc(100% - 70px);
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: space-around;
  width: 100%;
  background: white;
  box-shadow: 4px 4px 24px rgb(0 0 0 / 20%);
`;

export const Tab = styled.button`
  background: none;
  border: none;
  font-weight: ${props => (props.isActive ? 700 : 500)};
  font-size: 12px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Badge = styled.div`
  position: absolute;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  left: 50%;
  bottom: 60%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
