import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { useInternalContext } from '../../Context';
import { useCookieContext } from '../../contexts/CookieContext';
import { callLogin, fetchUserDetail } from '../../service';
import { getSelectedStore, redirectToStore, storeUser } from '../../util';
import { LoginCard } from './style';

const LoginPage = () => {
  const { setUser } = useInternalContext();
  const history = useHistory();
  const { setCustomerCookie } = useCookieContext();
  const [state, setState] = useState({
    loading: false,
    email: '',
    password: '',
    msgError: '',
  });

  const doLogin = async e => {
    e.preventDefault();
    setState(prevValues => ({ ...prevValues, loading: true, msgError: '' }));
    const user = await callLogin({
      username: state.email,
      password: state.password,
    });
    if (!user || user.error) {
      setState(prevValue => ({
        ...prevValue,
        loading: false,
        msgError: user ? user.error : 'Falha na conexão de internet',
      }));
      return;
    }
    const customer = await fetchUserDetail({ token: user.token });
    setUser({ ...customer, token: user.token });
    storeUser({ ...customer, token: user.token });
    console.log({customer})
    setCustomerCookie({ name: customer.name, phone: customer.mainPhone  });
    setState(prevValues => ({ ...prevValues, loading: false }));
    redirectToStore({ history, store: getSelectedStore() });
  };
  return (
    <LoginCard>
      <CardHeader>Informe seu e-mail e senha</CardHeader>
      <CardBody style={{ paddingBottom: 'calc(1.25rem + 15px)' }}>
        <Row style={{ justifyContent: 'center' }}>
          <Col style={{ maxWidth: 400 }}>
            <form onSubmit={doLogin}>
              <Row>
                <Col xs={12}>
                  <Input
                    type="email"
                    name="email"
                    required
                    placeholder="E-mail"
                    value={state.email}
                    onChange={({ target: { value } }) =>
                      setState(prevValues => ({ ...prevValues, email: value }))
                    }
                  />
                </Col>
                <Col xs={12}>
                  <Input
                    type="password"
                    name="password"
                    required
                    value={state.password}
                    onChange={({ target: { value } }) =>
                      setState(prevValues => ({
                        ...prevValues,
                        password: value,
                      }))
                    }
                    placeholder="Senha"
                  />
                </Col>
              </Row>
              <Row style={{ margin: 4 }}>
                <Col xs={12} style={{ color: 'red' }}>
                  {state.msgError}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ fontSize: 12, width: '55%' }}
                    disabled={state.loading}
                  >
                    {!state.loading ? 'Entrar' : 'Logando...'}
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col xs={12}>
                  <Button
                    color="primary"
                    outline
                    style={{ fontSize: 12, width: '55%' }}
                    onClick={() => history.push(`/create`)}
                  >
                    Criar Conta
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Button
          color="primary"
          outline
          onClick={() => history.push(`/password`)}
          style={{ fontSize: 12, border: 0 }}
        >
          Esqueci minha Senha
        </Button>
      </CardFooter>
    </LoginCard>
  );
};

export default LoginPage;
