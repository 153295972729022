import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  padding: 12px;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  width: 100%;
  z-index: 11;
  gap: 1rem;

  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

export const ValueCashback = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 1.25rem;

  @media (max-width: 1000px) {
    flex-direction: row;
    font-size: 1rem;
    gap: 0.5rem;
  }
`;
