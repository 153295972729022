/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-continue */
import React, { useEffect, useState } from 'react';

import { detect } from 'detect-browser';
import { confirmAlert } from 'react-confirm-alert';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardBody, CardHeader, Col, Row } from 'reactstrap';

import { Modal, ModalBody, ModalFooter } from '../../components/modal';
import {
  createStoreEncodedName,
  debounce,
  getDeliveryFee,
  getSelectedAddress,
  getSelectedStore,
  getStorageUser,
  getTableAccountOrder,
  getTakeAwayMode,
  getUser,
  isMobile,
  removeSelectedStore,
  setStorageUser,
  setTempIdOrders,
  storeTableAccountOrder,
  usePromoCode,
} from '../../util';
import { CartCheckout, CartContainer, RowDesktop } from './style';

import { useInternalContext } from '../../Context';
import { Button } from '../../components/button';
import CartBody from '../../components/cart/body';
import CartFooter from '../../components/cart/footer';
import Loading from '../../components/loading';
import { useCartContext } from '../../contexts/CartContext';
import { sendMetadata, sendOrder } from '../../service';
import { createOrder as parserOrder } from '../../util/order';
import { trackEvent } from '../../util/track';

const browser = detect();

export const CheckoutTableAccountMode = () => {
  const {
    config,
    setUser,
    isTakeAway: isTakeAwayFromStore,
    accountSelected,
    tableSelected,
  } = useInternalContext();
  const { clearCart, removeFromCart, cart, removePromocode } = useCartContext();
  const history = useHistory();
  const [state, setState] = useState({
    customer: {
      cpf: '',
      customerId: 0,
      email: '',
      name: '',
      mainPhone: '',
    },
    cards: [],
    loadingCep: false,
    loading: true,
    showQRCodeModal: false,
    qrCode: null,
    pixCopiaCola: '',
    newCard: false,
    change: 0,
    dates: [],
    height: 500,
    isTakeAway: false,
    payTakeAway: false,
    isDelivery: true,
    loadingDeliveryInformation: false,
    addressInformation: {},
    paymentModal: false,
    loadingPayments: false,
    deliveryIsCoverage: false,
    orderErrorMessage: '',
    creatingCardLoading: false,
    deliveryFee: null,
    validatingPromoCode: false,
    cardError: {},
    paymentsOptions: { payments: [] },
    payNow: false,
    payPix: false,
    deliveryStoreId: null,
    selectedStore: {},
    observation: '',
    hasLogin: null,
    cpfRequired: null,
    finishOrderButtonWasClickedDisabled: false,
  });

  const handleResize = () => {
    setState(prevValues => ({ ...prevValues, paymentModal: !!isMobile() }));
  };

  const loadData = async () => {
    setState(prevValues => ({ ...prevValues, loadingOrder: false }));
    const selectedStore = getSelectedStore();
    if (!selectedStore) return;

    setState(prevValues => ({
      ...prevValues,
      loading: false,
      selectedStore,
    }));
  };

  const removeProduct = async product => {
    confirmAlert({
      title: 'Remover produto',
      message: 'Deseja remover este produto do carrinho?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            removeFromCart(product.cartId);
          },
        },
        {
          label: 'Não',
          onClick: () => {},
        },
      ],
    });
  };

  const sucessOrder = async ({ order, result }) => {
    const { hasLogin } = config;

    trackEvent({
      name: 'Purchase',
      data: { currency: 'BRL', value: order.total / 100 },
    });

    await sendMetadata(result.id, {
      platform: browser.name,
      os: browser.os,
      platformVersion: browser.version,
      appVersion: process.env.VERSION,
      log: JSON.stringify(state),
    });
    clearCart();

    setTempIdOrders(result.id, hasLogin === 'YES');
    storeTableAccountOrder(result.id);
    history.push(`/success/${result.id}`);
  };

  const createOrder = async order => {
    const { payPix, selectedStore, customer } = state;
    const { hasLogin } = config;
    const user = getUser();
    if (hasLogin === 'NO') {
      // TODO: Como tratar esse token?
      setStorageUser({ ...customer });
      setUser({
        ...customer,
        isTempUser: true,
      });
    }
    if (payPix && (!order.customer.cpf || !order.customer.name)) {
      toast.warn('Para pagamentos PIX é obrigatório informar o nome e CPF');
      return;
    }
    console.log('# order: ', order);
    const result = await sendOrder(
      state.deliveryStoreId || selectedStore.id,
      order,
      user ? user.token : process.env.REACT_APP_TOKEN,
      hasLogin
    );
    console.log('# result: ', result);
    if (result.id) {
      sucessOrder({ result, order });
    } else {
      trackEvent({
        name: 'completedOrderWithError',
        data: { user: user || { name: 'Anônimo' }, error: result.erro },
      });

      setState(prevState => ({
        ...prevState,
        loadingOrder: false,
        loading: false,
        payNow: false,
        payPix: false,
        change: null,
        payTakeAway: false,
        paymentsOptions: {},
      }));

      if (result.userMessage || result.erro) {
        loadData();
        toast.error(result.userMessage || result.erro);
        if (result.valueOrderIsChanged) {
          history.push('/');
          removeSelectedStore();
          clearCart();
        }
      } else {
        loadData();
        toast.error('Erro ao finalizar pedido, tente novamente.');
      }
    }
  };

  const finishOrder = async () => {
    const externalId = sessionStorage.getItem('externalId');
    setState(prevValues => ({ ...prevValues, loadingOrder: true }));
    const selectedAddress = getSelectedAddress();
    if (selectedAddress) {
      delete selectedAddress.canEditStreet;
    }
    const store = getSelectedStore();
    const getSourceId = () => {
      if (tableSelected?.id || accountSelected?.id) {
        return 114;
      }
      if (isTakeAwayFromStore) {
        return 1111;
      }
      return 112;
    };
    const isTakeAway =
      getTakeAwayMode() || tableSelected?.id || accountSelected?.id
        ? false
        : state.selectedStore.type.toLowerCase() === 'takeaway';
    const order = parserOrder({
      orderId: getTableAccountOrder(),
      cartProducts: cart.products,
      discount: cart.discount,
      sourceId: getSourceId(),
      externalId,
      scheduled: config.hasScheduledOrder === 'YES',
      dayOfWeek: state.dayOfWeek,
      timeOfDay: state.selectUtcTime,
      discountId: -1,
      promocodeId: cart.promocodeId,
      address: selectedAddress,
      customer: {
        name: state.customer.name,
      },
      customerId: state.customer.id,
      deliveryFee: cart.zeroDelivery ? 0 : state.deliveryFee,
      deliveryCommission: isTakeAway ? 0 : store?.deliveryCommission || 0,
      storeId: state.deliveryStoreId || state.selectedStore.id,
      change: state.change * 100,
      isTakeAway: isTakeAway ? 1 : 0,
      observation: state.observation,
      tableId: tableSelected?.id,
      accountId: accountSelected?.id,
    });
    if (state.change && state.change * 100 < order.total) {
      toast.warn(
        'Valor pago em dinheiro precisa ser igual ou maior ao pedido.'
      );
      setState(prevState => ({ ...prevState, loadingOrder: false }));
      return;
    }
    createOrder(order);
  };

  const init = async () => {
    const { hasLogin, cpfRequired } = config;
    const user = hasLogin === 'YES' ? getUser() : getStorageUser();
    setState(prevValues => ({ ...prevValues, hasLogin, cpfRequired }));
    if (user && user.name) {
      setState(prevValues => ({
        ...prevValues,
        customer: user,
        paymentModal: !!isMobile(),
      }));
    } else {
      setState(prevValues => ({
        ...prevValues,
        paymentModal: !!isMobile(),
      }));
    }
    loadData();
  };

  if (!config) {
    return <Loading message="Carregando informações..." />;
  }

  useEffect(() => {
    if (config) {
      init();
    }

    window.addEventListener('resize', debounce(handleResize, 100));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 100));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.loading) {
    return <Loading message="Carregando informações..." />;
  }
  return (
    <>
      <Modal
        isOpen={state.paymentModal}
        style={{ borderRadius: 0 }}
        size="lg"
        fade={false}
      >
        <ModalBody className="mobile-body" style={{ scrollBehavior: 'smooth' }}>
          {state.loadingPayments && (
            <Loading message="Carregando formas de pagamento" />
          )}
          {!state.loadingPayments && !state.loadingOrder && (
            <header
              style={{
                position: 'sticky',
                top: -20,
                zIndex: 10,
                backgroundColor: 'white',
              }}
            >
              <div>
                <hr />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaArrowLeft
                    onClick={() => history.push('/')}
                    color={config.textCategoryColor}
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <h1
                      style={{
                        color: config.textCategoryColor,
                        fontWeight: 'bold',
                        fontSize: '1.25rem',
                        marginBottom: 0,
                      }}
                    >
                      Finalize seu pedido
                    </h1>
                  </span>
                </div>
                <hr />
              </div>
            </header>
          )}
          {!state.loadingOrder && (
            <>
              <CardHeader>
                <h4 className="card-title text-black">
                  <small className="small">Seu pedido em</small> <br />
                  {getSelectedStore().name}
                </h4>
              </CardHeader>
              <div
                style={{
                  overflowY: 'overlay',
                  overflowX: 'hidden',
                  height: 250,
                  padding: 30,
                }}
              >
                <CartBody
                  removeAction={removeProduct}
                  editAction={({ id }) =>
                    history.push(
                      `/loja/${createStoreEncodedName(
                        getSelectedStore().name
                      )}/${id}/edit`
                    )
                  }
                />
              </div>
              <CartFooter
                removePromocode={() => {
                  setState(prevValue => ({
                    ...prevValue,
                    deliveryFee: undefined,
                  }));
                  removePromocode();
                }}
                buttonGoToCheckout={false}
              />
            </>
          )}
          {state.loadingOrder && <Loading message="Criando pedido.." />}
        </ModalBody>
        {!state.loadingOrder && (
          <ModalFooter
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                finishOrder();
              }}
              style={{
                color: config.bottomTextColor,
                backgroundColor: config.bottomBackgroundColor,
              }}
            >
              Finalizar pedido
            </Button>
          </ModalFooter>
        )}
      </Modal>
      <CartCheckout id="checkout-container">
        {state.loadingOrder && <Loading message="Criando pedido.." />}
        {!state.loadingOrder && (
          <CardBody style={{ padding: 0, overflow: 'hidden' }}>
            {state.loadingDeliveryInformation && (
              <Loading message="Carregando informações de delivery" />
            )}
            {!state.loadingDeliveryInformation && (
              <Row style={{ margin: 0, height: '100%' }}>
                <Col
                  xs={12}
                  lg={7}
                  style={{ height: '100%' }}
                  className="order-1 order-lg-0"
                >
                  <h1
                    style={{
                      color: config.textCategoryColor,
                      fontWeight: 'bold',
                    }}
                  >
                    Finalize seu pedido
                  </h1>
                  <RowDesktop style={{ padding: 15 }}>
                    <div>
                      <Button
                        style={{
                          marginTop: '1rem',
                          width: '100%',
                          color: config.bottomTextColor,
                          backgroundColor: config.bottomBackgroundColor,
                        }}
                        onClick={finishOrder}
                      >
                        Finalizar pedido
                      </Button>
                    </div>
                  </RowDesktop>
                </Col>
                <Col
                  xs={12}
                  lg={5}
                  style={{
                    height: '100%',
                    paddingBottom: 15,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className="order-0 order-lg-1"
                >
                  <CartContainer>
                    <CardHeader>
                      <h4 className="card-title text-black">
                        <small className="small">Seu pedido em</small> <br />
                        {getSelectedStore().name}
                      </h4>
                    </CardHeader>
                    <div
                      style={{
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        padding: 30,
                      }}
                    >
                      <CartBody
                        removeAction={removeProduct}
                        editAction={({ id }) =>
                          history.push(
                            `/loja/${createStoreEncodedName(
                              getSelectedStore().name
                            )}/${id}/edit`
                          )
                        }
                      />
                    </div>
                    <CartFooter
                      deliveryFee={getDeliveryFee(cart.deliveryFee)}
                      applyPromoCode={usePromoCode}
                      colorPromoCode={config.textCategoryColor}
                      removePromocode={() => {
                        setState(prevValue => ({
                          ...prevValue,
                          deliveryFee: undefined,
                        }));
                        removePromocode();
                      }}
                    />
                  </CartContainer>
                </Col>
              </Row>
            )}
          </CardBody>
        )}
      </CartCheckout>
    </>
  );
};
