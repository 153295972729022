import React, { createContext, useContext, useState } from 'react';
import { useInternalContext } from './Context';
import { getSelectedStore } from './util';

const ImageContext = createContext({});

export const ImageContextProvider = ({ children }) => {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [imageURL, setSrcImageViewer] = useState(null);
  const { config } = useInternalContext();
  const selectedStore = getSelectedStore();

  const openImageViewer = image => {
    if (image) {
      setViewerIsOpen(true);
      setSrcImageViewer(
        `https://d26aylt6qzro7d.cloudfront.net/${
          selectedStore?.merchantId || config.merchantId
        }/${image}`
      );
    }
  };

  const closeImageViewer = () => {
    setViewerIsOpen(false);
    setSrcImageViewer(null);
  };

  return (
    <ImageContext.Provider
      value={{
        openImageViewer,
        viewerIsOpen,
        imageURL,
        closeImageViewer,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export const useImageContext = () => useContext(ImageContext);
