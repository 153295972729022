/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { goToTop } from 'react-scrollable-anchor';

import { tint } from 'polished';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Avatar, HeaderStyled, Logo, Menu, UserContainer } from './style';

import { useInternalContext } from '../../Context';
import ModalUser from '../../components/modalUser';
import { useCartContext, useCashbackContext } from '../../contexts/CartContext';
import { useCookieContext } from '../../contexts/CookieContext';
import { fetchUserDetail } from '../../service';
import {
  debounce,
  getSelectedStore,
  getStorageUser,
  getTakeAwayMode,
  getUser,
  logout,
} from '../../util';
import { ButtonNoStyle, Overlay } from '../../util/style';

const pathnamesHome = {
  '/': true,
  '/home': true,
  '/delivery': true,
};
pathnamesHome[``] = true;
pathnamesHome[`/home`] = true;
pathnamesHome[`/delivery`] = true;

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    config,
    user,
    setUser,
    tableAccountMode,
    accountSelected,
    tableSelected,
  } = useInternalContext();
  const {removeCustomerCookie} = useCookieContext();
  const { removeCashback } = useCartContext();
  const { cashbackConfigs } = useCashbackContext();
  const [modalUserIsOpen, setModalUserIsOpen] = useState(false);
  const [shortHeader, setShortHeader] = useState(false);
  const [isHome, setIsHome] = useState(!!pathnamesHome[location.pathname]);
  const [isStorePage, setIsStorePage] = useState(
    !!location.pathname.includes('loja')
  );

  const loadUser = async () => {
    const localUser = getUser();
    if (!localUser) {
      const tempUser = getStorageUser();
      if (!tempUser || !tempUser.name) return;
      setUser({ ...tempUser, isTempUser: true });
      return;
    }
    if (localUser.isHashUser) {
      return;
    }
    const customer = await fetchUserDetail({ token: localUser.token });
    setUser({ ...customer, token: localUser.token });
  };

  const handleScroll = () => setShortHeader(window.scrollY >= 120);

  const logoutFromHeader = () => {
    logout();
    removeCustomerCookie();
    removeCashback(true);
    setUser(null);
    history.push('/');
  };

  useEffect(() => {
    history.listen(({ pathname }) => {
      if (pathnamesHome[pathname]) {
        setIsHome(true);
      } else {
        setIsHome(false);
      }
      if (pathname.includes('/loja')) {
        setIsStorePage(true);
      } else {
        setIsStorePage(false);
      }
    });
    loadUser();
    window.addEventListener('scroll', debounce(handleScroll, 50));

    return () => {
      window.removeEventListener('scroll', debounce(handleScroll, 50));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToOrders = () => {
    history.push('/orders');
  };
  const goToCashback = () => {
    if (!user) return;
    const { id: userId } = user;
    const customerIdHasSeenExplanation = localStorage.getItem(
      `userSeenExplanation-${userId}`
    );
    history.push(
      `/cashback${customerIdHasSeenExplanation ? '' : '?explanation=true'}`
    );
  };

  const loginUser = () => {
    history.push(`/login`);
  };
  const closeModalUser = () => {
    setModalUserIsOpen(false);
  };

  const openModalUser = () => {
    setModalUserIsOpen(true);
  };

  if (!config) {
    return null;
  }

  if (isHome) {
    return null;
  }

  return (
    <>
      <HeaderStyled config={config} isShort={shortHeader}>
        <Overlay
          className={modalUserIsOpen && 'active'}
          onClick={closeModalUser}
        />
        {isStorePage && accountSelected && (
          <ButtonNoStyle
            style={{
              position: 'absolute',
              left: 135,
              bottom: shortHeader ? 'initial' : 20,
              color: config.textBaseColor,
              fontWeight: 'bold',
              fontSize: '1.1rem',
            }}
            onClick={goToOrders}
          >
            Comanda: {accountSelected.name}
          </ButtonNoStyle>
        )}
        {isStorePage && tableSelected && (
          <ButtonNoStyle
            style={{
              position: 'absolute',
              left: 135,
              bottom: shortHeader ? 'initial' : 20,
              color: config.textBaseColor,
              fontWeight: 'bold',
              fontSize: '1.1rem',
            }}
            onClick={goToOrders}
          >
            Mesa: {tableSelected.name}
          </ButtonNoStyle>
        )}
        <Logo
          src={config.merchantLogo}
          isShort={shortHeader}
          isStorePage={isStorePage}
          onClick={() => {
            const baseElement =
              window.document.getElementById('categoryContainer');
            if (baseElement) {
              baseElement.scrollTo(0, 0);
              goToTop();
            }
            history.push(``);
          }}
          isDefaultCatalogMode={!getSelectedStore()}
        />
        {!tableAccountMode && !getTakeAwayMode() && config.hasLogin !== 'NO' && (
          <>
            {user && config.hasLogin !== 'NO' && (
              <Menu
                style={{
                  color: config.textBaseColor,
                }}
                isOpen={modalUserIsOpen}
              >
                <UserContainer
                  onClick={openModalUser}
                  type="button"
                  color={config.textBaseColor}
                  backgroundColor={tint(0.25, config.headerBackgroundColor)}
                >
                  {user.name && (
                    <>
                      <Avatar
                        backgroundColor={config.headerBackgroundColor}
                        color={config.textBaseColor}
                      >
                        {user.name[0]}
                      </Avatar>
                      <span style={{ fontSize: 16, marginLeft: '0.75rem' }}>
                        {user.name && user.name.split(' ')[0]}
                      </span>
                    </>
                  )}

                  <MdKeyboardArrowDown style={{ marginLeft: '0.5rem' }} />
                </UserContainer>
                <ModalUser
                  isOpen={modalUserIsOpen}
                  user={user}
                  textBaseColor={config.textBaseColor}
                  backgroundColor={config.bottomBackgroundColor}
                  goToOrders={goToOrders}
                  logoutFromHeader={logoutFromHeader}
                  closeModalUser={closeModalUser}
                  cashbackConfigs={cashbackConfigs}
                  goToCashback={goToCashback}
                />
              </Menu>
            )}
          </>
        )}
        {config.hasLogin === 'NO' && (
          <Menu style={{ color: config.textBaseColor }}>
            <span
              onClick={() => {
                goToOrders();
              }}
              style={{ cursor: 'pointer', fontSize: 16, fontWeight: 'bold' }}
            >
              Meus pedidos
            </span>
          </Menu>
        )}
        {config.hasLogin === 'YES' && !user && getSelectedStore() && (
          <Menu style={{ color: config.textBaseColor }}>
            <span
              onClick={!user ? loginUser : logoutFromHeader}
              style={{
                cursor: 'pointer',
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              Entrar
            </span>
          </Menu>
        )}
      </HeaderStyled>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Header;
