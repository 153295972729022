import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import { useQueries } from 'react-query';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import { CardBody, Col, Row } from 'reactstrap';

import { useInternalContext } from '../../Context';
import { fetchDeliveryInformationV2, fetchStores } from '../../service';
import { getSelectedAddress, removeSelectedAddress } from '../../util';
import { Button } from '../button';
import Loading from '../loading';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../modal';
import {
  ArrowDown,
  City,
  DropDown,
  DropDownContent,
  SelectCity,
  StoreCard,
  StoreTab,
} from './style';

const StoreSelectionModal = ({
  isOpen,
  cancelAction,
  selectTab,
  changeAddress,
  confirmAction,
}) => {
  const { config } = useInternalContext();
  const [isLoadingDeliveryStores, setIsLoadingDeliveryStores] = useState(false);
  const [state, setState] = useState({
    stores: [],
    deliveryStores: [],
    cities: [],
    selectedCity: null,
    isDelivery: false,
    isTakeAway: false,
  });
  const [dropDownCity, setDropDownCity] = useState(false);
  const dropRef = useRef();
  const selectedAddress = getSelectedAddress();
  const [
    { data: stores, isLoading: isLoadingStores },
    // { data: deliveryStores, isLoading: isLoadingDeliveryStores },
  ] = useQueries([
    {
      queryKey: 'stores',
      queryFn: () => fetchStores(config.id),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  ]);

  const loadData = useCallback(async () => {
    console.log('# loadData: ', {
      hasDelivery: config.hasDelivery === 'YES',
      deliveryStores: state.deliveryStores,
      selectedAddress,
      selectTab,
    });
    if (
      config.hasDelivery === 'YES' &&
      state.deliveryStores?.length === 1 &&
      selectedAddress &&
      selectTab === 0
    ) {
      console.log('# load data:', state.deliveryStores);
      confirmAction({ ...state.deliveryStores[0], type: 'delivery' });
    }
    const allCities = stores
      .map(store => store.city)
      .concat(state.deliveryStores.map(store => store.city));
    const cities = [...new Set(allCities)];
    if (selectedAddress) {
      const selectedCity = cities.find(city => city === selectedAddress.city);
      if (selectedCity) {
        setState(prevValues => ({ ...prevValues, selectedCity }));
      }
    }
    const isDelivery =
      (config.hasDelivery === 'YES' &&
        config.hasTakeAway === 'YES' &&
        selectTab === 0) ||
      (config.hasDelivery === 'YES' &&
        config.hasTakeAway === 'NO' &&
        selectTab === 0);

    const isTakeAway = !isDelivery;

    setState(prevValues => ({
      ...prevValues,
      cities,
      isDelivery,
      isTakeAway,
    }));
  }, [
    config.hasDelivery,
    config.hasTakeAway,
    confirmAction,
    selectTab,
    selectedAddress,
    state.deliveryStores,
    stores,
  ]);

  useEffect(() => {
    setIsLoadingDeliveryStores(true);
    fetchDeliveryInformationV2({
      zip: selectedAddress ? selectedAddress.zip : null,
      number: selectedAddress ? selectedAddress.number : null,
      street: selectedAddress ? selectedAddress.street : null,
      merchantId: config.merchantId,
      hoursAfterShceduled: config.hoursAfterShceduled,
      digitalMerchantId: config.id,
    })
      .then(data => {
        setState(prevValues => ({ ...prevValues, deliveryStores: data }));
        setIsLoadingDeliveryStores(false);
      })
      .catch(() => {
        setIsLoadingDeliveryStores(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (config && stores && state.deliveryStores) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, stores, state.deliveryStores]);

  const filterStoresBySelectedCity = storesToFilter =>
    storesToFilter.filter(store =>
      state.selectedCity ? store.city === state.selectedCity : store
    );

  const drawStore = ({ storesToDraw, prefix }) => {
    const hasLogo = config.hideStoreLogo === 'NO';
    return (
      <Row style={{ marginLeft: 0, marginRight: 0 }}>
        {storesToDraw.map(store => (
          <Col
            xs={12}
            style={{ opacity: store.open ? 1 : 0.3 }}
            key={`${prefix}_${store.id}`}
            onClick={() => confirmAction({ ...store, type: prefix })}
          >
            <StoreCard boxShadow={config.productCardShadowColor}>
              <CardBody>
                <Row style={{ marginLeft: 0, marginRight: 0 }}>
                  {hasLogo && (
                    <Col
                      md={2}
                      xs={4}
                      style={{
                        borderRight: '1px solid black',
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        alt={`${store.name} parceira da opdv. Sistema de pdv integrado com ifood, Uber eats e Rappi, o melhor pdv do mercado!`}
                        src={store.logo}
                        style={{ width: '100%' }}
                      />
                    </Col>
                  )}
                  <Col xs={12} md={hasLogo ? 10 : 12}>
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                      <Col xs={12} md={hasLogo ? 9 : 10}>
                        <b style={{ fontSize: 18 }}>{store.name}</b>
                      </Col>
                      {store.address && (
                        <Col
                          xs={12}
                          md={hasLogo ? 9 : 10}
                          style={{ fontSize: 14 }}
                        >
                          {prefix === 'takeaway' ? (
                            <span>
                              {store.address.replace(', 0 ()', '')}
                              {store.distance
                                ? `${store.distance.toFixed(2)}km`
                                : ''}
                            </span>
                          ) : (
                            <span>
                              {config.hasVisibleAddress === 'YES' ? (
                                <>
                                  {store.address.replace(', 0 ()', '')}
                                  {store.distance &&
                                    ` (${store.distance.toFixed(2)}km)`}
                                </>
                              ) : (
                                store.distance &&
                                `Distância do endereço atual até a loja: ${store.distance.toFixed(
                                  2
                                )}km`
                              )}
                            </span>
                          )}
                        </Col>
                      )}
                      <Col xs={12} md={hasLogo ? 1 : 2}>
                        <Button
                          outline
                          className="btn-sm"
                          disabled={!store.open}
                        >
                          Selecionar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {!store.open && (
                  <div
                    style={{ fontSize: 12, marginTop: 10, fontWeight: 'bold' }}
                  >
                    Loja está fechada neste horário
                  </div>
                )}
              </CardBody>
            </StoreCard>
          </Col>
        ))}
      </Row>
    );
  };

  const selectCity = city => {
    setState(prevValues => {
      return {
        ...prevValues,
        selectedCity: city,
      };
    });
    if (dropRef.current) {
      dropRef.current.focus();
    }
  };

  const isLoading = isLoadingStores || isLoadingDeliveryStores;
  return (
    <Modal
      isOpen={isOpen}
      closeModal={cancelAction}
      toggle={() => {}}
      size="lg"
    >
      {selectedAddress && (
        <ModalHeader>
          <span>
            <b>
              {`${selectedAddress.street}/${selectedAddress.number} ${
                selectedAddress.complement ? selectedAddress.complement : ''
              }`}
            </b>
            <FaArrowAltCircleDown
              onClick={changeAddress}
              style={{ marginLeft: 10, cursor: 'pointer' }}
            />
          </span>
        </ModalHeader>
      )}
      <ModalBody style={{ height: 600 }}>
        <Tabs
          defaultIndex={selectTab}
          style={{
            width: '100%',
            maxHeight: '40wh',
          }}
        >
          <TabList
            style={{
              position: 'sticky',
              top: -22,
              zIndex: 100,
              backgroundColor: 'white',
            }}
          >
            {config.hasDelivery === 'YES' && (
              <StoreTab
                config={config}
                disabled={isLoading}
                onClick={() =>
                  setState(prevValues => ({
                    ...prevValues,
                    isDelivery: true,
                    isTakeAway: false,
                  }))
                }
                position="left"
              >
                <strong>Receber em casa</strong>
              </StoreTab>
            )}
            {config.hasTakeAway === 'YES' && (
              <StoreTab
                disabled={isLoading}
                config={config}
                onClick={() =>
                  setState(prevValue => ({
                    ...prevValue,
                    deliveryFee: 0,
                    isDelivery: false,
                    isTakeAway: true,
                    deliveryIsCoverage: false,
                    orderErrorMessage: null,
                    selectedAddressId: null,
                    selectedStoreId: null,
                  }))
                }
                position="right"
              >
                <strong>Buscar na loja</strong>
              </StoreTab>
            )}
          </TabList>
          {config.hasDelivery === 'YES' && (
            <TabPanel>
              {isLoading && <Loading message="Carregando lojas..." />}
              {!isLoading && (
                <div>
                  {state.deliveryStores.length === 0 && selectedAddress && (
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                      <Col xs={12}>
                        <hr />
                      </Col>
                      <Col xs={12} style={{ margin: 20 }}>
                        <b>Este endereço está fora do nosso raio de entrega</b>
                        <br />
                        <span>{`${selectedAddress.street}/${selectedAddress.number} ${selectedAddress.complement}`}</span>
                      </Col>

                      <Col xs={12} style={{ marginLeft: 20 }}>
                        <Button outline onClick={changeAddress} config={config}>
                          Trocar Endereço
                        </Button>
                      </Col>
                      <Col xs={12}>
                        <hr />
                      </Col>
                    </Row>
                  )}
                  {!selectedAddress && (
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                      <Col xs={12}>
                        <hr />
                      </Col>
                      <Col xs={12} style={{ margin: 20 }}>
                        <b>
                          Nenhum endereço informado, por favor, informe um
                          endereço
                        </b>
                      </Col>

                      <Col xs={12}>
                        <Button
                          outline
                          onClick={changeAddress}
                          style={{ margin: '0 20px' }}
                          config={config}
                        >
                          Informar endereço
                        </Button>
                      </Col>
                      <Col xs={12}>
                        <hr />
                      </Col>
                    </Row>
                  )}
                  <div>
                    {drawStore({
                      confirmAction,
                      storesToDraw: state.deliveryStores,
                      prefix: 'delivery',
                    })}
                  </div>
                </div>
              )}
            </TabPanel>
          )}
          <TabPanel>
            {isLoading && <Loading message="Carregando lojas..." />}
            {!isLoading && (
              <>
                {state.cities.length > 1 && (
                  <SelectCity>
                    <DropDown
                      ref={dropRef}
                      onClick={() => setDropDownCity(prevValue => !prevValue)}
                      config={config}
                    >
                      <City>
                        <span>
                          {state.selectedCity || 'Filtrar por cidade'}
                        </span>{' '}
                        <ArrowDown
                          className={dropDownCity ? 'active' : undefined}
                          config={config}
                        />
                      </City>
                      {dropDownCity && (
                        <DropDownContent
                          className={dropDownCity ? 'active' : undefined}
                          config={config}
                          role="menuitem"
                        >
                          {state.cities.sort().map(city => (
                            <li
                              role="menuitem"
                              id={city}
                              onKeyDown={() => {}}
                              onClick={() => selectCity(city)}
                              key={city}
                              className={
                                city === state.selectedCity
                                  ? 'selected'
                                  : undefined
                              }
                            >
                              {city}
                            </li>
                          ))}
                        </DropDownContent>
                      )}
                    </DropDown>
                  </SelectCity>
                )}
                <div>
                  {filterStoresBySelectedCity(
                    stores.filter(({ takeAway }) => takeAway)
                  ).length === 0 && (
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                      <Col xs={12}>
                        <hr />
                      </Col>
                      <Col xs={12} style={{ margin: 20 }}>
                        <b>Nenhuma loja tem a opção de retirada</b>
                      </Col>

                      <Col xs={12}>
                        <hr />
                      </Col>
                    </Row>
                  )}
                  {drawStore({
                    confirmAction,
                    storesToDraw: filterStoresBySelectedCity(
                      stores.filter(({ takeAway }) => takeAway)
                    ),
                    prefix: 'takeaway',
                  })}
                </div>
              </>
            )}
          </TabPanel>
        </Tabs>
      </ModalBody>
      <ModalFooter style={{ display: 'inline-grid' }}>
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                if (state.isDelivery && state.deliveryStores.length === 0) {
                  removeSelectedAddress();
                }
                cancelAction();
              }}
              outline
              config={config}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default StoreSelectionModal;
