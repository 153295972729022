import React from 'react';

import { useHistory } from 'react-router-dom';
import { useInternalContext } from '../../Context';
import { useCashbackContext } from '../../contexts/CartContext';
import { toBlr } from '../../util';
import { Button } from '../button';
import { Container, ValueCashback } from './style';

export const CashbackCurrent = () => {
  const { config } = useInternalContext();
  const { cashbackConfigs } = useCashbackContext();
  const history = useHistory();

  if (!cashbackConfigs.active || cashbackConfigs.active === 'NO') {
    return <div />;
  }

  return (
    <Container
      color={config.textBaseColor}
      backgroundColor={config.headerBackgroundColor}
    >
      {cashbackConfigs.currentCustomerValue ? (
        <ValueCashback>
          <span>Disponível: </span>
          <span>{toBlr(cashbackConfigs.currentCustomerValue)}</span>
        </ValueCashback>
      ) : (
        <ValueCashback>
          <span>Cashback disponível</span>
        </ValueCashback>
      )}
      <Button onClick={() => history.push(`/cashback?explanation=true`)}>
        Como utilizar?
      </Button>
    </Container>
  );
};
