import React, { useEffect, useRef } from 'react';
import { useInternalContext } from '../../Context';

import { ButtonCategory, ListCategories, ListItemCategories } from './style';

export const MobileCategories = ({
  shortHeader,
  categories,
  scrollTo,
  currentCategory,
}) => {
  const containerRef = useRef(null);
  const { config } = useInternalContext();
  const { selectedTabColor } = config;

  function handleTouchStart(event) {
    containerRef.current.scrollX = event.touches[0].clientX;
  }

  function handleTouchMove(event) {
    const container = containerRef.current;
    const currentX = event.touches[0].clientX;
    const diffX = currentX - container.scrollX;
    container.scrollLeft -= diffX;
    container.scrollX = currentX;
  }

  useEffect(() => {
    if (currentCategory) {
      const list = containerRef.current;
      const category = list.querySelector(`#category_${currentCategory.id}`);
      if (category) {
        const { offsetLeft } = category;
        const { offsetWidth } = category;
        const listWidth = list.offsetWidth;
        const scrollLeft = offsetLeft - (listWidth - offsetWidth) / 2;
        list.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [currentCategory]);

  return (
    <ListCategories
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      shortHeader={shortHeader}
      role="list"
    >
      {categories.map(category => (
        <ListItemCategories
          role="listitem"
          key={category.id}
          id={`category_${category.id}`}
          colorSelected={selectedTabColor}
          isSelected={
            parseInt(currentCategory?.id, 10) === parseInt(category.id, 10)
          }
        >
          <ButtonCategory
            colorSelected={selectedTabColor}
            isSelected={
              parseInt(currentCategory?.id, 10) === parseInt(category.id, 10)
            }
            onClick={() => scrollTo(category)}
          >
            {category.name}
          </ButtonCategory>
        </ListItemCategories>
      ))}
    </ListCategories>
  );
};
