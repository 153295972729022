import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useInternalContext } from '../../Context';
import { Button } from '../../components/button';
import { useCartContext } from '../../contexts/CartContext';
import { useCookieContext } from '../../contexts/CookieContext';
import {
  colorIsReadableOnBackgroundWhite,
  formatCPF,
  formatPhone,
  getUser,
  logout,
} from '../../util';
import { Line } from './style';

export const User = () => {
  const {
    config: { textBaseColor },
    user,
    setUser,
  } = useInternalContext();
  const { removeCashback } = useCartContext();
  const { removeCustomerCookie } = useCookieContext();
  const history = useHistory();
  const color = useMemo(
    () =>
      colorIsReadableOnBackgroundWhite(textBaseColor)
        ? textBaseColor
        : '#000000',
    [textBaseColor]
  );

  useEffect(() => {
    if (!getUser()) {
      history.push('/login');
    }
  }, [history]);

  return (
    <div>
      <hr style={{ marginTop: 0 }} />
      <h1>{user?.name}</h1>
      <hr />
      <div style={{ width: '90%', margin: 'auto' }}>
        {user?.mainPhone && (
          <Line>
            <h2>Celular</h2>
            <p style={{ color }}>{formatPhone(user.mainPhone)}</p>
          </Line>
        )}
        {user?.email && (
          <Line>
            <h2>E-mail</h2>
            <p style={{ color }}>{user?.email}</p>
          </Line>
        )}
        {user?.cpf && (
          <Line>
            <h2>CPF</h2>
            <p style={{ color }}>{formatCPF(user.cpf)}</p>
          </Line>
        )}
      </div>
      <hr />
      <div>
        <Button
          onClick={() => {
            history.push('/cashback');
          }}
          style={{ width: 150 }}
        >
          Cashback
        </Button>
        <Button
          onClick={() => {
            logout();
            removeCustomerCookie();
            setUser(null);
            removeCashback(true);
            history.push('/login');
          }}
          style={{ width: 150, marginLeft: '1rem' }}
        >
          Sair
        </Button>
      </div>
    </div>
  );
};
