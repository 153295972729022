import React, { useState } from 'react';
import { Col, FormText, Row } from 'reactstrap';

import { useInternalContext } from '../../Context';
import { getSelectedStore, toBlr } from '../../util';
import { Button } from '../button';
import Loading from '../loading';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../modal';
import { SelectCategory } from '../selectCategory';
import { PointsContainer } from './style';

const removeRepeatedProducts = (arr, key) => {
  return [
    ...new Map(
      arr.map((item, index) => (item[key] ? [item[key], item] : [index, item]))
    ).values(),
  ];
};

const PointsBase = props => {
  const { config } = useInternalContext();
  const { redeem, redeemOptionsFiltered, cart } = props;
  const [modalIsOpen, setModalOpenStatus] = useState(false);
  if (
    !redeem ||
    redeem.message ||
    redeem.status === false ||
    JSON.stringify(redeem) === '{}'
  )
    return null;
  const cartRedeemDiscount = cart.products.find(({ redeemId }) => redeemId);
  const selectedStore = getSelectedStore();
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        toggle={() => setModalOpenStatus(prevState => !prevState)}
        className="modal-primary"
      >
        <ModalHeader
          hasCloseButton
          closeModal={() => setModalOpenStatus(false)}
        >
          Resgate de Pontos
        </ModalHeader>
        <ModalBody>
          <div style={{ width: '100%', height: '100%', padding: 24 }}>
            <div
              style={{ display: `${props.loadingModal ? 'none' : 'block'}` }}
            >
              <SelectCategory
                value={props.categoryToFilterRedeem}
                onChange={props.categoryOnChange}
                token={props.token}
                optionsIds={redeem?.options?.map(option => option.categoryId)}
                hasDiscountValue={redeem?.options?.find(
                  option => option.discountValue
                )}
              />
            </div>
            {props.loadingModal ? (
              <Loading />
            ) : (
              <>
                {redeem.loyaltyPointsTerms && (
                  <div style={{ marginBottom: '1rem' }}>
                    <span>
                      <b>Regras:</b>{' '}
                      <a href={redeem.loyaltyPointsTerms} target="_new">
                        Clique para abrir
                      </a>
                    </span>
                  </div>
                )}
                <div>
                  {redeemOptionsFiltered &&
                    removeRepeatedProducts(
                      redeemOptionsFiltered,
                      'productId'
                    ).map(item => (
                      <Row
                        key={`product_card_${
                          item.productId || item.discountId
                        }_${item.categoryId}`}
                        style={{
                          borderTop: '1px solid #dee2e6',
                          marginTop: 4,
                          paddingTop: 8,
                          paddingBottom: 4,
                          alignItems: 'center',
                        }}
                      >
                        <Col xs={4} lg={2}>
                          {item.productImage && (
                            <img
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: 400,
                              }}
                              src={`https://d26aylt6qzro7d.cloudfront.net/${
                                selectedStore?.merchantId || config.merchantId
                              }/${item.productImage}`}
                              alt="produto do sistema o pdv da empresa OPDV"
                            />
                          )}
                          {item.discountValue && (
                            <span style={{ fontSize: '1.5em' }}>
                              {toBlr(item.discountValue)}
                            </span>
                          )}
                        </Col>
                        <Col lg={10} xs={8}>
                          <Row>
                            <Col xs={12}>
                              <h4 style={{ fontWeight: 'bold' }}>
                                {item.productName || item.discountName}
                              </h4>
                              <span style={{ fontSize: 18 }}>
                                Custo: {item.pointsToRedeem} pontos
                              </span>
                            </Col>
                            <Col xs={12}>
                              <Button
                                disabled={
                                  !item.isCanRedeem || cartRedeemDiscount
                                }
                                onClick={() => {
                                  props.addRedeemItem(item);
                                  setModalOpenStatus(false);
                                }}
                              >
                                Resgatar
                              </Button>
                              <Col xs={12} />
                              {!item.isCanRedeem && (
                                <FormText>
                                  Você ainda não tem pontos para este produto
                                </FormText>
                              )}
                              {cartRedeemDiscount && (
                                <FormText color="red">
                                  Você só pode resgatar um produto por vez
                                </FormText>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                </div>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={() => setModalOpenStatus(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <PointsContainer {...props} config={config}>
        <h4 style={{ marginBottom: '0px' }}>{redeem.totalPoints} Pontos</h4>
        <Button
          style={{ marginLeft: 8 }}
          {...props}
          onClick={() => setModalOpenStatus(true)}
        >
          Resgatar
        </Button>
      </PointsContainer>
    </>
  );
};

export default PointsBase;
