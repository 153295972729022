import React from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

import { useInternalContext } from '../../Context';
import { ProductCount } from './style';
import { ProductBase } from './productBase';

export const MultipleChoiseStep = ({
  option,
  step,
  wizardSteps,
  addItem,
  subtractItem,
  index,
}) => {
  const { config } = useInternalContext();
  const {
    image: productImageURL,
    name: productName,
    price: productPrice,
    description: productDescription,
    quantity: productQuantity,
  } = option;
  return (
    <div className="group-item">
      <ProductBase
        productDescription={productDescription}
        productImageURL={productImageURL}
        productName={productName}
        productPrice={productPrice}
      />
      <ProductCount config={config}>
        {productQuantity > 0 && (
          <>
            <AiOutlineMinus
              size={24}
              style={{ cursor: 'pointer' }}
              onClick={subtractItem({
                option,
                step,
              })}
            />
            <span style={{ margin: '0.5em', userSelect: 'none' }}>
              {productQuantity}
            </span>
          </>
        )}
        <AiOutlinePlus
          size={24}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const currentVariation = wizardSteps.find(
              wizard => wizard.id === step.id
            );

            const quantityOptionals = currentVariation?.options?.reduce(
              (acc, current) => acc + current.quantity,
              0
            );
            if (quantityOptionals + 1 === currentVariation.max) {
              const modalBody = document.querySelector('.modal-body');

              const nextElement = document.querySelector(`#step_${index + 1}`);
              if (nextElement) {
                modalBody.scrollTop = nextElement.offsetTop;
              } else {
                // Faz o scroll até o fim da página
                modalBody.scrollTop =
                  modalBody.scrollHeight - modalBody.clientHeight;
              }
            }
            addItem({ option, step })();
          }}
        />
      </ProductCount>
    </div>
  );
};
