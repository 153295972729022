import styled from 'styled-components';
import {
  Modal as ModalBootstrap,
  ModalBody as ModalBodyBootstrap,
  ModalFooter as ModalFooterBootstrap,
} from 'reactstrap';

export const ModalBase = styled(ModalBootstrap)``;

export const ModalHeaderBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  color: black;
  border-radius: ${props => (props.isMobile ? '0' : '12px 12px 0 0')};
  border-bottom-style: solid !important;
  border-bottom-color: rgba(0, 0, 0, 0.2) !important;
  border-width: 0 0 0.55px !important;
  margin: -1px;
`;

export const ModalBodyBase = styled(ModalBodyBootstrap)`
  padding: 0px;
`;

export const ModalFooterBase = styled(ModalFooterBootstrap)``;
