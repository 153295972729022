/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Col } from 'reactstrap';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useInternalContext } from '../../Context';
import { getSelectedStore } from '../../util';
import { Campaign } from './style';

/** @returns {React.JSX.Element} */
export const BannerView = ({
  banners,
  configColor,
  categoryIndex,
  category,
  onClick,
}) => {
  const { config } = useInternalContext();
  const selectedStore = getSelectedStore();
  return (
    <Col
      lg={6}
      key={`banner_${categoryIndex}_${category.id}`}
      sm={12}
      md={6}
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <div
        style={{
          width: '100%',
          boxShadow: 'rgba(0, 0, 0, 0.08) 1px 1px 1px !important',
          borderRadius: 25,
          marginTop: 4,
          border: `1px solid ${configColor.bannerCardBorderColor}`,
        }}
      >
        {!banners && category.image && (
          <img
            alt={`${category.name} faz parte de pdv OPDV que é um sistem integrado com ifood e Uber Eats - OPDV`}
            style={{ width: '90%' }}
            src={`https://d26aylt6qzro7d.cloudfront.net/${
              selectedStore?.merchantId || config.merchantId
            }/${category.image}`}
          />
        )}
      </div>
    </Col>
  );
};

/** @returns {React.JSX.Element} */
export const BannerSimpleView = ({ campaign, bannerIndex, onClick }) => {
  const { config } = useInternalContext();
  const selectedStore = getSelectedStore();
  return (
    <Col
      lg={12}
      key={`banner_view_${bannerIndex}`}
      sm={12}
      md={12}
      style={{
        cursor: 'pointer',
      }}
    >
      {campaign && (
        <Campaign>
          {campaign.imagesFull?.length > 1 && (
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              spaceBetween={50}
              slidesPerView="auto"
              pagination={{
                clickable: true,
              }}
              autoplay={{ delay: 3000 }}
              speed={500}
              navigation
              loop
            >
              {campaign.imagesFull.map(image => (
                <SwiperSlide>
                  <img
                    key={image.id}
                    alt={`${campaign.name} é uma campanha que faz parte de pdv OPDV. O sistem integrado com ifood e Uber Eats - OPDV`}
                    style={{ width: '100%', maxHeight: 400 }}
                    src={`https://d26aylt6qzro7d.cloudfront.net/${
                      selectedStore?.merchantId || config.merchantId
                    }/${image.image}`}
                    onClick={() => onClick(image.image)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          {campaign.images?.length === 1 && (
            <img
              alt={`${campaign.name} é uma campanha que faz parte de pdv OPDV. O sistem integrado com ifood e Uber Eats - OPDV`}
              style={{ width: '100%', maxHeight: 400 }}
              src={`https://d26aylt6qzro7d.cloudfront.net/${
                selectedStore?.merchantId || config.merchantId
              }/${campaign.images[0]}`}
              onClick={onClick}
            />
          )}
        </Campaign>
      )}
    </Col>
  );
};
// eslint-disable-next-line import/no-default-export
export default BannerView;
