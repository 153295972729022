import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: white !important;
}

h4 {
  font-size: 1.375rem;
  margin-bottom: 0.5rem;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
}
h1 {
  font-size: 2.25rem;
  margin-bottom: 0.5rem;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
}

.App {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  text-align: center;
  background-color: white;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-red {
  color: red !important;
}

.card-body {
  overflow: overlay;
}

.loadingAnimation {
  height: 20%;
  width: 20%;
}

.modal-backdrop {
  width: 100% !important;
}
.modal-content {
  border-radius: 10px;
  width: 600px!important;
  min-width: auto;
  max-width: auto;
  border-width: 0;
  border-style: none;
  border-color: transparent;
  background: var(#ffffff,#fff);
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  z-index: 10;
  margin: 0 auto;
  overflow: initial;
}
.modal-dialog {
  max-width: 70%;
}
.modal-lg {
  width: 600px!important;
  height: 100%!important;
}
.modal-open {
  overflow: hidden !important;
}

.modal-class-product {
  overflow: hidden !important;
}


.Select-menu-outer {
  z-index: 999 !important;
}
.Select.is-open {
  position: relative;
  z-index: 1000;
}

.no-overflow {
  overflow: hidden !important;
}

.small {
  font-size: 1rem;
  font-weight: 300;
}
.modal-content-product {
  padding: 12px 
}

.noBackground .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent !important;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.3rem;
  outline: 0;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}

.swiper-button-prev, .swiper-button-next {
  color: #000 !important;
}

.swiper-wrapper{
  padding-bottom: 30px;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 0px !important;
}

@media (min-width: 1000px) {
  .modal-content {
    max-height: 95vh;
  }
  .modal-body {
    overflow: overlay;
  }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
}
 .modal-content {
    width: 100vw;
 }
}
@media (max-width: 1000px) {
  .modal-content-product {
    padding: 0px 
  }
  .Toastify__toast-container {
    bottom: 0 !important;
  }
  .loadingAnimation {
    height: 40% !important;
    width: 40% !important;
  }
  .modal-lg {
    height: 100% !important;
  }
  .modal-dialog {
    max-width: 100% !important;
    height: 100%;
    margin: 0 !important;
  }
  .modal-content {
    height: 100% !important;
    width: 100%  !important;
    border-radius: 0;
  }
  .modal-title {
    flex: 1 !important;
    justify-content: space-around !important;
  }
  .modal-footer {
    padding: 1rem !important;
  }

  .modal-body {
    overflow: overlay !important;
  }
  .modal-open .modal {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .card-body {
    overflow: overlay;
  }
}

.group-item {
  border-top: 0.1rem solid #f4f4f4;
  padding: 10px 0;
  font-size: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.product-info {
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
  user-select: none;
}
.product-info p {
  color: var(--light-gray);
  font-weight:lighter;
  font-size: 14px;
  user-select: none;
}
.product-image {
  user-select: none;
  max-width: 20%!important;
}
.product-image img {
  width: 100%;
  border: 0;
}
.product-resume-image {
  user-select: none;
  text-align: center;


}
.product-resume-image img{
  height: 372px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.product-name {
  color: #a9a9a9;
  font-size: 19px;
}


`;
