import styled from 'styled-components';
import { Card, Col, Row } from 'reactstrap';
import { Button } from '../../components/button';

export const CartCheckout = styled(Card)`
  text-align: left;
  padding: 25px 100px;
  border-bottom: none;
  @media (max-width: 1400px) {
    padding: 25px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.87em;
  color: red;
  @media (max-width: 1000px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const StoreCard = styled(Card)`
  margin-top: 4px;
`;

export const ForecastTime = styled.span`
  font-size: 0.87em;
  @media (max-width: 1000px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const AddressLabel = styled.span`
  font-size: 0.87em;
  @media (max-width: 1000px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const CloseIcon = styled.span`
  color: black;
  cursor: pointer;
  float: left;
`;
export const CloseHideDesktopIcon = styled.span`
  color: black;
  cursor: pointer;
  float: left;
  display: none;
  @media (max-width: 1000px) {
    display: inline;
  }
`;

export const H4Destkop = styled.h4`
  display: inline;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const RowDesktop = styled(Row)`
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const H4Mobile = styled.h4`
  display: none;
  @media (max-width: 1000px) {
    display: inline;
  }
`;

export const CartContainer = styled(Card)`
  padding: 0;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
  border: 1px solid #f7f7f7;
  max-height: 60vh;
  width: 500px;
`;

export const ColPayments = styled(Col)`
  display: flex;
  align-items: center;
`;

export const ButtonPaymentOptions = styled(Button)`
  width: 180px;
  margin: 4px;
  opacity: 1;

  @media (max-width: 1000px) {
    width: 50% !important;
  }
`;

export const PixPaymentContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 20px;

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  .qr-code {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .copia-e-cola {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    .input-wrapper-copia-e-cola {
      max-width: 400px;

      > button {
        display: flex;
        width: 100%;
        background: none;
        border: 1px solid #939393;
        border-radius: 0.25rem;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        padding: 10px 15px;

        @media (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
`;
