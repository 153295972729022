import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { captureException } from '@sentry/react';
import { listProductCategories } from '../../service';

export const SelectCategory = ({
  token,
  value,
  optionsIds,
  onChange,
  hasDiscountValue,
  ...rest
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getCategories() {
      try {
        const optionsFetched = await listProductCategories({
          token,
        });
        if (optionsIds) {
          const uniqOptionsIds = [...new Set(optionsIds)];
          const optionsToSet = optionsFetched
            .filter(option => uniqOptionsIds.includes(option.id))
            .map(option => ({
              value: option.id,
              label: option.name,
            }));
          if (hasDiscountValue) {
            optionsToSet.unshift({
              value: 'only-discount',
              label: 'Descontos',
            });
          }
          optionsToSet.unshift({ value: null, label: 'Todas' });
          setOptions(optionsToSet);
        } else {
          if (hasDiscountValue) {
            optionsFetched.unshift({
              value: 'only-discount',
              label: 'Descontos',
            });
          }
          optionsFetched.unshift({ value: null, label: 'Todas' });
          setOptions(
            optionsFetched.map(option => ({
              value: option.id,
              label: option.name,
            }))
          );
        }
      } catch (error) {
        captureException(error);
        console.log(error);
      }
    }

    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (options.length === 0) {
    return <div />;
  }
  return (
    <FormGroup>
      <Label>Filtrar por categoria</Label>
      <Select
        placeholder="Selecione.."
        options={options}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </FormGroup>
  );
};
