import React from 'react';

const stylesContainer = {
  justifyContent: 'center',
  alignContent: 'center',
  width: '100%',
  paddingLeft: '0%',
  textAlign: 'center',
  height: '100%',
};

const Loading = ({
  message,
  messageColor = 'black',
  height = '20%',
  fixed = false,
}) => (
  <div
    style={
      fixed
        ? {
            position: 'fixed',
            background: 'white',
            zIndex: 20,
            ...stylesContainer,
          }
        : stylesContainer
    }
  >
    <img
      alt=" "
      className="loadingAnimation"
      style={{
        height,
        width: '20%',
        fill: 'white',
      }}
      src={`${process.env.PUBLIC_URL}/loading.svg`}
    />

    <span
      style={{
        marginTop: 10,
        float: 'left',
        width: '100%',
        color: messageColor,
        textAlign: 'center',
      }}
    >
      {message}
    </span>
  </div>
);

export default Loading;
