import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { FormText } from 'reactstrap';

import { useInternalContext } from '../../Context';
import { useCartContext } from '../../contexts/CartContext';
import {
  calcProductTotalValue,
  createStoreEncodedName,
  getSelectedStore,
  getUser,
} from '../../util';
import { trackEvent } from '../../util/track';
import { Button } from '../button';
import { AllSteps } from '../cart/allSteps';
import CartResume from '../cart/resume';
import Step from '../cart/step';
import { MoneyLabel, ProductCount } from '../cart/style';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../modal';
import { ProductHeader } from './header';

/**
 * @typedef {object} Props
 * @property {object} state
 * @property {Function} setState
 * @property {Function} onAddProductCallback
 * @property {object} storeDetail
 */
/**
 *
 * @param {Props} props
 * @returns
 */
export const ProductModal = ({
  state,
  setState,
  onAddProductCallback,
  storeDetail,
}) => {
  // TODO : REMOVER esse setState, adicionar functions de callback especificas para cada coisa
  const { config } = useInternalContext();
  const { addToCart, cart, replaceToCart } = useCartContext();
  const history = useHistory();
  const params = useParams();

  const disabledButtonAddToCart = state.wizardSteps.findIndex(wizardStep => {
    if (wizardStep.min === 0) {
      return false;
    }

    const quantitySelectedFormData = Object.values(wizardStep.formData).filter(
      object => object.checked
    ).length;
    if (
      quantitySelectedFormData &&
      quantitySelectedFormData >= wizardStep.min
    ) {
      return false;
    }
    const quantityOptionals = wizardStep?.options?.reduce(
      (acc, current) => acc + current.quantity,
      0
    );

    if (quantityOptionals && quantityOptionals >= wizardStep.min) {
      return false;
    }
    return true;
  });

  const modifyProduct = () =>
    setState(prevValues => ({
      ...prevValues,
      wizardIndex: prevValues.wizardIndex - 1,
    }));

  const closeProductDetail = () => {
    const url = `/loja/${createStoreEncodedName(getSelectedStore()?.name)}`;
    history.replace(url, null);
    setState(prevValues => ({
      ...prevValues,
      cartResumeShow: true,
      wizardSteps: [],
      simple: false,
      wizardIndex: 0,
      productDetail: false,
      product: {},
    }));
  };

  const addProduct = () => {
    if (!storeDetail?.open) {
      return;
    }
    if (disabledButtonAddToCart >= 0) {
      const modalBody = document.querySelector('.modal-body');
      const nextElement = document.querySelector(
        `#step_${disabledButtonAddToCart}`
      );
      if (nextElement) {
        modalBody.scrollTop = nextElement.offsetTop;
      }
      return;
    }
    onAddProductCallback();

    if (!params || !params.id) {
      addToCart({ ...state.product, wizardSteps: [...state.wizardSteps] });
    } else {
      replaceToCart({ ...state.product, wizardSteps: [...state.wizardSteps] });
    }
    const url = `/loja/${createStoreEncodedName(getSelectedStore().name)}`;
    history.replace(url, null);
    // if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
    //   ReactGA.pageview(url);
    // }
    // if (process.env.REACT_APP_PIXEL) {
    //   ReactPixel.pageView();
    // }

    const cartElement = document.querySelector('#cart');
    cartElement.scrollTo(0, cartElement.scrollHeight);

    trackEvent({
      name: 'AddToCart',
      data: {
        productId: state.product.id,
        productName: state.product.name,
        cartJson: JSON.stringify(cart),
        user: getUser(),
      },
    });
  };

  const subtractProduct = () => {
    if (state.product.quantity > 1) {
      trackEvent({
        name: 'subtractProductQuantity',
        data: {
          productId: state.product.id,
          productName: state.product.name,
          user: getUser(),
        },
      });
      setState(prevValues => ({
        ...prevValues,
        product: {
          ...prevValues.product,
          quantity: prevValues.product.quantity - 1,
        },
      }));
    }
  };

  const sumProduct = () => {
    trackEvent({
      name: 'sumProductQuantity',
      data: {
        productId: state.product.id,
        productName: state.product.name,
        user: getUser(),
      },
    });
    setState(prevValues => ({
      ...prevValues,
      product: {
        ...prevValues.product,
        quantity: prevValues.product.quantity + 1,
      },
    }));
  };

  const subtractOptional =
    ({ step: stepData, option }) =>
    () => {
      const quantity = (option.quantity || 0) - 1;

      if (quantity < 0) return;
      trackEvent({
        name: 'subtractProductOptional',
        data: {
          productId: state.product.id,
          productName: state.product.name,
          option,
          user: getUser(),
        },
      });
      const wizardStepsToState = state.wizardSteps.map(stepWizard => {
        if (stepData.id !== stepWizard.id) return stepWizard;
        return {
          ...stepWizard,
          options: stepWizard.options.map(optionStep => {
            if (optionStep.id !== option.id) return optionStep;
            return {
              ...optionStep,
              quantity,
            };
          }),
        };
      });
      setState(prevValues => ({
        ...prevValues,
        product: {
          ...prevValues.product,
          multiple: wizardStepsToState,
        },
        wizardSteps: wizardStepsToState,
      }));
    };

  const sumOptional =
    ({ step: stepData, option }) =>
    () => {
      const quantity = (option.quantity || 0) + 1;
      trackEvent({
        name: 'sumProductOptional',
        data: {
          productId: state.product.id,
          productName: state.product.name,
          option,
          user: getUser(),
        },
      });

      const countSelectedOptionals = stepData.options.reduce(
        (previusValue, _, index, items) => {
          return previusValue + items[index].quantity;
        },
        1
      );
      if (countSelectedOptionals > stepData.max) return;

      const wizardStepsToState = state.wizardSteps.map(stepWizard => {
        if (stepData.id !== stepWizard.id) return stepWizard;
        return {
          ...stepWizard,
          options: stepWizard.options.map(optionStep => {
            if (optionStep.id !== option.id) return optionStep;
            return {
              ...optionStep,
              quantity,
            };
          }),
        };
      });
      setState(prevValues => ({
        ...prevValues,
        product: {
          ...prevValues.product,
          multiple: wizardStepsToState,
        },
        wizardSteps: wizardStepsToState,
      }));
    };

  if (config.montageProductInSteps === 'YES') {
    const step = state.wizardSteps[state.wizardIndex];

    return (
      <Modal
        isOpen={state.productDetail}
        size="lg"
        style={{ maxWidth: 1000 }}
        contentClassName="modal-content-product"
        modalClassName="modal-class-product"
        closeModal={closeProductDetail}
      >
        <ModalHeader
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderBottom: 'none',
            textAlign: 'right',
          }}
          title={state.product.name}
          hasCloseButton
          closeModal={closeProductDetail}
        />

        {state.wizardSteps.length > 0 && state.cartResumeShow && (
          <ModalBody id="body-13">
            <CartResume
              product={state.product}
              startStep={() =>
                setState(prevValues => ({
                  ...prevValues,
                  cartResumeShow: true,
                }))
              }
              categoryTextColor={config.textCategoryColor}
            />
          </ModalBody>
        )}
        {(state.wizardSteps.length === 0 || !state.cartResumeShow) && (
          <Step
            simpleItem={state.simple}
            step={step}
            subtractProduct={subtractProduct}
            sumProduct={sumProduct}
            product={state.product}
            updateWizardSteps={wizarSteps =>
              setState(prevValues => ({ ...prevValues, wizarSteps }))
            }
            updateObservation={value =>
              setState(prevValues => ({ ...prevValues, product: value }))
            }
            wizardSteps={state.wizardSteps}
            subtractOptional={subtractOptional}
            sumOptional={sumOptional}
            wizardIndex={state.wizardIndex}
            categoryTextColor={config.textCategoryColor}
            moveBack={() => {
              trackEvent({
                name: 'clickOnStepBack',
                data: {
                  step: step.title,
                  user: getUser(),
                },
              });
              setState(prevValues => ({
                ...prevValues,
                wizardIndex: prevValues.wizardIndex - 1,
              }));
            }}
            moveNext={() => {
              trackEvent({
                name: 'clickOnStepNext',
                data: {
                  step: step.title,
                  user: getUser(),
                },
              });
              setState(prevValues => ({
                ...prevValues,
                wizardIndex: prevValues.wizardIndex + 1,
              }));
            }}
          />
        )}
        {!state.simple && state.cartResumeShow && (
          <ModalFooter>
            <Button outline color="danger" onClick={closeProductDetail}>
              Cancelar
            </Button>
            <Button
              disabled={!storeDetail?.open}
              onClick={() =>
                setState(prevValues => ({
                  ...prevValues,
                  cartResumeShow: false,
                }))
              }
            >
              {storeDetail?.open ? 'Avançar' : 'Loja fechada'}
            </Button>
          </ModalFooter>
        )}
        {(state.simple || !step) && (
          <ModalFooter>
            {state.wizardIndex !== 0 && (
              <Button
                outline
                onClick={modifyProduct}
                disabled={!storeDetail?.open}
              >
                Modificar
              </Button>
            )}

            <Button
              onClick={addProduct}
              disabled={!storeDetail?.open}
              style={{ width: '100%' }}
            >
              {storeDetail?.open ? 'Adicionar' : 'Loja Fechada'}
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }

  if (config.montageProductInSteps === 'NO') {
    return (
      <Modal
        isOpen={state.productDetail}
        size="lg"
        contentClassName="modal-content-product"
        modalClassName="modal-class-product"
        closeModal={closeProductDetail}
      >
        <ModalHeader
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderBottom: 'none',
            textAlign: 'right',
          }}
          title={state.product.name}
          hasCloseButton
          closeModal={closeProductDetail}
        />
        <ModalBody style={{ scrollBehavior: 'smooth' }}>
          <ProductHeader
            productDescription={state.product.description}
            productImage={state.product.image}
          />

          <AllSteps
            wizardSteps={state.wizardSteps}
            disabledButtonAddToCart={disabledButtonAddToCart}
            updateWizardSteps={wizarSteps =>
              setState(prevValues => ({ ...prevValues, wizarSteps }))
            }
            subtractOptional={subtractOptional}
            sumOptional={sumOptional}
            config={config}
          />

          <div style={{ marginTop: '1.5em', display: 'flex', flex: 1 }}>
            <div style={{ flex: 1, paddingRight: 4, paddingLeft: 4 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                  alignItems: 'center',
                  color: '#717171',
                  fontWeight: 500,
                  fontSize: '1rem',
                  margin: '20px 0 10px',
                }}
              >
                <span>Alguma observação?</span>
                <span>{state.product.observation?.length || 0}/124</span>
              </div>
              <textarea
                style={{
                  width: '100%',
                  borderRadius: 4,
                  border: '1px solid #dcdcdc',
                  backgroundColor: '#FFF',
                  fontWeight: 400,
                  resize: 'none',
                  padding: 10,
                  height: 'calc(3.15em + 20px)',
                }}
                value={state.product.observation}
                placeholder="Ex.: Sem cebola, sem pimenta, etc."
                maxLength="124"
                onChange={({ target: { value: observation } }) =>
                  setState(prevValues => ({
                    ...prevValues,
                    product: { ...prevValues.product, observation },
                  }))
                }
              />
              {state.product.observation && (
                <FormText>Limite de 124 caracteres</FormText>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {state.wizardIndex !== 0 && (
            <Button
              outline
              onClick={modifyProduct}
              disabled={!storeDetail?.open}
            >
              Modificar
            </Button>
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ProductCount config={config} makeBox>
              {state.product.quantity > 0 && (
                <>
                  <div
                    style={{
                      height: 20,
                      width: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AiOutlineMinus
                      size={20}
                      style={{ cursor: 'pointer' }}
                      onClick={subtractProduct}
                    />
                  </div>
                  <span
                    style={{
                      minWidth: 35,
                      height: 30,
                      fontWeight: 500,
                      margin: '3px 0',
                      display: 'inline-block',
                      fontSize: '1.13em',
                      textAlign: 'center',
                      userSelect: 'none',
                    }}
                  >
                    {state.product.quantity}
                  </span>
                </>
              )}
              <div
                style={{
                  height: 20,
                  width: 20,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AiOutlinePlus
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onClick={sumProduct}
                />
              </div>
            </ProductCount>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {storeDetail?.open && (
                <Button
                  onClick={addProduct}
                  isDisabled={
                    disabledButtonAddToCart >= 0 || !storeDetail?.open
                  }
                  style={{ width: '100%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ fontSize: '1rem' }}>Adicionar</span>
                    <MoneyLabel color={config.categoryTextColor} isAddButton>
                      {calcProductTotalValue({
                        cartProduct: {
                          ...state.product,
                          wizardSteps: state.wizardSteps,
                        },
                        multiplyQuantity: true,
                        format: true,
                      })}
                    </MoneyLabel>
                  </div>
                </Button>
              )}
              {!storeDetail?.open && (
                <small style={{ color: 'red' }}>Loja fechada</small>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  return <div />;
};
