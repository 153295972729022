import styled from 'styled-components';
import { colorIsReadableOnBackgroundWhite } from '.';

// Utilizar em todo elemento que seja clicável que não seja um button
// exemplo: textos/ícones clicáveis
export const ButtonNoStyle = styled.button`
  border: none;
  background: none;
`;

export const Overlay = styled.div`
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: 0.3s;
  cursor: ${props => (props.invisible ? 'initial' : 'pointer')};

  &.active {
    z-index: 14;
    opacity: ${props => (props.invisible ? 0 : 0.3)};
    visibility: visible;
  }
`;

export const CloseButtonModal = styled.div`
  cursor: pointer;
  border-radius: 25px;
  color: ${props => props.color};
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 30px;
    margin-left: auto;
  }
  &:hover {
    background-color: ${props => props.color};
    color: ${props =>
      colorIsReadableOnBackgroundWhite(props.color) ? '#fff' : '#000'};
  }
`;
