/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IoMdClose } from 'react-icons/io';
import {
  formatCep,
  formatCNPJ,
  formatPhone,
  isMobile as IsMobileFunction,
} from '../../util';
import { ButtonNoStyle, CloseButtonModal, Overlay } from '../../util/style';
import {
  AboutTab,
  Container,
  Content,
  HoursTab,
  ListContainer,
  ListItem,
} from './style';

const convertIndexOnDays = {
  0: 'Domingo',
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
};

const convertOpenHours = hour => {
  if (!hour) return '';
  const [hour1, hour2] = hour.split('|');

  const day = moment().format('DD');
  const month = moment().format('MM');
  const year = moment().format('YYYY');

  const convertedDateStart = moment(
    `${day}/${month}/${year} ${hour1} +0000`,
    'DD/MM/YYYY HH:mm Z'
  );

  const convertedDateEnd = moment(
    `${day}/${month}/${year} ${hour2} +0000`,
    'DD/MM/YYYY HH:mm Z'
  );

  return `${convertedDateStart.local().format('HH:mm')} às ${convertedDateEnd
    .local()
    .format('HH:mm')}`;
};

const ModalStoreInfo = ({
  mainColor,
  isOpen,
  toggle,
  store,
  openHours: { openDays, openHours1, openHours2 } = {},
  isPageMode = false,
}) => {
  const isMobile = IsMobileFunction();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const handleKeyPress = event => {
      if (isOpen && toggle && event.key === 'Escape') {
        toggle();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (isMobile && !isPageMode) return <div />;

  return (
    <>
      {!isPageMode && (
        <Overlay
          style={{ zIndex: 20 }}
          className={isOpen && 'active'}
          onClick={toggle}
        />
      )}

      <Container isOpen={isOpen}>
        {!isMobile && (
          <CloseButtonModal
            style={{ position: 'absolute', left: 20, top: 15 }}
            color={mainColor}
            onClick={toggle}
          >
            <IoMdClose size={30} />
          </CloseButtonModal>
        )}
        <Content>
          <ListContainer>
            <ListItem mainColor={mainColor} isActive={currentTab === 0}>
              <ButtonNoStyle
                style={{ width: '100%' }}
                onClick={() => setCurrentTab(0)}
              >
                Sobre
              </ButtonNoStyle>
            </ListItem>
            <ListItem mainColor={mainColor} isActive={currentTab === 1}>
              <ButtonNoStyle
                style={{ width: '100%' }}
                onClick={() => setCurrentTab(1)}
              >
                Horário
              </ButtonNoStyle>
            </ListItem>
          </ListContainer>

          {currentTab === 0 && (
            <AboutTab>
              <h1>{store.name}</h1>
              <hr />
              <h2>Endereço</h2>
              <span>{store.address}</span>
              <span>
                {store.city} - {store.state}
              </span>
              {store.zip && <span>{formatCep(store.zip)}</span>}
              <hr />
              <h2>Outras informações</h2>
              {store.phone && <span>Telefone: {formatPhone(store.phone)}</span>}
              {store.cnpj && <span>CNPJ: {formatCNPJ(store.cnpj)}</span>}
              <hr />
            </AboutTab>
          )}
          {currentTab === 1 && (
            <HoursTab>
              {openHours1 === null && openHours2 === null ? (
                <p style={{ textAlign: 'center' }}>
                  Horários indisponíveis para esta loja
                </p>
              ) : (
                <>
                  {openDays?.split('').map((isOpenOnDay, index) => (
                    <div key={index}>
                      <span className="weekday">
                        {convertIndexOnDays[index]}
                      </span>
                      <span className="hoursday">
                        {isOpenOnDay === '1' ? (
                          <span>
                            {convertOpenHours(openHours1 && openHours1[index])}
                            <br />
                            {convertOpenHours(openHours2 && openHours2[index])}
                          </span>
                        ) : (
                          'Fechado'
                        )}
                      </span>
                    </div>
                  ))}
                </>
              )}
            </HoursTab>
          )}
        </Content>
      </Container>
    </>
  );
};

export default ModalStoreInfo;
