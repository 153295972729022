import styled from 'styled-components';

export const ListCategories = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  width: 100%;
  z-index: 14;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  transition: top 0.3s;
  top: ${props => (props.shortHeader ? '70px' : '')};
  font-size: 1rem;

  flex-wrap: nowrap;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* habilita o deslocamento com toque no iOS */

  @media (min-width: 999px) {
    display: none;
  }
`;

export const ListItemCategories = styled.div`
  margin: 0px 20px;
  height: 50px;
  display: flex;
  border-bottom: ${props =>
    props.isSelected
      ? `2px solid ${props.colorSelected}`
      : '2px solid transparent'};
`;

export const ButtonCategory = styled.button`
  border: none;
  background: none;
  width: max-content;
  font-weight: ${props => (props.isSelected ? 'bold' : '500')};
  /* border-bottom: ${props =>
    props.isSelected ? `2px solid ${props.colorSelected}` : ''}; */

  outline: none;
`;
