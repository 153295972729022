import styled from 'styled-components';

export const Footer = styled.div`
  padding: 0.75rem !important;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    padding: 0.9rem !important;
  }
`;

export const VariationName = styled.span`
  font-size: 1em;
  text-align: left;
  @media (max-width: 1000px) {
    font-size: calc(7px + 1.5vmin);
  }
`;

export const OptionalName = styled.span`
  font-size: calc(2px + 1.3vmin);
  text-align: left;
  float: left;
  @media (max-width: 1000px) {
    font-size: calc(7px + 1.3vmin);
  }
`;

export const ProductCartLabel = styled.span`
  font-size: calc(6px + 1vmin);
  float: left;
  @media (max-width: 1000px) {
    font-size: calc(9px + 1.5vmin);
  }
`;

export const ProductCartPrice = styled.span`
  font-size: calc(6px + 1vmin);
  text-align: right;
  float: right;
  @media (max-width: 1000px) {
    font-size: calc(9px + 1.5vmin);
  }
`;

export const ProductPriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: lighter;
`;

export const ProductPrice = styled.span`
  font-size: calc(7px + 1.3vmin);
  font-weight: lighter;
  @media (max-width: 1000px) {
    font-size: calc(9px + 1.5vmin);
  }
`;

export const ProductName = styled.span`
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  display: inline-block;
  margin: 5px 0;
  color: ${props => props.color};
  @media (max-width: 1000px) {
    font-size: 1.2em;
  }
`;

export const HintHeader = styled.span`
  color: #9a9a;
  font-size: 1em;
  text-align: left;
`;

export const MoneyLabel = styled.span`
  font-size: ${props => (props.isAddButton ? '1em' : '1.3em')};
  color: ${props => props.color};
  font-weight: bold;
  @media (max-width: 1000px) {
    font-size: ${props => (props.isAddButton ? '.86em' : '1.2em')};
  }
`;

export const FullHeigthDiv = styled.div`
  max-height: ${props => props.height * 0.6}px !important;
`;

export const ProductCount = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  align-items: center;
  border: ${props => (props.makeBox ? '1px solid #dcdcdc' : '')};
  margin-right: 10px;
  justify-content: flex-end;
  svg {
    color: ${props => props.config.bottomBackgroundColor};
    :hover {
      color: darken(${props => props.config.bottomBackgroundColor});
    }
  }
`;

export const ButtonOpenImageOptional = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.categoryTextColor};
  padding: 2px 4px;
  border-radius: 5px;
  background-color: transparent;
  &:hover {
    border-width: 2px;
  }
`;

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  border: 0;
  background: none;
  width: 100%;
  color: ${props => props.textCategoryColor || '#000'};
`;
