import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueries } from 'react-query';

import ModalStoreInfo from '../../components/modalStoreInfo';
import { useInternalContext } from '../../Context';
import { fetchOpenHours, fetchStore } from '../../service';
import { getSelectedStore, getUser } from '../../util';
import Loading from '../../components/loading';

export const Info = () => {
  const {
    config: { textCategoryColor, hasLogin },
  } = useInternalContext();

  const history = useHistory();
  const storeId = getSelectedStore().id || getSelectedStore().storeId;

  const [
    { data: storeDetail, isLoading: isLoadingStore },
    { data: openHours, isLoading: isLoadingOpenHours },
  ] = useQueries([
    {
      queryKey: ['store', storeId],
      queryFn: () => fetchStore(storeId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['openhours', storeId],
      queryFn: () =>
        fetchOpenHours({
          hasLogin,
          storeId,
          token: null,
          user: getUser(),
        }),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  ]);

  useEffect(() => {
    if (!storeId) {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingOpenHours || isLoadingStore) {
    return <Loading />;
  }

  return (
    <ModalStoreInfo
      isOpen
      mainColor={textCategoryColor}
      store={storeDetail}
      openHours={openHours}
      isPageMode
    />
  );
};
