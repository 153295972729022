import { captureException } from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormText,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';

import AddressSelectionModal from '../../components/addressSelection';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import StoreSelectionModal from '../../components/storeSelection';
import {
  createAccount,
  doSendSmsAgain,
  fetchUserDetail,
  verifyAccount,
} from '../../service';
import {
  getSelectedStore,
  storeSelectedAddress,
  storeSelectedStore,
  storeUser,
  validateCpf,
} from '../../util';
import { CreateAccountCard } from './style';

import { useInternalContext } from '../../Context';
import { useCookieContext } from '../../contexts/CookieContext';

function useInterval(callback, delay) {
  const intervalRef = useRef();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(
        () => callbackRef.current(),
        delay
      );
      return () => window.clearInterval(intervalRef.current);
    }
    return () => {};
  }, [delay]);

  return intervalRef;
}

const CreateAccount = () => {
  const internaVal = useRef(null);
  const { config, setUser } = useInternalContext();
  const { customerCookie } = useCookieContext();
  const [selectStoreModal, setSelectStoreModal] = useState(false);
  const [addressSelectModal, setAddressSelectModal] = useState(false);
  const [selectTab, setSelectTab] = useState(0);

  const history = useHistory();

  const removeInternaval = () => clearInterval(internaVal.current);
  useEffect(() => () => removeInternaval(), []);
  console.log({customerCookie})
  const [state, setState] = useState({
    email: '',
    mainPhone: customerCookie.phone || '',
    cpf: '',
    password: '',
    name: customerCookie.name || '',
    loading: false,
    validateCodeStep: false,
  });

  const [time, setTime] = useState(30);
  const [isPaused, setPaused] = useState(true);
  const [smsSended, setSmsSended] = useState(false);
  const [addressInformation, setAddressInformation] = useState({ zip: '' });

  const intervalRef = useInterval(
    () => {
      if (time > 0) {
        setTime(prevTime => prevTime - 1);
      } else {
        window.clearInterval(intervalRef.current);
        setPaused(true);
      }
    },
    isPaused ? null : 1000
  );

  const createAccountForm = async e => {
    e.preventDefault();
    const { name, email, password, cpf, mainPhone } = state;
    if (
      (config.cpfRequired === 'YES' && !validateCpf(cpf)) ||
      (cpf && cpf.trim() !== '' && !validateCpf(cpf))
    ) {
      toast.warn('o CPF não é valido.');
      return;
    }
    setState(prevValues => ({ ...prevValues, loading: true }));

    const store = getSelectedStore();
    const customer = {
      name,
      email,
      password,
      cpf: cpf.replace(/\D/g, ''),
      mainPhone: mainPhone.replace(/\D/g, ''),
      storeId: store?.id,
    };

    try {
      const callback = await createAccount(customer);
      if (!callback.error) {
        setState(prevValues => ({
          ...prevValues,
          token: callback.token,
          validateCodeStep: true,
          phoneToValidate: mainPhone.replace(/\D/g, ''),
        }));
        setPaused(false);
      } else {
        toast.warn(callback.error);
      }
    } catch (error) {
      captureException(error);
      console.error('e', error);
    }
    setState(prevValue => ({ ...prevValue, loading: false }));
  };

  const sendAgain = async () => {
    setTime(30);
    setPaused(false);
    setSmsSended(true);
    const { token: validateToken } = state;
    await doSendSmsAgain({ validateToken });
  };

  const verifyCode = async e => {
    e.preventDefault();

    const { code, token } = state;
    try {
      const verified = await verifyAccount({ code, token });
      if (verified.error) {
        toast.warn(verified.error);
        setState(prevValues => ({
          ...prevValues,
          loading: false,
        }));

        return;
      }
      const completedUser = await fetchUserDetail({ token: verified.token });
      storeUser({ ...completedUser, token: verified.token });
      setUser({ ...completedUser, token: verified.token });
      history.push(``);
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    setState(prevValues => ({
      ...prevValues,
      loading: false,
    }));
  };

  const smsButtonMessage = () => {
    if (time !== 0)
      return smsSended
        ? `Tentar por SMS novamente em: 00:${`${time}`.padStart(2, 0)}`
        : `Tentar por SMS em: 00:${`${time}`.padStart(2, 0)}`;
    return 'Enviar SMS';
  };

  const confirmStoreSelection = async store => {
    storeSelectedStore(store);
    setAddressSelectModal(false);
    setSelectStoreModal(false);
  };

  useEffect(() => {
    const store = getSelectedStore();
    if (!store) {
      setSelectStoreModal(true);
    }
  }, []);

  return (
    <div>
      {selectStoreModal && (
        <StoreSelectionModal
          changeAddress={() => {
            setSelectStoreModal(false);
            setAddressSelectModal(true);
          }}
          cancelAction={() => {
            toast.warn('Você precisa selecionar uma loja para criar uma conta!')
          }}
          selectTab={selectTab}
          isOpen={selectStoreModal}
          confirmAction={confirmStoreSelection}
        />
      )}
      <AddressSelectionModal
        openNewAddress={() => {
          setAddressSelectModal(true);
        }}
        cancelAction={() => {
          setAddressInformation({ zip: '' });
          setAddressSelectModal(false);
          setSelectStoreModal(true);
        }}
        addressInformationValues={{ ...addressInformation }}
        isOpen={addressSelectModal}
        openStoreSelection={() => {
          setAddressSelectModal(false);
          setSelectStoreModal(true);
          setSelectTab(1);
        }}
        confirmAction={addressInformationData => {
          storeSelectedAddress(addressInformationData);
          setAddressSelectModal(false);
          setSelectStoreModal(true);
        }}
      />
      <Modal size="sm" isOpen={state.validateCodeStep}>
        <form onSubmit={verifyCode}>
          <ModalBody>
            <Row>
              <Col lg={6} sm={12} md={6}>
                <Input
                  disabled
                  required
                  value={state.mainPhone}
                  placeholder="Telefone"
                  disableAnimationLabel
                />
              </Col>
              <Col lg={6} sm={12} md={6} style={{ color: 'black' }}>
                <Input
                  required
                  maxLength={6}
                  type="number"
                  value={state.code}
                  onChange={({ target: { value } }) =>
                    setState(prevValues => ({ ...prevValues, code: value }))
                  }
                  placeholder="Código"
                />
                <FormText style={{ marginTop: -10 }}>
                  O código foi enviado para seu{' '}
                  <span style={{ color: '#25D366' }}>WhatsApp</span>, caso não
                  tenha recebido, você pode tentar o envio por{' '}
                  <span style={{ color: 'blue' }}>SMS</span>.
                </FormText>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ flexDirection: 'column' }}>
            <Row style={{ width: '100%' }}>
              <div style={{ marginLeft: 15 }}>
                <Button
                  outline
                  onClick={sendAgain}
                  color="primary"
                  disabled={time !== 0}
                >
                  {smsButtonMessage()}
                </Button>
              </div>
              <div style={{ marginLeft: 'auto', marginRight: 15 }}>
                <Button
                  color="primary"
                  type="submit"
                  style={{
                    appearance: 'initial',
                    WebkitAppearance: 'initial',
                  }}
                >
                  Verificar Código
                </Button>
                <Button
                  color="danger"
                  style={{
                    appearance: 'initial',
                    WebkitAppearance: 'initial',
                    marginLeft: 10,
                  }}
                  onClick={() =>
                    setState(prevValues => ({
                      ...prevValues,
                      validateCodeStep: false,
                    }))
                  }
                >
                  Cancelar
                </Button>
              </div>
            </Row>
          </ModalFooter>
        </form>
      </Modal>

      <form onSubmit={createAccountForm}>
        <CreateAccountCard>
          <CardHeader>Faça seu cadastro</CardHeader>
          <CardBody>
            <Row>
              <Col lg={6} sm={12}>
                <Input
                  name="name"
                  placeholder="Nome"
                  required
                  onChange={({ target: { value } }) =>
                    setState(prevValues => ({
                      ...prevValues,
                      name: value,
                    }))
                  }
                  value={state.name}
                />
              </Col>
              <Col lg={6} sm={12}>
                <Input
                  placeholder="E-mail"
                  type="email"
                  name="email"
                  required
                  value={state.email}
                  onChange={({ target: { value } }) =>
                    setState(prevValues => ({
                      ...prevValues,
                      email: value,
                    }))
                  }
                />
              </Col>
              <Col lg={6} sm={12}>
                <PatternFormat
                  placeholder="CPF"
                  format="###.###.###-##"
                  mask="_"
                  value={state.cpf}
                  required={config.cpfRequired === 'YES'}
                  name="cpf"
                  onChange={({ target: { value } }) =>
                    setState(prevValues => ({
                      ...prevValues,
                      cpf: value,
                    }))
                  }
                  customInput={Input}
                />
              </Col>
              <Col lg={6} sm={12}>
                <Input
                  type="password"
                  placeholder="Senha"
                  required
                  name="password"
                  onChange={({ target: { value } }) =>
                    setState(prevValues => ({
                      ...prevValues,
                      password: value,
                    }))
                  }
                />
              </Col>
              <Col lg={6} sm={12}>
                <PatternFormat
                  format="(##) #####-####"
                  mask="_"
                  required
                  name="mainPhone"
                  value={state.mainPhone}
                  onChange={({ target: { value } }) =>
                    setState(prevValues => ({
                      ...prevValues,
                      mainPhone: value,
                    }))
                  }
                  placeholder="Telefone"
                  customInput={Input}
                />
                <FormText style={{ marginTop: -13 }}>
                  Um código SMS será enviado para este número para confirmar seu
                  cadastro.
                </FormText>
              </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
              <Col xs={12}>
                <Button
                  color="primary"
                  disabled={state.loading}
                  type="submit"
                  style={{
                    width: '40%',
                    appearance: 'initial',
                    WebkitAppearance: 'initial',
                    fontSize: 12,
                  }}
                >
                  {state.loading ? 'Criando conta..' : 'Criar Conta'}
                </Button>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row style={{ maringTop: 8 }}>
              <Col xs={12} style={{ textAlign: 'center' }}>
                <Button
                  color="primary"
                  outline
                  onClick={() => history.push(`/login`)}
                  style={{ fontSize: 12, border: 0 }}
                >
                  Voltar para Login
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </CreateAccountCard>
      </form>
    </div>
  );
};

export default CreateAccount;
