import { getSelectedStore, getStoreOpenHours } from './index';

/**
 *
 * @param {string} configString
 * @returns boolean
 */
export const isInRange = configString => {
  const [startTime, endTime] = configString.split('|');

  // Get the current time
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();

  // Parse the start time
  const [startHours, startMinutes] = startTime.split(':');

  // Parse the end time
  const [endHours, endMinutes] = endTime.split(':');

  // Convert everything to minutes for easier comparison
  const currentTimeInMinutes = currentHours * 60 + currentMinutes;
  const startTimeInMinutes = parseInt(startHours) * 60 + parseInt(startMinutes);
  const endTimeInMinutes = parseInt(endHours) * 60 + parseInt(endMinutes);

  // Handle the case where the end time is on the next day
  if (endTimeInMinutes < startTimeInMinutes) {
    return (
      currentTimeInMinutes >= startTimeInMinutes ||
      currentTimeInMinutes <= endTimeInMinutes
    );
  }

  // Check if the current time is within the range
  return (
    currentTimeInMinutes >= startTimeInMinutes &&
    currentTimeInMinutes <= endTimeInMinutes
  );
};

/**
 *
 * @returns boolean | null
 */
export const isStoreOpen = () => {
  const config = getStoreOpenHours(getSelectedStore()?.id);
  if (!config || !config.openDays) {
    return null;
  }
  const day = new Date().getDay();
  if (config.openDays.split()[day] === '0') {
    return false;
  }
  const time1 =
    config.openHours1 && config.openHours1[day] ? config.openHours1[day] : null;

  const time2 =
    config.openHours2 && config.openHours2[day] ? config.openHours2[day] : null;
  if (time1 && time2) {
    return isInRange(time1) || isInRange(time2);
  }
  if (time1 && !time2) {
    return isInRange(time1);
  }
  if (!time1 && time2) {
    return isInRange(time2);
  }
};
