import styled from 'styled-components';

export const Campaign = styled.div`
  width: 100%;

  .swiper-pagination {
    bottom: 10% !important;
    @media (max-width: 1000px) {
      bottom: 20% !important;
    }
  }
  .swiper-pagination-bullet {
    width: 20px !important;
    height: 4px !important;
    border-radius: 0 !important;
    background-color: white !important;
    transition: 0.3s !important;
    opacity: 0.4 !important;
    @media (max-width: 1000px) {
      height: 2px !important;
    }
  }
  .swiper-pagination-bullet-active {
    width: 40px !important;
    opacity: 1 !important;
  }

  .swiper-button-prev {
    background-color: #fff8 !important;
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;

    &::after {
      font-size: 14px;
    }

    @media (max-width: 1000px) {
      width: 25px !important;
      height: 25px !important;
      &::after {
        font-size: 10px;
      }
    }
  }
  .swiper-button-next {
    background-color: #fff8 !important;
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;

    &::after {
      font-size: 14px;
    }

    @media (max-width: 1000px) {
      width: 25px !important;
      height: 25px !important;

      &::after {
        font-size: 10px;
      }
    }
  }
`;
