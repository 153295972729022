import { detect } from 'detect-browser';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import ReactGA4 from 'react-ga4';

import { PatternFormat } from 'react-number-format';

import { captureException } from '@sentry/react';
import { useHistory, useParams } from 'react-router-dom';
import { useInternalContext } from '../../Context';
import AddressSelectionModal from '../../components/addressSelection';
import { ButtonGetGeoLocation } from '../../components/buttonGetGeoLocation';
import HomeBanner from '../../components/homeBanner';
import Loading from '../../components/loading';
import StoreSelectionModal from '../../components/storeSelection';
import { StoreCard } from '../../components/storeSelection/style';
import { useCartContext } from '../../contexts/CartContext';
import {
  fetchAddressInformation,
  fetchDeliveryInformationV2,
  fetchStores,
  getSaleCart,
  trackData,
  valideteHashcode,
} from '../../service';
import {
  createStoreEncodedName,
  getSelectedStore,
  removeSelectedAddress,
  setStorageUser,
  storeSelectedAddress,
  storeSelectedStore,
  storeUser,
} from '../../util';
import { startTracks } from '../../util/track';

const browser = detect();

function createStoreCard(store, config, selectStore) {
  return (
    <Col
      sm={12}
      md={6}
      key={store.id}
      style={{
        marginTop: 4,
        opacity: store.open ? 1 : 0.3,
      }}
    >
      <StoreCard
        boxShadow={config.productCardShadowColor}
        onClick={() => selectStore(store)}
      >
        <Card style={{ height: 130, overflow: 'hidden' }}>
          <CardBody style={{ overflow: 'hidden' }}>
            <Row style={{ textAlign: 'left' }}>
              <Col xs={12}>
                <b style={{ fontSize: 18 }}>{store.name}</b>
              </Col>
              {store.address && config.hasVisibleAddress === 'YES' && (
                <Col xs={12} style={{ fontSize: 12 }}>
                  <>
                    {store.address.replace(', 0 ()', '')}
                    {store.distance && ` (${store.distance.toFixed(2)}km)`}
                  </>
                </Col>
              )}
            </Row>
            {!store.open && (
              <div style={{ fontSize: 12, marginTop: 4, fontWeight: 'bold' }}>
                Loja está fechada neste horário
              </div>
            )}
          </CardBody>
        </Card>
      </StoreCard>
    </Col>
  );
}

/** @returns {React.JSX.Element} */
const Home = () => {
  const history = useHistory();
  const params = useParams();
  const { setUser, config } = useInternalContext();
  const { clearCart, updateCart } = useCartContext();
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addressSelectModal, setAddressSelectModal] = useState(false);
  const [addressInformation, setAddressInformation] = useState({ zip: '' });
  const [selectTab, setSelectTab] = useState(0);
  const [selectStoreModal, setSelectStoreModal] = useState(false);

  useEffect(() => {
    const { search } = window.location;
    trackData({
      track: search
        ? new URLSearchParams(search).get('track') || 'empty'
        : 'empty',
      platform: browser.name,
      os: browser.os,
      url: window.location.href,
      platformVersion: browser.version,
    });

    const hash = new URLSearchParams(window.location.search).get('hash');
    const promo = new URLSearchParams(window.location.search).get('promo');
    if (hash) {
      valideteHashcode(hash).then(data => {
        sessionStorage.setItem('sourceId', data.sourceId);
        sessionStorage.setItem('externalId', data.externalId);
        storeSelectedAddress({
          zip: data.zip,
          number: data.number,
          street: data.street,
          complement: data.complement,
          city: data.city,
          neighborhood: data.neighborhood,
          state: data.state,
        });
        return fetchDeliveryInformationV2({
          zip: data.zip,
          number: data.number,
          street: data.street,
          merchantId: config.merchantId,
          hoursAfterShceduled: config ? config.hoursAfterShceduled : 0,
          digitalMerchantId: config.id,
        }).then(storesDeliveryHash => {
          const store = storesDeliveryHash.find(
            ({ id }) => parseInt(id, 10) === parseInt(data.storeId, 10)
          );
          if (store) {
            startTracks(store, history);
            storeSelectedStore({
              ...store,
              id: parseInt(store.id, 10),
              storeId: parseInt(store.id, 10),
              type: 'delivery',
            });
            if (config.hasLogin !== 'NO') {
              storeUser({
                ...data,
                id: data.id,
                token: data.token,
              });
              setUser({ ...data });
            } else {
              storeUser(null);
            }
            if (config.hasLogin === 'NO') {
              setStorageUser({
                ...data,
              });
            }

            history.push(`/loja/${createStoreEncodedName(store.name)}`);
          }
        });
      });
      return;
    }
    if (promo) {
      const [salesTrackId, mainPhone, promocodeId] =
        decodeURI(promo).split('|');
      setIsLoading(true);
      getSaleCart({
        salesTrackId,
        mainPhone,
        promocodeId,
      })
        .then(data => {
          const { cart: dataCard } = data;
          if (dataCard?.discount) {
            updateCart(dataCard);
          }
          console.log('cart:', dataCard);
          setIsLoading(false);
        })
        .catch(e => {
          console.error(e);
          setIsLoading(false);
        });
    }
    if (
      config.homeMode === 'DEFAULT_CATALOG' &&
      config.defaultCatalogId &&
      !getSelectedStore()
    ) {
      history.push(`/cardapio`);
      return;
    }

    sessionStorage.removeItem('sourceId');
    sessionStorage.removeItem('externalId');
    const store = getSelectedStore();
    if (store) {
      const { sku } = params;
      startTracks(store, history);
      history.push(
        `/loja/${createStoreEncodedName(store.name)}${sku ? `/${sku}` : ''}`
      );
    } else {
      fetchStores(config.id).then(values => {
        setStores(values.sort((a, b) => b.open - a.open));
        if (values.length === 1) {
          startTracks(values[0], history);
          storeSelectedStore({
            ...values[0],
            type:
              values[0].takeAway && config.hasTakeAway !== 'NO'
                ? 'takeaway'
                : 'NONE',
          });
          history.push(`/loja/${createStoreEncodedName(values[0].name)}`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normalizedCep = useMemo(() => {
    return addressInformation.zip.replace('_', '').replace('-', '');
  }, [addressInformation.zip]);

  const loadAddressFromCep = async zipCode => {
    setIsLoading(true);
    const addressInformationValues = await fetchAddressInformation(zipCode)
      .then(value => ({
        ...value,
        canEditStreet: !value.street || value.street.trim() === '',
        street: value?.street?.trim() || '',
        number: '',
        complement: '',
      }))
      .catch(err => {
        captureException(err);
        return {
          zip: zipCode,
          error: 'Cep não encontrado',
        };
      });
    setIsLoading(false);
    setAddressInformation(addressInformationValues);
    if (!addressInformationValues.error) {
      setAddressSelectModal(true);
    }
  };

  useEffect(() => {
    if (normalizedCep.length === 8) {
      loadAddressFromCep(normalizedCep);
    }
  }, [addressInformation.error, normalizedCep]);

  const selectStore = store => {
    startTracks(store, history);
    storeSelectedStore({ ...store, type: 'takeaway' });
    const { sku } = params;
    history.push(
      `/loja/${createStoreEncodedName(store.name)}${sku ? `/${sku}` : ''}`
    );
  };

  const openStoreSelection = () => {
    setSelectStoreModal(true);
    setSelectTab(1);
    setSelectStoreModal(false);
  };

  const confirmAddressSelection = async addressInformationData => {
    removeSelectedAddress();
    storeSelectedAddress(addressInformationData);
    setAddressSelectModal(false);
    setSelectStoreModal(true);
  };

  const confirmStoreSelection = async store => {
    storeSelectedStore(store);
    startTracks(store, history);
    const url = `/loja/${createStoreEncodedName(store.name)}`;
    console.log('# confirmStoreSelection: ', url);
    ReactGA4.send({
      hitType: 'pageview',
      page: `/loja/${createStoreEncodedName(store.name)}`,
    });
    console.log('# 1 store: ', store);
    console.log('# 1 getSelectedStore(): ', getSelectedStore());
    if (
      store.id !== (getSelectedStore() || {}).id &&
      store.id !== (getSelectedStore() || {}).storeId
    ) {
      clearCart();
    }
    setIsLoading(true);
    setAddressSelectModal(false);
    setSelectStoreModal(false);
    history.push(url);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <HomeBanner
        merchantBannerSrc={config.merchantBanner}
        merchantLogoSrc={config.merchantLogo}
        headerBackgroundColor={config.headerBackgroundColor}
      />
      {selectStoreModal && (
        <StoreSelectionModal
          changeAddress={() => {
            setSelectStoreModal(false);
            setAddressSelectModal(true);
          }}
          cancelAction={() => setSelectStoreModal(false)}
          selectTab={selectTab}
          isOpen={selectStoreModal}
          confirmAction={confirmStoreSelection}
        />
      )}
      {addressSelectModal && (
        <AddressSelectionModal
          openNewAddress={() => {
            setAddressSelectModal(true);
          }}
          cancelAction={() => {
            setAddressInformation({ zip: '' });
            setAddressSelectModal(false);
          }}
          addressInformationValues={{ ...addressInformation }}
          isOpen={addressSelectModal}
          openStoreSelection={openStoreSelection}
          confirmAction={confirmAddressSelection}
          stores={stores}
          showButtonGetUserLocation={false}
        />
      )}
      <Card
        style={{
          maxWidth: 992,
          paddingLeft: 42,
          paddingRight: 42,
          paddingTop: 42,
          marginTop: 20,
        }}
      >
        <CardBody>
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <Col xs={12}>
              <FormGroup>
                <Label style={{ fontSize: 20, fontWeight: 700 }}>
                  Informe seu CEP e faça o pedido na loja mais próxima de você
                </Label>
                <PatternFormat
                  disabled={isLoading}
                  format="#####-###"
                  mask="_"
                  value={addressInformation.zip}
                  required
                  onChange={({ target: { value } }) =>
                    setAddressInformation(prevValues => ({
                      ...prevValues,
                      zip: value,
                    }))
                  }
                  style={{ marginTop: 12 }}
                  placeholder="CEP"
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <ButtonGetGeoLocation
                callback={address => {
                  setAddressInformation(address);
                  setAddressSelectModal(true);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            {isLoading && (
              <Col xs={12}>
                <Loading height="100%" />
              </Col>
            )}
            {!isLoading && addressInformation.error && (
              <Col xs={12}>
                <span style={{ color: 'red' }}>{addressInformation.error}</span>
              </Col>
            )}
          </Row>
          {normalizedCep?.length !== 8 && (
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col xs={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Label style={{ margin: '1rem' }}>OU</Label>
                  <Label style={{ fontWeight: 700 }}>PARA RETIRADA</Label>
                  <Label>Escolha a loja mais próxima de você!</Label>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      <div
        style={{
          maxWidth: 992,
          padding: 42,
        }}
      >
        {isLoading && (
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <Col xs={12}>
              <div style={{ width: 150, height: 150 }}>
                <Loading />
              </div>
            </Col>
          </Row>
        )}
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
          {stores
            .filter(({ takeAway }) => takeAway)
            .map(store => createStoreCard(store, config, selectStore))}
        </Row>
      </div>
    </div>
  );
};
export default Home;
