import React from 'react';
import { useInternalContext } from '../../Context';
import { useImageContext } from '../../ImageContext';
import { getSelectedStore } from '../../util';

export const ProductHeader = ({ productImage, productDescription }) => {
  const { config } = useInternalContext();
  const { openImageViewer } = useImageContext();
  const selectedStore = getSelectedStore();
  return (
    <div style={{ marginBottom: 32 }}>
      {productImage && (
        <div
          className="product-resume-image"
          tabIndex={0}
          role="button"
          onKeyDown={() => {}}
          onClick={() => openImageViewer(productImage)}
        >
          <img
            src={`https://d26aylt6qzro7d.cloudfront.net/${
              selectedStore?.merchantId || config.merchantId
            }/${productImage}`}
            alt="produto do sistema o pdv da empresa OPDV"
          />
        </div>
      )}
      {productDescription && (
        <div
          style={{
            fontWeight: 'lighter',
            marginTop: 16,
            padding: 12,
            marginBottom: 16,
          }}
        >
          {productDescription}
        </div>
      )}
    </div>
  );
};
