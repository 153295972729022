import styled from 'styled-components';
import { Button } from '../../components/button';

export const Container = styled.main`
  h1 {
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 0;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 700;
  }

  p {
    text-align: left;
  }

  .swiper-pagination {
    top: 87% !important;
  }

  .swiper-pagination-bullet {
    background-color: ${props => props.bulletColor || '#000'} !important;
  }

  @media (max-width: 1000px) {
    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
`;

export const HeaderCashback = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  padding: 1rem;
  margin-bottom: 4rem;

  > button {
    position: absolute;
    left: 3rem;
    @media (max-width: 1000px) {
      left: 1rem;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 1rem;
  }
`;

export const ContainerStep = styled.div`
  position: relative;

  border: 2px solid #d9d9d9;
  border-radius: 20px;

  max-width: 450px;
  height: 450px;
  max-height: 55vh;
  margin: auto;
  padding: 2rem 4rem 6rem;

  > h2 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  > p {
    margin-bottom: 2rem;
  }

  @media (max-width: 1000px) {
    padding: 1rem 2rem;
    height: 40vh;
    border: 0;
    img {
      width: 120px;
    }
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: -9%;
  left: -9%;

  font-size: 36px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  width: 70px;
  height: 70px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ContainerExtract = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
  max-width: 2000px;
  margin: auto;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;

export const Extract = styled.div`
  /* tirando 1 rem daqui e do outro componente irmão para ter 1.0rem de margin entre os dois */
  width: calc(50% - 2rem);
  padding: 2rem 4rem;

  border: 2px solid ${props => props.borderColor || '#d9d9d9'};
  border-radius: 20px;

  font-size: 1.25rem;
  font-weight: 500;
  height: ${props => (props.isEmpty ? '200px' : '500px')};

  @media (max-width: 1000px) {
    width: 100%;
    font-size: 1rem;
    height: auto;
  }
`;

export const ExtractTotals = styled.div``;

export const TotalLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Transactions = styled.div`
  width: calc(50% - 2rem);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  position: relative;
  top: -15px;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
`;

export const TransactionsFilters = styled.div`
  display: flex;
  justify-content: space-evenly;
  > button {
    font-weight: 500;
    text-transform: uppercase;
    padding: 1rem 2rem;
  }
`;

export const TransactionWrapper = styled.div`
  margin-top: 2rem;
  height: 418px;
  overflow: overlay;
`;

export const Transaction = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;
  margin-bottom: 2rem;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 2rem;
  padding-top: 1.5rem;
`;

export const EmptyTransactions = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 2rem;
  padding-top: 1.5rem;
  text-align: center;
  font-weight: 600;

  > button {
    height: 30px;
    font-size: 0.775rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 0 auto;
  }
  @media (max-width: 1000px) {
    font-size: 0.875rem;
  }
`;

export const Date = styled.small`
  color: #555;
  font-size: 90%;
`;

export const InfosTransaction = styled.div`
  display: grid;
  margin-top: 0.5rem;
  grid-template-columns: 1fr 1fr auto;
  gap: 0px 0px;
  grid-template-areas: 'total cashback details';

  @media (max-width: 1000px) {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 1fr;

    grid-template-areas:
      'total cashback'
      'details details';
  }
`;

export const TotalTransaction = styled.div`
  grid-area: total;
  display: flex;
  flex-direction: column;
`;

export const CashbackTransaction = styled.div`
  grid-area: cashback;
  display: flex;
  flex-direction: column;
`;

export const DetailsButtonTransaction = styled(Button)`
  grid-area: details;
  height: 30px;
  font-size: 0.775rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  @media (max-width: 1000px) {
    margin-right: auto;
    margin-top: 1rem;
  }
`;

export const Terms = styled.footer`
  width: 100%;
  height: 150px;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const TermsMobile = styled.footer`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  @media (min-width: 1000px) {
    display: none;
  }
`;
