import moment from 'moment';
import { calcTotalCart } from '.';
import { PAYMENT_TYPES } from '../constants/index';

const generateSubItems = wizardSteps => {
  const subItems = [];
  wizardSteps.forEach(variation => {
    variation.options
      .filter(
        ({ quantity, id }) =>
          quantity ||
          (variation.formData[id] && variation.formData[id].checked === true)
      )
      .forEach(value => {
        const subItem = {
          quantity: value.quantity || 1,
          discount: 0,
          type: variation.type,
          variationId: variation.id,
          product: {
            id: value.id,
            name: value.realvalueName || value.digitalName || value.name,
            price: value.price || 0,
          },
        };
        subItems.push(subItem);
      });
  });
  return subItems;
};

export const createOrder = ({
  cartProducts,
  address,
  customer,
  storeId,
  billingAddress,
  customerId,
  deliveryCommission,
  promocodeId,
  change,
  discount,
  timeOfDay,
  payment = {},
  isTakeAway,
  deliveryFee,
  scheduled = 0,
  sourceId = 112,
  externalId,
  dayOfWeek,
  observation,
  tableId,
  accountId,
  orderId,
  cashback,
}) => {
  console.log('# order isTakeAway: ', isTakeAway);
  let totalDiscount = 0;
  if (discount) {
    totalDiscount += parseInt(discount, 10);
  }

  const cartRedeemDiscount = cartProducts.find(({ redeemId }) => redeemId);
  if (cartRedeemDiscount) {
    totalDiscount += parseInt(cartRedeemDiscount.price, 10) * -1;
  }

  const cartProductsWithoutRedeem = cartProducts.filter(
    ({ redeemId, price }) => !redeemId || price === 0
  );

  const payments = [];
  if (Object.entries(payment).length > 0) {
    payments.push({
      value:
        payment.type === PAYMENT_TYPES.MONEY
          ? change ||
            calcTotalCart({
              discount: totalDiscount,
              cartProducts: cartProductsWithoutRedeem,
              deliveryFee,
              cashback: cashback || 0,
              format: false,
            })
          : calcTotalCart({
              discount: totalDiscount,
              cartProducts: cartProductsWithoutRedeem,
              deliveryFee,
              cashback: cashback || 0,
              format: false,
            }),
      paymentMethod: {
        id: payment.id,
        key: payment.methodKey,
        name: payment.name,
      },
    });
  }

  if (cashback) {
    payments.push({
      value: cashback,
      paymentMethod: {
        id: null,
        key: 'cashback',
        name: 'Cashback',
      },
    });
  }

  const productTotal = calcTotalCart({
    cartProducts: cartProductsWithoutRedeem,
    deliverFee: 0,
    cashback: 0,
    format: false,
  });
  console.log('# order productTotal: ', productTotal);
  const total = calcTotalCart({
    cartProducts: cartProductsWithoutRedeem,
    discount: totalDiscount,
    deliveryFee,
    cashback: cashback || 0,
    format: false,
  });

  const output = {
    customer,
    billingAddress,
    // não deixar desconto maior que o valor de produtos para não ficar negativo o valor do pedido
    discount: totalDiscount > productTotal ? productTotal : totalDiscount,
    customerId,
    externalId,
    createdDate: scheduled
      ? dayOfWeek &&
        timeOfDay &&
        moment(`${dayOfWeek}/${timeOfDay.format('HH:mm')}`, 'DD/MM/HH:mm')
      : null,
    scheduled,
    storeId,
    redeemOptionId: cartRedeemDiscount ? cartRedeemDiscount.redeemId : null,
    discountId: -1,
    deliveryFee: deliveryFee || 0,
    deliveryCommission,
    promocodeId,
    address: !address || address.error || isTakeAway ? null : address,
    observation: process.env.REACT_APP_TIME_TO_SCHEDULED
      ? `PEDIDO AGENDADO PARA DIA ${dayOfWeek}, ${observation}`
      : observation,
    items: cartProductsWithoutRedeem.map((product, index) => ({
      sequence: index + 1,
      quantity: product.quantity,
      observation: product.observation,
      discount: 0,
      type: 0,
      product: {
        id: product.id,
        name: product.realProductName || product.digitalName || product.name,
        price: product.price,
        categoryId: product.categoryId,
        categoryName: product.categoryName,
      },
      subItems: generateSubItems(product.wizardSteps),
    })),
    orderType: isTakeAway ? 0 : 1, // 1 == delivery || 0 == takeaway
    sourceId,
    status: 20,
    paymentStatus: 0,
    payments,
    productTotal,
    total,
  };

  if (orderId) {
    output.id = orderId;
  }

  if (tableId) {
    output.tableId = tableId;
  }

  if (accountId) {
    output.accountId = accountId;
  }

  return output;
};
