import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
// import ReactGA from 'react-ga';
import ReactGAv4 from 'react-ga4';
import { getSelectedStore } from './index';

const started = {};

const eventsFb = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
];

export const trackEvent = ({ name, data }) => {
  const store = getSelectedStore();
  if (!store) {
    return;
  }
  const { gtmCode, pixelCode } = store;

  const gmv4 = store.googleAnalyticsCode;

  if (gtmCode) {
    const tagManagerArgs = {
      dataLayer: {
        ...data,
      },
      dataLayerName: name,
    };
    if (window.dataLayer) {
      window.dataLayer.push({ event: name, ...data });
    } else {
      TagManager.dataLayer(tagManagerArgs);
    }
  }
  if (gmv4 && ReactGAv4.isInitialized) {
    ReactGAv4.event({
      category: name,
      action: name,
    });
  }
  if (pixelCode) {
    const { fbq } = window;

    if (!fbq) {
      return;
    }
    if (eventsFb.includes(name)) {
      fbq('track', name, {
        ...data,
      });
    } else {
      fbq('trackCustom', name, {
        ...data,
      });
    }
  }
};

export const startTracks = (store, history) => {
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: true, // enable logs
  };
  const { pixelCode } = store;
  const { gtmCode } = store;
  const gmv4 = store.googleAnalyticsCode;
  if (gmv4 && !ReactGAv4.isInitialized && !started[gmv4]) {
    started[gmv4] = true;
    ReactGAv4.initialize(gmv4);
  }
  if (pixelCode && !started[pixelCode]) {
    started[pixelCode] = true;
    ReactPixel.init(pixelCode, advancedMatching, options);
    ReactPixel.grantConsent();
    ReactPixel.pageView();
  }
  if (gtmCode && !started[gtmCode]) {
    started[gtmCode] = true;
    const tagManagerArgs = {
      gtmId: `${gtmCode}`,
    };
    TagManager.initialize(tagManagerArgs);
  }
  history.listen(location => {
    if (gmv4) {
      ReactGAv4.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
    // ReactGA.pageview(window.location.pathname + window.location.search);
    trackEvent({
      name: 'pageChange',
      data: { newPage: location.pathname },
    });
  });
};
