/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BiCart,
  BiInfoCircle,
  BiListUl,
  BiStoreAlt,
  BiUserCircle,
} from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import { useInternalContext } from '../../Context';
import { useCartContext } from '../../contexts/CartContext';
import { createStoreEncodedName, getSelectedStore } from '../../util';
import { Badge, Container, Tab } from './style';

const pathnamesHome = {
  '/': true,
  '/home': true,
  '/delivery': true,
};
pathnamesHome['/'] = true;
pathnamesHome['/home'] = true;
pathnamesHome['/delivery'] = true;

export const TabsMobile = () => {
  const {
    config: {
      unSelectedTabColor: unSelectedTabColorConfig,
      unSelectedTabTextColor: unSelectedTabTextColorConfig,
      selectedTabColor,
      selectedTabTextColor,
    },
    config,
    user,
    isMobile,
  } = useInternalContext();
  const { cart } = useCartContext();
  const unSelectedTabColor =
    unSelectedTabColorConfig === 'trasnparent'
      ? '#ffffff'
      : unSelectedTabColorConfig;
  const unSelectedTabTextColor =
    unSelectedTabTextColorConfig === 'transparent'
      ? '#ffffff'
      : unSelectedTabTextColorConfig;

  const history = useHistory();
  const location = useLocation();
  const [activeTabUrl, setActiveUrlTab] = useState(`none`);

  const store = useMemo(() => {
    return getSelectedStore();
  }, [location.pathname]);

  const tabs = useMemo(() => {
    const array = [];
    array.push({
      title: 'Início',
      icon: 'BiStoreAlt',
      url: '/loja',
    });
    if (store) {
      array.push({
        title: 'Pedidos',
        url: '/orders',
        icon: 'BiListUl',
      });
    }
    array.push({
      title: 'Carrinho',
      url: '/cart',
      icon: 'BiCart',
    });
    if (store) {
      array.push({ title: 'Info', url: '/info', icon: 'BiInfoCircle' });
      if (config?.hasLogin === 'YES') {
        array.push({
          title: user ? 'Perfil' : 'Entrar',
          url: user ? '/me' : '/login',
          icon: 'BiUserCircle',
        });
      }
    }

    return array;
  }, [user, config, store]);

  const updateActiveTab = useCallback(
    pathname => {
      const currentTab = tabs.find(tab => pathname.includes(tab.url));
      if (!currentTab) {
        setActiveUrlTab('none');
        return;
      }

      setActiveUrlTab(currentTab.url);
    },
    [tabs]
  );

  const handleChangeTab = tabIndex => {
    const { url } = tabs[tabIndex];
    if (url === '/loja') {
      if (!store) {
        history.push('/cardapio');
        return;
      }
      history.push(`/loja/${createStoreEncodedName(store.name)}`);
      return;
    }
    history.push(url);
  };

  const getTabIcon = tab => {
    switch (tab.icon) {
      case 'BiStoreAlt':
        return (
          <BiStoreAlt
            color={
              tab.url === activeTabUrl
                ? selectedTabTextColor
                : unSelectedTabTextColor
            }
            size={20}
          />
        );

      case 'BiInfoCircle':
        return (
          <BiInfoCircle
            color={
              tab.url === activeTabUrl
                ? selectedTabTextColor
                : unSelectedTabTextColor
            }
            size={20}
          />
        );

      case 'BiUserCircle':
        return (
          <BiUserCircle
            color={
              tab.url === activeTabUrl
                ? selectedTabTextColor
                : unSelectedTabTextColor
            }
            size={20}
          />
        );

      case 'BiListUl':
        return (
          <BiListUl
            color={
              tab.url === activeTabUrl
                ? selectedTabTextColor
                : unSelectedTabTextColor
            }
            size={20}
          />
        );

      case 'BiCart':
        return (
          <BiCart
            color={
              tab.url === activeTabUrl
                ? selectedTabTextColor
                : unSelectedTabTextColor
            }
            size={20}
          />
        );

      default:
        return <div />;
    }
  };

  useEffect(() => {
    updateActiveTab(location.pathname);
  }, [updateActiveTab, location.pathname]);

  if (!isMobile || pathnamesHome[location.pathname]) {
    return <div />;
  }

  if (!store) {
    return <div />;
  }

  return (
    <Container className="tabs">
      {tabs.map((tab, index) => (
        <Tab
          style={{
            color:
              tab.url === activeTabUrl
                ? selectedTabTextColor
                : unSelectedTabTextColor,
            background:
              tab.url === activeTabUrl ? selectedTabColor : unSelectedTabColor,
          }}
          type="button"
          key={tab.url}
          isActive={tab.url === activeTabUrl}
          onClick={() => handleChangeTab(index)}
        >
          {tab.icon && getTabIcon(tab)}
          <span>{tab.title}</span>
          {tab.url === '/cart' && cart?.products?.length > 0 && (
            <Badge
              style={{
                color:
                  tab.url === activeTabUrl
                    ? unSelectedTabTextColor
                    : selectedTabTextColor,
                background:
                  tab.url === activeTabUrl
                    ? unSelectedTabColor
                    : selectedTabColor,
              }}
            >
              {cart.products.length}
            </Badge>
          )}
        </Tab>
      ))}
    </Container>
  );
};
