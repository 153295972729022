import styled from 'styled-components';
import { Row, Col } from 'reactstrap';

export const ProductDescriptionList = styled.p`
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: lighter;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`;
export const ProductName = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;
export const ProductPrice = styled.span`
  margin-top: auto;
`;

export const ImageContainer = styled.div`
  padding: 15px 0 15px 15px;
  flex-shrink: 0;
  overflow: hidden;
`;

export const ProductBox = styled(Row)`
  cursor: pointer;
  border: 0.1rem solid #f4f4f4;
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 4px;
  :hover {
    border-color: black;
  }
  @media (max-width: 600px) {
    margin-right: 8px;
    margin-left: 0px;
    margin-top: 0px;
    border-bottom: 0.1rem solid #f4f4f4;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    :hover {
      border-color: #f4f4f4;
    }
  }
`;

export const ProductGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: ${props => props.config.productCardBackgroundColor};
  color: ${props => props.config.textBaseColor};
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border: solid thin ${props => props.config.productCardBackgroundColor};
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 5px 15px black;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ProductGridName = styled.div`
  padding-top: 10px;
  padding-bottom: ${props => (props.isDefaultCatalogMode ? '10px' : '2px')};
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
`;
export const ProductGridPrice = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
`;

export const ProductGridImage = styled.div`
  height: 100%;
`;

export const ColProductGrid = styled(Col)`
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20px;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-left: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
