import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import { FaArrowAltCircleDown } from 'react-icons/fa';
import { IoStorefrontOutline } from 'react-icons/io5';

import { useInternalContext } from '../../Context';
import { getSelectedAddress, getSelectedStore, getTakeAwayMode, isMobile } from '../../util';
import { ButtonNoStyle } from '../../util/style';
import Loading from '../loading';
import { Container, Fixed, TitleDelivery, TitleTakeAway } from './styles';

const drawAddress = ({ openAddress, onDelivery, config }) => {
  if (!getSelectedStore()) return <div />;
  if (getSelectedStore().type.toLowerCase() === 'takeaway') {
    return (
      <ButtonNoStyle
        style={{
          display: 'flex',
          fontWeight: 'bold',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '90vw',
          overflow: 'hidden',
          marginBottom: 0,
          color: config.textBaseColor,
        }}
        onClick={() => {
          onDelivery();
        }}
      >
        {getSelectedStore().name}
        <FaArrowAltCircleDown
          style={{
            marginLeft: 4,
            marginTop: 4,
            color: config.textBaseColor,
          }}
        />
      </ButtonNoStyle>
    );
  }
  if (
    getSelectedStore()?.type.toLowerCase() === 'delivery' &&
    getSelectedAddress()
  ) {
    return (
      <Row
        style={{ cursor: 'pointer', maxWidth: '60vw' }}
        onClick={openAddress}
      >
        <Col xs={12} style={{ display: 'flex' }}>
          <p
            style={{
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '90vw',
              overflow: 'hidden',
              marginBottom: 0,
            }}
          >
            {`${getSelectedAddress().street}/${getSelectedAddress().number} ${
              getSelectedAddress().complement
                ? getSelectedAddress().complement
                : ''
            }`}
          </p>
          <FaArrowAltCircleDown
            style={{
              marginLeft: 8,
              marginTop: 4,
              color: config.textBaseColor,
            }}
          />
        </Col>
      </Row>
    );
  }
  return <div />;
};

const FixedAddress = ({
  hideAddress = false,
  onTakeAway,
  openAddress,
  onDelivery,
  shortHeader,
  toggleModalStoreInfo,
}) => {
  const { config, selectedStore, tableAccountMode } =
    useInternalContext();
  const [isLoading, setIsLoading] = useState(false);

  if (!config) {
    return <div />;
  }

  if (isLoading) {
    return <Loading fixed />;
  }

  if (tableAccountMode || getTakeAwayMode()) {
    return <div />;
  }

  const store = selectedStore || getSelectedStore();

  if (!store) {
    return <div />;
  }

  return (
    <Container shortHeader={shortHeader}>
      <Fixed>
        <div style={{ display: 'flex' }}>
          {config.hasDelivery === 'YES' && (
            <ButtonNoStyle type="button" onClick={onTakeAway}>
              <TitleDelivery config={config}>Entrega</TitleDelivery>
            </ButtonNoStyle>
          )}
          {config.hasTakeAway === 'YES' && (
            <ButtonNoStyle
              type="button"
              onClick={async () => {
                setIsLoading(true);
                await onDelivery();
                setIsLoading(false);
              }}
              style={{ marginLeft: 25 }}
            >
              <TitleTakeAway config={config}>Retirada</TitleTakeAway>
            </ButtonNoStyle>
          )}
        </div>
        {!hideAddress && (
          <div
            style={{
              display: 'flex',
              overflow: 'hidden',
              fontSize: 14,
              textAlign: 'left',
              color: config.textBaseColor,
            }}
          >
            {drawAddress({ openAddress, onDelivery, config })}
            {config.displayStoreInfo === 'YES' && (
              <>
                {(store?.type.toLowerCase() === 'takeaway' ||
                  (getSelectedAddress() && store?.type === 'delivery')) &&
                  !isMobile() && <span style={{ margin: '0 10px' }}>|</span>}
                {!isMobile() && store && (
                  <ButtonNoStyle
                    style={{
                      color: config.textBaseColor,
                      fontWeight: 'bold',
                    }}
                    onClick={toggleModalStoreInfo}
                  >
                    <span>
                      Ver loja{' '}
                      <IoStorefrontOutline
                        style={{
                          color: config.textBaseColor,
                          marginLeft: 4,
                        }}
                        size={18}
                      />
                    </span>
                  </ButtonNoStyle>
                )}
              </>
            )}
          </div>
        )}
      </Fixed>
    </Container>
  );
};

export default FixedAddress;
