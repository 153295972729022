import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, Row, Col, CardFooter } from 'reactstrap';
import { Button } from '../../components/button';
import { useInternalContext } from '../../Context';
import { OrderId, OrderSuccessTitle } from './style';

const Success = () => {
  const { config } = useInternalContext();
  const params = useParams();
  const history = useHistory();

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={12}>
            <OrderSuccessTitle config={config}>
              Seu pedido foi <br /> realizado com sucesso!
            </OrderSuccessTitle>
            <OrderId>Pedido #{params.id}</OrderId>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Button onClick={() => history.push('/orders')} config={config}>
          Acompanhar pedido
        </Button>
      </CardFooter>
    </Card>
  );
};

export default Success;
