import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import CartBody from '../../components/cart/body';
import CartFooter from '../../components/cart/footer';
import Loading from '../../components/loading';
import { useCartContext } from '../../contexts/CartContext';
import { fetchStore } from '../../service';
import { createStoreEncodedName, getSelectedStore } from '../../util';
import { CartMobileContainer } from '../globalStyles';

export const Cart = () => {
  const { removePromocode } = useCartContext();

  const history = useHistory();
  const storeId = getSelectedStore()?.id || getSelectedStore()?.storeId;

  const { data: storeDetail, isLoading } = useQuery({
    queryKey: ['store', storeId],
    queryFn: () => fetchStore(storeId),
    enabled: !!storeId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CartMobileContainer>
      <CartBody />
      <CartFooter
        fixed
        removePromocode={removePromocode}
        storeDetail={storeDetail}
        openStoreSelection={() => {
          const store = getSelectedStore();
          if (!store) {
            history.push('/');
            return;
          }
          history.push(`/loja/${createStoreEncodedName(store.name)}`, {
            openStoreSelection: true,
          });
          toast.warn(
            'Clique em Entrega ou Retirada para selecionar uma loja!',
            {
              position: 'top-center',
            }
          );
        }}
      />
    </CartMobileContainer>
  );
};
