import React, { useState } from 'react';
import { MdOutlineLocationSearching } from 'react-icons/md';
import { toast } from 'react-toastify';

import { captureException } from '@sentry/react';
import Loading from '../loading';
import { fetchAddressFromLatLng } from '../../service';
import { ButtonNoStyle } from '../../util/style';

const ButtonGetGeoLocation = ({ callback }) => {
  const [loading, setLoading] = useState(false);
  const [hiddenButton, setHiddenButton] = useState(false);

  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          try {
            setLoading(true);
            const { latitude, longitude } = position.coords;
            const address = await fetchAddressFromLatLng({
              latitude,
              longitude,
            });
            if (!address.zip) {
              toast.error(
                'Não encontramos seu endereço. Por favor, insira-o manualmente.'
              );
              setLoading(false);
              setHiddenButton(true);
              return;
            }
            toast.success('Confirme o endereço encontrado');
            setLoading(false);
            callback(address);
          } catch (err) {
            captureException(err);
            setHiddenButton(true);
            toast.error(
              'Não encontramos seu endereço. Por favor, insira-o manualmente.'
            );
            setLoading(false);
          }
        },
        () => {
          toast.error(
            <span>
              <b>Sem permissão para obter a sua localização</b> <br />
              Por favor, permita o acesso ou digite o endereço manualmente.
            </span>
          );
        }
      );
    } else {
      toast.error('O seu navagedor não suporta geolocalização.');
    }
  };

  if (loading) {
    return <Loading height="100%" />;
  }

  if (hiddenButton) {
    return <div />;
  }

  return (
    <ButtonNoStyle
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        border: '1px solid #ced4da',
        marginBottom: 10,
      }}
      onClick={getGeoLocation}
      disabled={loading}
    >
      <MdOutlineLocationSearching style={{ fontSize: 22, marginRight: 10 }} />
      <span style={{ letterSpacing: 1, fontWeight: 500 }}>
        Usar minha localização
      </span>
    </ButtonNoStyle>
  );
};

export { ButtonGetGeoLocation };
