import React, { useEffect, useMemo } from 'react';
import { MdOutlineExitToApp } from 'react-icons/md';

import {
  colorIsReadableOnBackgroundWhite,
  formatCPF,
  formatPhone,
} from '../../util';
import { Button } from '../button';
import { Container, Line, Close } from './styles';

const ModalUser = ({
  isOpen,
  user,
  textBaseColor,
  goToOrders,
  logoutFromHeader,
  closeModalUser,
  backgroundColor,
  cashbackConfigs,
  goToCashback,
}) => {
  const color = useMemo(
    () =>
      colorIsReadableOnBackgroundWhite(textBaseColor)
        ? textBaseColor
        : '#000000',
    [textBaseColor]
  );

  useEffect(() => {
    const handleKeyPress = event => {
      if (closeModalUser && event.key === 'Escape') {
        closeModalUser();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container isActive={isOpen} color={color}>
      <Close onClick={closeModalUser} color={backgroundColor} />
      <hr style={{ marginTop: 0 }} />
      <h1>{user.name}</h1>
      <hr />

      {user.mainPhone && (
        <Line>
          <p style={{ color }}>
            <strong>Telefone: </strong> {formatPhone(user.mainPhone)}
          </p>
        </Line>
      )}
      {user.email && (
        <Line>
          <p style={{ color }}>
            <strong>Email:</strong> {user.email}
          </p>
        </Line>
      )}
      {user.cpf && (
        <Line>
          <p style={{ color }}>
            <strong>CPF:</strong> {formatCPF(user.cpf)}
          </p>
        </Line>
      )}

      <hr />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        {cashbackConfigs?.active === 'YES' && (
          <Button
            onClick={() => {
              goToCashback();
              closeModalUser();
            }}
          >
            Cashback
          </Button>
        )}
        <Button
          onClick={() => {
            goToOrders();
            closeModalUser();
          }}
        >
          Meus pedidos
        </Button>
        {!user.isTempUser && (
          <Button
            onClick={() => {
              logoutFromHeader();
              closeModalUser();
            }}
            style={{
              width: 38,
              height: 38,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            title="Sair"
          >
            <MdOutlineExitToApp size={20} />
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ModalUser;
