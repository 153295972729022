import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  FormText,
} from 'reactstrap';
import { PatternFormat } from 'react-number-format';

import Loading from '../loading';
import { formatCep, getSelectedAddress, getUser } from '../../util';
import { fetchAddressInformation, fetchUserDetail } from '../../service';
import { Logo } from '../../views/Header/style';
import { useInternalContext } from '../../Context';
import { Button } from '../button';
import { ButtonGetGeoLocation } from '../buttonGetGeoLocation';

const AddressSelectionModal = ({
  isOpen,
  confirmAction,
  cancelAction,
  openStoreSelection,
  addressInformationValues,
  showButtonGetUserLocation = true,
}) => {
  const { config } = useInternalContext();
  const [addressInformation, setAddressInformation] = useState({
    ...addressInformationValues,
  });
  const [loading, setLoading] = useState(false);
  const [openNewAddress, setOpenNewAddress] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [showButtonGetLocation, setShowButtonGetLocation] = useState(
    showButtonGetUserLocation
  );
  const [cep, setCep] = useState(() => {
    if (addressInformationValues && addressInformationValues.zip) {
      return addressInformationValues.zip;
    }
    return '';
  });
  const [selectedAddressId] = useState();

  const loadAddressFromCep = async zipCode => {
    setLoadingCep(true);
    const addressInformationData = await fetchAddressInformation(zipCode)
      .then(prevValues => ({
        ...prevValues,
        canEditStreet: !prevValues.street || prevValues.street.trim() === '',
        street: prevValues?.street?.trim() || '',
        number: getSelectedAddress()?.number || '',
        complement: getSelectedAddress()?.complement || '',
      }))
      .catch(() => {
        return {
          error: 'Cep não encontrado',
        };
      });
    setLoadingCep(false);
    setAddressInformation({
      ...addressInformationData,
      cep: addressInformationData.zip,
    });
    return addressInformation;
  };
  const onChange = field => async value => {
    if (field === 'cep') {
      const cepData = value.replace('_', '').replace('-', '');
      setCep(cepData);
      setAddressInformation(prevValues => ({
        ...prevValues,
        cep: cepData,
        zip: cepData,
      }));
      if (cepData.length === 8) {
        setShowButtonGetLocation(false);
        await loadAddressFromCep(cepData);
      } else {
        setShowButtonGetLocation(true);
      }
    }
    if (field !== 'cep') {
      setAddressInformation(prevValues => ({ ...prevValues, [field]: value }));
    }
  };

  const newAddress = () => {
    setAddressInformation({});
    setOpenNewAddress(true);
    setCep('');
  };
  const loadData = async () => {
    const user = getUser();
    const selectedAddress = getSelectedAddress();
    if (user) {
      setLoading(true);
      const customerData = await fetchUserDetail({ token: user.token });
      setCustomer({
        ...customerData,
        addressList: Array.isArray(customerData.addressList)
          ? [...customerData.addressList, selectedAddress].filter(
              address => address
            )
          : [selectedAddress].filter(address => address),
      });
      setLoading(false);
      // if (customerData.addressList?.length === 0) {
      //   newAddress();
      // }
    }

    if (selectedAddress) {
      setCep(formatCep(selectedAddress.zip));
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={() => {}} size="lg">
      {customer && !openNewAddress && (
        <>
          <ModalHeader>
            {!loading && customer.addressList?.length > 0 ? (
              <span>Selecione um endereço.</span>
            ) : (
              <span>Nenhum endereço cadastrado.</span>
            )}
          </ModalHeader>
          <ModalBody>
            {loading && <Loading message="Carregando endereços" />}
            {!loading &&
              customer.addressList?.map((address, index) => (
                <Row key={`${address.cep}`} style={{ marginTop: 4 }}>
                  <Col xs={12}>
                    <FormGroup check style={{ textAlign: 'left' }}>
                      <Label check>
                        <Input
                          type="radio"
                          checked={selectedAddressId === index}
                          style={{ width: 20, height: 20 }}
                          onChange={() => confirmAction(address)}
                        />
                        {'  '}
                        {address.zip} - {address.street}, {address.number}{' '}
                        {address.complement}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={cancelAction}>
              Cancelar
            </Button>

            <Button onClick={() => newAddress()}>Novo Endereço</Button>
          </ModalFooter>
        </>
      )}
      {(!customer || openNewAddress) && (
        <>
          <ModalHeader
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 'calc(10px + 2vmin)',
              borderBottom: `1px solid ${config.headerBottomColor}`,
              backgroundColor: config.headerBackgroundColor,
            }}
          >
            <Logo src={config.merchantLogo} onClick={() => {}} />
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>
                    Informe seu CEP e faça o pedido na loja mais próxima de você
                  </Label>
                  <PatternFormat
                    disabled={loadingCep}
                    format="#####-###"
                    mask="_"
                    value={cep}
                    required
                    placeholder="CEP"
                    onChange={({ target: { value } }) => onChange('cep')(value)}
                    customInput={Input}
                  />
                </FormGroup>
              </Col>
              {showButtonGetLocation && (
                <Col xs={12}>
                  <ButtonGetGeoLocation
                    callback={address => {
                      setCep(address.zip);
                      setAddressInformation(address);
                      setShowButtonGetLocation(false);
                    }}
                  />
                </Col>
              )}
              {!addressInformation.zip && addressInformation.error && (
                <Col xs={12}>
                  <span style={{ color: 'red' }}>
                    {addressInformation.error}
                  </span>
                </Col>
              )}
            </Row>
            {addressInformation?.zip?.length === 8 && (
              <Row>
                {loadingCep && (
                  <Col xs={12}>
                    <Loading message="Carregando endereço.." />
                  </Col>
                )}
                {!loadingCep && (
                  <>
                    <Col lg={8} sm={12}>
                      <FormGroup>
                        <Label>Endereço</Label>
                        <Input
                          value={addressInformation.street}
                          placeholder="Digite o endereço completo com acentos"
                          required
                          onChange={({ target: { value: street } }) =>
                            onChange('street')(street)
                          }
                          disabled={!addressInformation.canEditStreet}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} sm={12}>
                      <FormGroup>
                        <Label>Número</Label>
                        <Input
                          value={addressInformation.number}
                          placeholder="Número"
                          required
                          onChange={({ target: { value: number } }) =>
                            onChange('number')(number)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={8} sm={12}>
                      <FormGroup>
                        <Label>Complemento</Label>
                        <Input
                          value={addressInformation.complement}
                          onChange={({ target: { value: complement } }) =>
                            onChange('complement')(complement)
                          }
                          placeholder="Complemento"
                        />
                      </FormGroup>
                    </Col>
                  </>
                )}
              </Row>
            )}

            {addressInformation?.zip?.length < 8 &&
              config.hasTakeAway === 'YES' && (
                <Row>
                  <Col xs={12}>
                    Caso não deseje informar o endereço, você pode selecionar
                    uma loja clicando no botão abaixo.
                  </Col>
                  <Col xs={12}>
                    <Button outline onClick={openStoreSelection}>
                      Selecionar loja
                    </Button>
                  </Col>
                </Row>
              )}
          </ModalBody>
          <ModalFooter style={{ display: 'inline-grid' }}>
            <Row>
              <Col xs={12} style={{ textAlign: 'right' }}>
                <Button
                  style={{ marginRight: 10 }}
                  outline
                  onClick={() => {
                    cancelAction();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={!addressInformation.number || loadingCep}
                  onClick={() => confirmAction(addressInformation)}
                >
                  Confirmar
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {!addressInformation.number && (
                  <FormText>
                    Você deve preencher o seu endereço antes de continuar
                  </FormText>
                )}
              </Col>
            </Row>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export
export default AddressSelectionModal;
