import React, { useEffect, useRef, useState } from 'react';
import {
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  CardHeader,
  ModalHeader,
  FormText,
} from 'reactstrap';
import { PatternFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { captureException } from '@sentry/react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { CreateForgotPasswordCard } from './style';
import {
  fetchUserDetail,
  resetAccount,
  resetVerifyAccount,
  doSendSmsAgain,
} from '../../service';
import { storeUser } from '../../util';

import { useInternalContext } from '../../Context';

const Forgot = () => {
  const [state, setState] = useState({
    loading: false,
    email: null,
    password: '',
    cpf: '',
    timeToStart: 30,
    code: '',
  });
  const interval = useRef(null);
  const history = useHistory();
  const { setUser } = useInternalContext();

  const startTimer = () => {
    if (interval.current !== null) return;
    setState(prevValue => ({ ...prevValue, timeToStart: 30 }));
    interval.current = setInterval(() => {
      const { timeToStart } = state;
      if (timeToStart - 1 >= 0)
        setState(prevValues => ({
          ...prevValues,
          timeToStart: timeToStart - 1,
        }));
      else {
        clearInterval(interval.current);
        interval.current = null;
      }
    }, 1000);
  };

  const removeInternaval = () => clearInterval(interval.current);
  useEffect(() => () => removeInternaval(), []);

  const resetPassword = async e => {
    e.preventDefault();
    try {
      setState(prevValues => ({ ...prevValues, loading: true }));
      const { phone, email } = await resetAccount({
        cpf: state.cpf.replace(/\D/g, ''),
      });
      if (!phone) {
        setState(prevValues => ({
          ...prevValues,
          codeCheck: false,
          loading: false,
          phone,
          code: '',
          password: '',
        }));
        toast.warn('Nenhum usuário localizado com este CPF.');
        return;
      }
      setState(prevValues => ({
        ...prevValues,
        codeCheck: true,
        loading: false,
        email,
        phone,
        code: '',
        password: '',
      }));
      startTimer();
    } catch (error) {
      console.error('e::', error);
      captureException(error);
      setState(prevValues => ({ ...prevValues, loading: false }));
    }
  };

  const sendAgain = async () => {
    startTimer();
    const { cpf } = state;
    await doSendSmsAgain({ cpf: cpf.replace(/\D/g, '') });
  };

  const verifyCode = async e => {
    e.preventDefault();
    const { code, cpf, password } = state;

    try {
      setState(prevValue => ({ ...prevValue, loading: true }));
      const verified = await resetVerifyAccount({ code, cpf, password });
      if (verified.error) {
        setState(prevValues => ({ ...prevValues, loading: false }));
        toast.warn(verified.error);
        return;
      }
      const completedUser = await fetchUserDetail({ token: verified.token });

      storeUser({ ...completedUser, token: verified.token });
      setUser({ ...completedUser, token: verified.token });
      history.push(``);
    } catch (error) {
      captureException(error);
      console.error('e::', error);
    }
    setState(prevValues => ({ ...prevValues, loading: false }));
  };

  const { loading, timeToStart, codeCheck, phone, email } = state;

  return (
    <div>
      <Modal size="lg" isOpen={codeCheck}>
        <form onSubmit={verifyCode}>
          <ModalHeader>Verificar Código</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <span>Código enviado para: {phone}</span>
              </Col>
              {email && email !== '' && (
                <Col xs={12}>
                  <span>Seu e-mail de login:{email}</span>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={12} lg={6} style={{ color: 'black' }}>
                <FormGroup>
                  <Label>Código:</Label>
                  <Input
                    placeholder="XXXXXXX"
                    required
                    maxLength={6}
                    type="number"
                    value={state.code}
                    onChange={({ target: { value } }) =>
                      setState(prevValues => ({ ...prevValues, code: value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6} style={{ color: 'black' }}>
                <FormGroup>
                  <Label>Nova senha:</Label>
                  <Input
                    required
                    type="password"
                    value={state.password}
                    onChange={({ target: { value } }) =>
                      setState(prevValues => ({
                        ...prevValues,
                        password: value,
                      }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col xs={12}>
                {timeToStart !== 0 && (
                  <Button
                    outline
                    color="primary"
                    type="button"
                    disabled={timeToStart !== 0}
                  >
                    Reenviar SMS em: 00:{`${timeToStart}`.padStart(2, 0)}
                  </Button>
                )}
                {timeToStart === 0 && (
                  <Button
                    outline
                    onClick={sendAgain}
                    color="primary"
                    disabled={timeToStart !== 0}
                  >
                    Reenviar SMS
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                  color="danger"
                  disabled={loading}
                  outline
                  onClick={() =>
                    setState(prevValues => ({
                      ...prevValues,
                      codeCheck: false,
                    }))
                  }
                  style={{
                    appearance: 'initial',
                    WebkitAppearance: 'initial',
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={loading}
                  style={{
                    marginLeft: 8,
                    appearance: 'initial',
                    WebkitAppearance: 'initial',
                  }}
                >
                  {loading ? 'Verificando dados..' : 'Verificar Código'}
                </Button>
                <FormText>
                  Dependendo da operadora, o SMS pode demorar um pouco para
                  chegar.
                </FormText>
              </Col>
            </Row>
          </ModalFooter>
        </form>
      </Modal>
      <form onSubmit={resetPassword}>
        <CreateForgotPasswordCard>
          <CardHeader>Resgatar Senha</CardHeader>
          <CardBody>
            <Row style={{ justifyContent: 'center' }}>
              <Col xs={6} style={{ color: 'black' }}>
                <PatternFormat
                  label="CPF"
                  format="###.###.###-##"
                  mask="_"
                  value={state.cpf}
                  required
                  onChange={({ target: { value } }) =>
                    setState(prevValues => ({ ...prevValues, cpf: value }))
                  }
                  customInput={Input}
                />
              </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <Button
                color="primary"
                type="submit"
                disabled={loading}
                style={{
                  appearance: 'initial',
                  WebkitAppearance: 'initial',
                  fontSize: 12,
                  width: '25%',
                }}
              >
                {loading ? 'Solicitando..' : 'Continuar'}
              </Button>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              outline
              style={{ fontSize: 12, border: 0 }}
              onClick={() => history.push(`/login`)}
            >
              Voltar para o login
            </Button>
          </CardFooter>
        </CreateForgotPasswordCard>
      </form>
    </div>
  );
};

export default Forgot;
