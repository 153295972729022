import React from 'react';
import { useInternalContext } from '../../Context';
import { CartFooterNormal } from './footerNormal';
import { CartFooterTableAccountMode } from './footerTableAccountMode';

const CartFooter = props => {
  const { tableAccountMode } = useInternalContext();

  if (tableAccountMode) {
    return <CartFooterTableAccountMode {...props} />;
  }

  return <CartFooterNormal {...props} />;
};

export default CartFooter;
