import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { Badge, Card, CardBody, CardFooter, Col, Label, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { MdUpdate } from 'react-icons/md';

import { OrderTab, CardOrder, CardHeaderOrder } from './style';
import { statusToText, getTempIdOrders, storeOrderReview } from '../../util';
import Loading from '../../components/loading';
import { fetchOrders, fetchTempOrders } from '../../service';
import { Button } from '../../components/button';
import { useInternalContext } from '../../Context';
import { ModalOrder } from '../../components/modalOrder';
import { StarRating } from '../../components/starRating';

const TEN_SECONDS_IN_MINISECONDS = 10 * 1000;

/** @returns {React.JSX.Element} */
const Orders = () => {
  const history = useHistory();
  const { config, user, setScore } = useInternalContext();
  const [state, setState] = useState({
    loading: true,
    detailOrder: false,
    order: {
      items: [],
    },
  });

  const { data: orders, isLoading } = useQuery({
    queryKey: 'orders',
    queryFn:
      config.hasLogin === 'YES'
        ? () =>
            user
              ? fetchOrders(user.token)
              : Promise.resolve({ pending: [], completed: [] })
        : () =>
            getTempIdOrders()
              ? fetchTempOrders(getTempIdOrders())
              : Promise.resolve({ pending: [], completed: [] }),
    refetchInterval: TEN_SECONDS_IN_MINISECONDS,
  });

  const drawOrder = order => {
    return (
      <CardOrder key={`order_${order.id}`}>
        <CardHeaderOrder>
          <div lg={2} xs={4} style={{ textAlign: 'left', fontWeight: 'bold' }}>
            #{order.id}
          </div>
          <div lg={2} xs={8} style={{ textAlign: 'left' }}>
            {moment(order.createdDate).local().format('DD/MM/YYYY HH:mm')}
          </div>
          <div lg={8} sm={4} style={{ textAlign: 'left', fontWeight: 500 }}>
            {statusToText(order.status)}
          </div>
        </CardHeaderOrder>
        <CardBody>
          <Row>
            <Col sm={7} style={{ margin: '10px 0px' }}>
              <div xs={12} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                {`O pedido ${order.status < 80 ? 'vai ser' : 'foi'} ${
                  order.orderType === 0
                    ? 'retirado na loja:'
                    : 'entregue no endereço:'
                }`}
              </div>
              <div xs={12} style={{ textAlign: 'left' }}>
                {order.orderType === 0 && (
                  <span>
                    {order.storeName}: {order.storeAddress} {order.storeNumber}{' '}
                    {order.storeComplement}
                  </span>
                )}
                {order.orderType !== 0 && (
                  <span>
                    {order.orderAddress} {order.orderNumber}{' '}
                    {order.orderComplement}
                  </span>
                )}
              </div>
              {order.scheduled === 0 && (
                <div xs={12} style={{ textAlign: 'left' }}>
                  <span>
                    Previsão:{' '}
                    {moment(order.deliveryForecastDate)
                      .local()
                      .format('DD/MM/YYYY HH:mm')}
                  </span>
                </div>
              )}
            </Col>
            {order.status >= 80 && (
              <Col sm={5} style={{ margin: '10px 0px' }}>
                <div>
                  {order.reviewScore ? (
                    <Badge color="success">Avaliado</Badge>
                  ) : (
                    <Label>Quantas estrelas o pedido merece?</Label>
                  )}
                </div>
                <StarRating
                  value={order.reviewScore}
                  onClick={rating => {
                    setScore(rating);
                    storeOrderReview(order);
                    history.push(`order/${order.id}`);
                  }}
                  readonly={order.reviewScore}
                />
              </Col>
            )}
          </Row>
        </CardBody>
        <CardFooter>
          <div xs={12} style={{ textAlign: 'center' }}>
            <Button
              outline
              style={{ width: '50%', padding: 4 }}
              onClick={() =>
                setState(prevState => ({
                  ...prevState,
                  detailOrder: true,
                  order,
                }))
              }
              config={config}
            >
              Detalhar
            </Button>
          </div>
        </CardFooter>
      </CardOrder>
    );
  };

  useEffect(() => {
    if (config) {
      if (config.hasLogin === 'YES' && !user) {
        history.push('/login');
      }
    }
  }, [config, history, user]);

  if (!config) {
    return null;
  }

  if (isLoading) {
    return <Loading message="Carregando pedidos" />;
  }

  return (
    <div
      style={{
        backgroundColor: config.headerBackgroundColor,
      }}
    >
      <ModalOrder
        isOpen={state.detailOrder}
        setIsOpen={newValue =>
          setState(prev => ({ ...prev, detailOrder: newValue }))
        }
        order={state.order}
      />
      <p style={{ color: config.textBaseColor, paddingTop: 20 }}>
        Os pedidos são atualizados em tempo real{' '}
        <MdUpdate size={20} style={{ position: 'relative', bottom: 2 }} />
      </p>
      <Tabs style={{ width: '100%' }}>
        <TabList style={{ padding: '0 5%' }}>
          <OrderTab config={config}>Pedidos atuais</OrderTab>
          <OrderTab config={config}>Pedidos antigos</OrderTab>
        </TabList>
        <TabPanel>
          <Card>
            <CardBody
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {orders.pending.length === 0 && <span>Nenhum pedido</span>}
              {orders.pending.map(orderMap => drawOrder(orderMap))}
            </CardBody>
          </Card>
        </TabPanel>
        <TabPanel>
          <Card>
            <CardBody
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {orders.completed.length === 0 && <span>Nenhum pedido</span>}
              {orders.completed.map(orderMap => drawOrder(orderMap))}
            </CardBody>
          </Card>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Orders;
