/* eslint-disable no-param-reassign */
import React from 'react';

import { FaCheck } from 'react-icons/fa';
import { useImageContext } from '../../ImageContext';
import ImageViewer from '../ImageViewer';
import { MultipleChoiseStep } from './multipleChoiseStep';
import { SingleChoiseStep } from './singleChoiseStep';
import { getStepSubTitle, isRequiredFilled } from './stepUtil';

export const AllSteps = ({
  wizardSteps,
  subtractOptional,
  sumOptional,
  updateWizardSteps,
}) => {
  const { viewerIsOpen, imageURL, closeImageViewer } = useImageContext();

  if (wizardSteps.length === 0) {
    return (
      <ImageViewer
        isOpen={viewerIsOpen}
        src={imageURL}
        onClose={closeImageViewer}
      />
    );
  }
  return wizardSteps.map((step, index) => (
    <div id={`step_${index}`} key={step.id}>
      <ImageViewer
        isOpen={viewerIsOpen}
        src={imageURL}
        onClose={closeImageViewer}
      />
      <div
        style={{
          padding: '12px 20px 10px',
          backgroundColor: '#f2f2f2',
          position: 'sticky',
          zIndex: 1,
          top: 0,
        }}
      >
        <div
          style={{
            lineHeight: '12px',
            fontSize: '.95rem',
            fontWeight: 100,
            color: '#717171',
            marginBottom: '0.5rem',
          }}
        >
          <span>
            <p
              style={{
                color: '#3f3e3e',
                fontWeight: 500,
                fontSize: '1rem',
                marginBottom: 4,
              }}
            >
              {step?.title}
            </p>
            {step?.min === 0 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: 100,
                    fontSize: '.869rem',
                    color: '#717171',
                  }}
                />
                {isRequiredFilled(step) ? <FaCheck color="#50a773" /> : <div />}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: 100,
                    fontSize: '.869rem',
                    color: '#717171',
                  }}
                >
                  {getStepSubTitle(step)}
                </span>
                {isRequiredFilled(step) ? (
                  <FaCheck color="#50a773" />
                ) : (
                  <span
                    style={{ display: 'inline-block', boxSizing: 'border-box' }}
                  >
                    <span
                      id={`required_${index}`}
                      className="shake-fade"
                      style={{
                        backgroundColor: '#717171',
                        fontSize: '.5625rem',
                        padding: '6px 6px 4px',
                        border: 'none',
                        fontWeight: 500,
                        fontFeatureSettings: 'tnum',
                        fontVariantNumeric: 'tabular-nums',
                        borderRadius: '3px',
                        color: '#f5f0eb',
                      }}
                    >
                      OBRIGATÓRIO
                    </span>
                  </span>
                )}
              </div>
            )}
          </span>
        </div>
      </div>
      {step?.options
        .sort((a, b) => a.position - b.position || a.name.localeCompare(b.name))
        .map(option => {
          const { id } = option;
          return (
            <div key={option.id} style={{ display: 'flex', flex: 1 }}>
              <div style={{ flex: 1 }}>
                <div style={{ padding: 2 }}>
                  <div style={{ flex: 1 }}>
                    {step.optionType === 'MULTIPLE' && (
                      <MultipleChoiseStep
                        option={option}
                        step={step}
                        index={index}
                        wizardSteps={wizardSteps}
                        addItem={sumOptional}
                        subtractItem={subtractOptional}
                        key={id}
                      />
                    )}
                    {step.optionType === 'SINGLE' && (
                      <SingleChoiseStep
                        option={option}
                        updateWizardSteps={updateWizardSteps}
                        index={index}
                        step={step}
                        wizardSteps={wizardSteps}
                        key={id}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <hr />
    </div>
  ));
};
