import React, { useRef, useState } from 'react';
import { FaChevronDown, FaSearch } from 'react-icons/fa';
import { Overlay } from '../../util/style';
import {
  Container,
  Category,
  Search,
  SearchBox,
  InputSearch,
  SelectCategoryToScroll,
  Dropdown,
  Option,
  CurrentCategory,
  Icon,
} from './styles';

const FixedCategoriesAndSearch = ({
  categories,
  scrollTo,
  inputSearchValue,
  currentCategory,
  shortHeader,
}) => {
  const inputSearch = useRef(null);
  const [isOpenCategories, setIsOpenCategories] = useState(false);
  const [search, setSearch] = inputSearchValue;

  function focusInputSearch() {
    inputSearch.current.focus();
  }

  function toggleCategories() {
    setIsOpenCategories(prevState => !prevState);
  }

  if (!categories || categories.length === 0) return null;

  return (
    <Container shortHeader={shortHeader}>
      <Category>
        <Overlay
          className={isOpenCategories && 'active'}
          onClick={toggleCategories}
          invisible
        />
        <SelectCategoryToScroll onClick={toggleCategories}>
          <CurrentCategory>{currentCategory?.name}</CurrentCategory>
          <Icon>
            <FaChevronDown size={17} />
          </Icon>
          <Dropdown className={isOpenCategories && 'active'}>
            {categories.map(category => (
              <Option key={category.id} onClick={() => scrollTo(category)}>
                {category.name}
              </Option>
            ))}
          </Dropdown>
        </SelectCategoryToScroll>
      </Category>
      <Search>
        <SearchBox onClick={focusInputSearch}>
          <FaSearch size={17} />
          <InputSearch
            ref={inputSearch}
            placeholder="Buscar no cardápio"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </SearchBox>
      </Search>
    </Container>
  );
};

export default FixedCategoriesAndSearch;
