import React from 'react';
import { Col, Row } from 'reactstrap';

import { croppText, getMinPrice, getSelectedStore } from '../../util';
import {
  ColProductGrid,
  ProductBox,
  ProductDescriptionList,
  ProductGridContainer,
  ProductGridImage,
  ProductGridName,
  ProductGridPrice,
  ProductName,
  ProductPrice,
} from './style';

const ProductGrid = ({
  product,
  configBase,
  showProductModal,
  isDefaultCatalogMode,
}) => {
  const selectedStore = getSelectedStore();
  return (
    <ColProductGrid xs={6} md={4} lg={4} xl={3}>
      <ProductGridContainer
        config={configBase}
        onClick={showProductModal(product)}
      >
        {product.image && (
          <ProductGridImage>
            <img
              src={`https://d26aylt6qzro7d.cloudfront.net/${
                selectedStore?.merchantId || configBase.merchantId
              }/${product.image}`}
              alt="Imagem do produto"
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: '15px 15px 0 0',
              }}
            />
          </ProductGridImage>
        )}
        <ProductGridName isDefaultCatalogMode={isDefaultCatalogMode}>
          {product.name}
        </ProductGridName>
        {!isDefaultCatalogMode && (
          <ProductGridPrice>
            {!product.price && <span>A partir de&nbsp;</span>}
            <ProductPrice>{` ${getMinPrice(product)}`}</ProductPrice>
          </ProductGridPrice>
        )}
      </ProductGridContainer>
    </ColProductGrid>
  );
};

const ProductNormal = ({
  product,
  configBase,
  showProductModal,
  isDefaultCatalogMode,
}) => {
  const selectedStore = getSelectedStore();
  return product ? (
    <Col xs={12} lg={6}>
      <ProductBox config={configBase} onClick={showProductModal(product)}>
        <Col xs={8} rowSpan={2}>
          <Row
            style={{
              textAlign: 'left',
              display: 'flex',
              height: '100%',
              paddingTop: 5,
              paddingLeft: 0,
              justifyContent: 'space-beteween',
            }}
          >
            <Col
              xs={12}
              style={{
                overflow: 'hidden',
                display: 'flex',
                alignItems: isDefaultCatalogMode ? 'center' : '',
              }}
            >
              <ProductName>{product.name}</ProductName>
            </Col>
            <Col
              xs={12}
              style={{ width: '100%', overflow: 'hidden', height: 50 }}
            >
              <ProductDescriptionList>
                {croppText(product.description)}
              </ProductDescriptionList>
            </Col>

            {!isDefaultCatalogMode && (
              <Col xs={12}>
                {!product.price && <span>A partir de </span>}
                <ProductPrice>{getMinPrice(product)}</ProductPrice>
              </Col>
            )}
          </Row>
        </Col>
        <Col
          xs={4}
          style={{
            backgroundColor: 'white',
            backgroundImage: `url(https://d26aylt6qzro7d.cloudfront.net/${
              selectedStore?.merchantId || configBase.merchantId
            }/${product.image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            borderRadius: 15,
          }}
        />
      </ProductBox>
    </Col>
  ) : null;
};

const ProductView = ({
  product,
  showProductModal,
  isDefaultCatalogMode,
  config: configBase,
}) => {
  if (configBase.displayProductsInGrid === 'YES') {
    return (
      <ProductGrid
        product={product}
        configBase={configBase}
        showProductModal={showProductModal}
        isDefaultCatalogMode={isDefaultCatalogMode}
      />
    );
  }
  return (
    <ProductNormal
      product={product}
      configBase={configBase}
      showProductModal={showProductModal}
      isDefaultCatalogMode={isDefaultCatalogMode}
    />
  );
};

export default ProductView;
