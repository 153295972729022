import styled from 'styled-components';

export const CloseIcon = styled.span`
  color: red;
  cursor: pointer;
  @media (max-width: 1000px) {
    display: inline;
    cursor: pointer;
    float: right;
  }
`;

export const HeaderStyled = styled.header`
  display: flex;
  color: white;
  height: 120px;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-bottom: 1px solid ${props => props.config?.headerBottomColor};
  background-color: ${props => props.config?.headerBackgroundColor};

  @media (max-width: 999px) {
    position: ${props => (props.isShort ? 'fixed' : '')};
    height: ${props => (props.isShort ? '70px' : '120px')};
  }
`;

export const Logo = styled.img`
  transform: scale(0.6);
  cursor: pointer;
  max-height: 150px;
  @media (max-width: 1000px) {
    transform: ${({ isStorePage, isDefaultCatalogMode, isShort }) => {
      if (isDefaultCatalogMode) {
        if (isShort) return 'scale(0.5)';
        return 'scale(1)';
      }
      return isStorePage ? 'scale(0.5)' : 'scale(0.7)';
    }};
    display: ${({ isShort, isDefaultCatalogMode }) => {
      if (isShort && isDefaultCatalogMode) {
        return 'block';
      }
      return isShort ? 'none' : 'block';
    }};
    position: relative;
    top: ${({ isStorePage, isDefaultCatalogMode }) => {
      if (isDefaultCatalogMode) {
        return 0;
      }
      return isStorePage ? '-30px' : 0;
    }};
    max-height: 100px;
  }
`;

export const Menu = styled.div`
  position: absolute;
  display: flex;
  font-size: calc(8px + 2vmin);
  padding: 0 15px;
  right: 45px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const UserContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  user-select: none;
  padding: 12px 25px;
  border-radius: 10px;
  transition: 0.2s;
  outline: none;
  border: none;
  background-color: ${props => props.backgroundColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${props => props.color};
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${props => props.color};
  border: 1px solid ${props => props.color};
`;
