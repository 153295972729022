import React, { useState } from 'react';
import { useInternalContext } from '../../Context';
import { useImageContext } from '../../ImageContext';
import { getSelectedStore, toBlr } from '../../util';

/**
 * @returns {React.JSX.Element}
 */
export const ProductBase = ({
  productName,
  productImageURL,
  productDescription,
  productPrice,
}) => {
  const [isImageVisible, setIsImageVisible] = useState(true);
  const { config } = useInternalContext();
  const { openImageViewer } = useImageContext();
  const selectedStore = getSelectedStore();

  const handleImageError = () => {
    setIsImageVisible(false);
  };

  return (
    <>
      {productImageURL && isImageVisible && (
        <div
          className="product-image"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => openImageViewer(productImageURL)}
        >
          <img
            src={`https://d26aylt6qzro7d.cloudfront.net/${
              selectedStore?.merchantId || config.merchantId
            }/${productImageURL}`}
            alt={`Imagem de compra do produto ${productName}`}
            onError={handleImageError}
          />
        </div>
      )}
      <div className="product-info">
        {productName}
        <p>{productDescription}</p>
        <span style={{ color: config.textColor, fontWeight: 'bold' }}>
          {productPrice > 0 ? `+ ${toBlr(productPrice)}` : ''}
        </span>
      </div>
    </>
  );
};
