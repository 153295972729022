import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import { useCartContext } from '../../contexts/CartContext';
import { checkPromocode } from '../../service';
import { calcTotalCart, getSelectedStore, getUser, toBlr } from '../../util';
import { trackEvent } from '../../util/track';
import { Button } from '../button';
import Loading from '../loading';

const ModalApplyPromocode = ({ open, onToggle, cart, applyPromoCode }) => {
  const [loading, setLoading] = useState(false);
  const [promocode, setPromocode] = useState('');

  const { applyPromocode, applyZeroDelivery } = useCartContext();

  const validateCupom = async e => {
    e.preventDefault();
    const store = getSelectedStore();

    const orderValue = calcTotalCart({
      cartProducts: cart.products,
      deliverFee: 0,
      format: false,
    });
    setLoading(true);
    const user = getUser();
    const result = await checkPromocode({
      code: promocode,
      orderValue,
      token: user ? user.token : null,
      storeId: store.id || store.storeId,
    });
    if (result.msg) {
      trackEvent({
        name: 'promocode',
        data: {
          promocode,
          success: false,
        },
      });
      toast.warn(result.msg);
      setLoading(false);
      return;
    }
    trackEvent({
      name: 'promocode',
      data: {
        promocode,
        success: true,
      },
    });
    applyPromoCode({
      code: result.code,
      percent: result.percentValue,
      money: result.moneyValue,
      zeroDeliveryFee: result.zeroDeliveryFee === 'YES',
      discountDescription: result.percentValue
        ? `${result.name} (${parseFloat((result.percentValue * 100).toFixed(2))
            .toString()
            .replace('.', ',')}%)`
        : `${result.name} (${toBlr(result.moneyValue)})`,
      promocodeId: result.id,
      applyPromocode,
      applyZeroDelivery,
    });
    setLoading(false);
    onToggle();
  };

  return (
    <Modal isOpen={open} size="sm" toggle={onToggle}>
      <form onSubmit={validateCupom}>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label>Insira seu código de desconto</Label>
                <Input
                  disabled={loading}
                  required
                  onChange={({ target: { value: code } }) => setPromocode(code)}
                  placeholder="Desconto"
                  value={promocode}
                />
                <FormText>Ex.: TUDO20</FormText>
              </FormGroup>
            </Col>
          </Row>
          {loading && (
            <Row>
              <Col xs={12}>
                <Loading message="Validando código" />
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} outline onClick={onToggle} color="danger">
            Cancelar
          </Button>
          <Button disabled={loading} type="submit">
            Validar código de desconto
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalApplyPromocode;
