import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactGA4 from 'react-ga4';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
import { useQueries } from 'react-query';
import { removeHash } from 'react-scrollable-anchor';
import { CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { PatternFormat } from 'react-number-format';
import { useInternalContext } from '../../Context';
import AddressSelectionModal from '../../components/addressSelection';
import { BannerSimpleView, BannerView } from '../../components/banner';
import { Button } from '../../components/button';
import CartBody from '../../components/cart/body';
import CartFooter from '../../components/cart/footer';
import { CashbackCurrent } from '../../components/cashbackCurrent';
import FixedAddress from '../../components/fixedAddress';
import FixedCategoriesAndSearch from '../../components/fixedCategoriesAndSearch';
import Loading from '../../components/loading';
import { MobileCategories } from '../../components/mobileCategories';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '../../components/modal';
import { ProductModal } from '../../components/modalProduct';
import ModalStoreInfo from '../../components/modalStoreInfo';
import PointsBase from '../../components/points';
import ProductView from '../../components/product';
import StoreSelectionModal from '../../components/storeSelection';
import { useCartContext, useCashbackContext } from '../../contexts/CartContext';
import { useCookieContext } from '../../contexts/CookieContext';
import {
  fetchCashbackConfiguration,
  fetchDeliveryInformationV2,
  fetchOpenHours,
  fetchRedeemOptions,
  fetchStore,
  fetchStores,
  getSaleCart,
  listCategories,
  retrieveCampaigns,
  retrieveSubscriptions,
} from '../../service';
import {
  createStoreEncodedName,
  debounce,
  findCategory,
  findElementPosition,
  findProductOnCategories,
  getSelectedAddress,
  getSelectedStore,
  getStorageUser,
  getTakeAwayMode,
  getUser,
  removeSelectedStore,
  setStorageUser,
  storeOpenHours,
  storeSelectedAddress,
  storeSelectedStore,
} from '../../util';
import { startTracks, trackEvent } from '../../util/track';
import Footer from '../Footer';
import {
  BodyNoScroll,
  CartDesktopContainer,
  CategoryBigName,
  CategoryContainer,
  CategorySmallName,
  ColCart,
  LoyaltyMobileContainer,
  ProductList,
} from '../globalStyles';
import { processScroll } from './util';

/** @returns {React.JSX.Element} */
const Store = () => {
  const { config, setSelectedStore, isMobile, tableAccountMode } =
    useInternalContext();
  const {
    updateCart,
    clearCart,
    removeFromCart,
    cart,
    addRedeemItem,
    removePromocode,
  } = useCartContext();
  const { cashbackConfigs, setCashbackConfigs } = useCashbackContext();

  const params = useParams();
  const history = useHistory();
  const { pathname, hash, state: stateLocation } = useLocation();
  // TODO: Evitar ter states gigantes como esse
  const [redeemOptions, setRedeemOptions] = useState([]);

  const [state, setState] = useState({
    openAddresSelectModal: false,
    shortHeader: false,
    modalStoreInfo: false,
    addressInformation: {},
    selectedCategoryId: null,
    selectStoreModal: false,
    addressSelectModal: false,
    newAddressPopup: false,
    loading: false,
    inputSearchValue: '',
    selectTab: 0,
    productDetail: false,
    simple: false,
    cartResumeShow: true,
    loadingModal: false,
    wizardSteps: [],
    wizardIndex: 0,
    product: {},
    categoryToFilterRedeem: {
      label: 'Todas',
      value: null,
    },
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const [isLoadingCart, setIsLoadingCart] = useState(false);

  const [currentCategory, setCurrentCategory] = useState(undefined);
  const [modalGetNameUser, setModalGetNameUser] = useState(false);
  const [productToShow, setProductToShow] = useState(null);
  const [modalCustomerInfoCookie, setModalCustomerInfoCookie] = useState(false);
  const [nameUserQRCode, setNameUserQRCode] = useState('');
  const [customerNameTemp, setCustomerNameTemp] = useState('');
  const [customerPhoneTemp, setCustomerPhoneTemp] = useState('');

  const { setCustomerCookie, customerCookie } = useCookieContext();

  const handleScroll = () => {
    const banners = document.querySelector('.banners')
      ? document.querySelector('.banners').clientHeight
      : null;
    const filteredData = processScroll()
      .filter(category => {
        let calculatedOffset = category.isParentCategory ? -240 : -205;
        if (banners) {
          calculatedOffset -= banners - 60;
        }
        if (isMobile) {
          calculatedOffset += 20;
        }
        return category.offset + category.scrollHeight > calculatedOffset;
      })
      .sort((a, b) => a.offset - b.offset);
    setState(prevValues => ({
      ...prevValues,
      shortHeader: window.scrollY >= 120,
    }));
    setCurrentCategory(prevCategory => {
      if (prevCategory?.id !== filteredData[0]?.id) {
        return filteredData[0];
      }
      return prevCategory;
    });
  };

  const editProductModal = productData => {
    const { items: subItems, wizardSteps } = productData;
    setState(prevValues => ({
      ...prevValues,
      wizardSteps,
      wizardIndex: 0,
      product: productData,
      simple: subItems.length === 0,
      productDetail: true,
    }));
  };
  const storeId = getSelectedStore()?.id || getSelectedStore()?.storeId;
  const user = getUser();

  const [
    { data: campaigns, isLoading: isLoadingCampaigns },
    { isLoading: isLoadingSubscriptions },
    { data: storeDetail, isLoading: isLoadingStoreDetail },
    { data: openHours, isLoading: isLoadingOpenHours },
    { data: redeem, isLoading: isLoadingRedeem },
    { data: dataCategories, isLoading: isLoadingCategories },
    { data: stores, isLoading: isLoadingStores },
    { isLoading: isLoadingCashback },
  ] = useQueries([
    {
      queryKey: ['campaigns', storeId],
      queryFn: () => retrieveCampaigns(storeId, config.id),
      enabled: !!storeId || pathname.includes('cardapio'),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['subscriptions', storeId],
      queryFn: () => retrieveSubscriptions(storeId),
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['store', storeId],
      queryFn: () => fetchStore(storeId, config.hoursAfterShceduled),
      enabled: !!storeId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['openhours', storeId],
      queryFn: () =>
        fetchOpenHours({
          hasLogin: config.hasLogin,
          storeId,
          token: null,
          user,
        }).then(hours => {
          storeOpenHours(storeId, hours);
          return hours;
        }),
      enabled: !!storeId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['redeem', storeId],
      queryFn: () => fetchRedeemOptions({ token: user?.token, storeId }),
      enabled: !!storeId && !!(user && config.hasLogin === 'YES'),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['categories', storeId],
      queryFn: () =>
        listCategories({
          storeId,
          isTakeAwayMode: getTakeAwayMode(),
          tableAccountMode,
        }),
      onSuccess: data => {
        if (cart.products && cart.products.length > 0) {
          cart.products
            .filter(product => !product.redeemId)
            .forEach(product => {
              const productFinded = findProductOnCategories(product.id, data);
              if (
                !productFinded ||
                productFinded.priceDelivery !== product.priceDelivery
              ) {
                removeFromCart(product.cartId);
              }
            });
        }
      },
    },
    {
      queryKey: 'stores',
      queryFn: () => fetchStores(config.id),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'cashback',
      enabled: !getTakeAwayMode() && !tableAccountMode,
      queryFn: () => fetchCashbackConfiguration({ token: user?.token }),
      onSuccess: data => setCashbackConfigs(data),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  ]);
  const isDefaultCatalogMode = !getSelectedStore();

  useEffect(() => {
    if (redeem && redeem.options) {
      setRedeemOptions(redeem.options);
    }
  }, [redeem]);

  const categories = useMemo(() => {
    if (!dataCategories) return [];
    return dataCategories
      .filter(item => {
        return item.items.length > 0 || item.categories.length > 0;
      })
      .filter(item => {
        return item.items.length > 0 || item.categories.length > 0;
      })
      .map(item => ({
        ...item,
        isParentCategory: item.categories.length > 0,
      }));
  }, [dataCategories]);

  const isLoading =
    isLoadingCampaigns ||
    isLoadingSubscriptions ||
    isLoadingStoreDetail ||
    isLoadingOpenHours ||
    isLoadingRedeem ||
    isLoadingCategories ||
    isLoadingStores ||
    isLoadingCart ||
    isLoadingCashback;

  const scrollTo = useCallback(
    category => {
      trackEvent({
        name: 'scrollingTo',
        data: { productId: category.id, user: getUser() },
      });

      if (isScrolling) return;

      setIsScrolling(true);

      if (!category) return;

      if (getSelectedStore()) {
        const url = `/loja/${createStoreEncodedName(
          getSelectedStore().name
        )}/${createStoreEncodedName(category.name)}`;
        history.replace(url, null);
      }

      // if (store.googleAnalyticsCode) {
      //   ReactGA4.send({
      //     hitType: 'pageview',
      //     page: `${pathname}/${createStoreEncodedName(category.name)}`,
      //   });
      // }

      const baseElement = document.querySelector('html');
      if (!baseElement || !window.document.getElementById(category.id)) return;
      let calculatedOffset = category.isParentCategory ? 165 : 135;

      if (isMobile) {
        calculatedOffset += -35;
      }
      window.scroll(
        0,
        findElementPosition(document.getElementById(category.id)) -
          calculatedOffset
      );
      setIsScrolling(false);
    },
    [history, isMobile, isScrolling]
  );

  const showProductModal = useCallback(
    productData => () => {
      trackEvent({
        name: 'openProductDetail',
        data: {
          productId: productData.id,
          productName: productData.name,
          storeOpen: !!storeDetail?.open,
          user: getUser(),
        },
      });
      const { items: subItems, wizardSteps: wizardStepsProduct = null } =
        productData;

      const wizardSteps =
        wizardStepsProduct || subItems.length !== 0
          ? subItems
              .filter(subItem => subItem.items.length !== 0)
              .map(item => {
                return {
                  formData: {},
                  id: item.id,
                  required: item.min !== 0,
                  min: item.min,
                  max: item.max,
                  type: item.type,
                  optionType: item.max === 1 ? 'SINGLE' : 'MULTIPLE',
                  title: item.name,
                  description: item.description,
                  options: [
                    ...item.items.map(option => ({
                      ...option,
                      quantity: option.quantity || 0,
                    })),
                  ],
                };
              })
          : [];
      setState(prevValues => ({
        ...prevValues,
        wizardSteps,
        wizardIndex: 0,
        product: { ...productData, quantity: 1 },
        simple: subItems.length === 0,
        productDetail: true,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeDetail?.open]
  );

  const scrollToUrlParam = useCallback(
    categoriesData => {
      for (let i = 0; i < categoriesData.length; i += 1) {
        const category = categoriesData[i];
        if (createStoreEncodedName(category.name) === params.sku) {
          if (category) {
            setTimeout(() => scrollTo(category), 500);
          }
        }
        if (category.items) {
          const foundedProduct = category.items.find(item => {
            return (
              `${item.sku}` === `${params.sku}` ||
              item.name.split(' ').join('-').split('/').join('+') === params.sku
            );
          });
          if (foundedProduct) {
            showProductModal(foundedProduct)();
            return;
          }
        }
        if (category.categories) {
          category.categories.forEach(categoryMap => {
            if (createStoreEncodedName(categoryMap.name) === params.sku) {
              if (categoryMap) {
                setTimeout(() => scrollTo(categoryMap), 500);
              }
            }
            const foundedProduct = categoryMap.items.find(item => {
              return (
                `${item.sku}` === `${params.sku}` ||
                item.name.split(' ').join('-').split('/').join('+') ===
                  params.sku
              );
            });
            if (foundedProduct) {
              showProductModal(foundedProduct)();
            }
          });
        }
      }
    },
    [params.sku, scrollTo, showProductModal]
  );

  useEffect(() => {
    if (categories && params.sku) {
      scrollToUrlParam(categories);
    }
    if (categories && !currentCategory) {
      setCurrentCategory(categories[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const fetchInitialData = useCallback(async () => {
    if (params.id) {
      editProductModal(
        cart.products.find(
          ({ id: productId }) =>
            parseInt(productId, 10) === parseInt(params.id, 10)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const onTakeAway = async () => {
    if (stores.length === 1 && getSelectedAddress()) {
      const deliveryStores = await fetchDeliveryInformationV2({
        zip: getSelectedAddress() ? getSelectedAddress().zip : null,
        number: getSelectedAddress() ? getSelectedAddress().number : null,
        street: getSelectedAddress() ? getSelectedAddress().street : null,
        merchantId: config.merchantId,
        hoursAfterShceduled: config?.hoursAfterShceduled || 0,
        digitalMerchantId: config.id,
      });
      if (deliveryStores.length === 0) {
        setState(prevValues => ({
          ...prevValues,
          selectStoreModal: true,
          selectTab: 0,
        }));
        return;
      }
      startTracks(stores[0], history);
      setSelectedStore({
        ...stores[0],
        deliveryFee: deliveryStores[0].deliveryFee,
        type: 'delivery',
      });
      fetchInitialData();
    } else {
      setState(prevValues => ({
        ...prevValues,
        selectStoreModal: true,
        selectTab: 0,
      }));
    }
  };

  const onDelivery = () => {
    if (stores.length === 1) {
      startTracks(stores[0], history);
      setSelectedStore({ ...stores[0], type: 'takeaway' });
      fetchInitialData();
    } else {
      setState(prevValues => ({
        ...prevValues,
        selectStoreModal: true,
        selectTab: 1,
      }));
    }
  };

  const loadCatalogByStore = async () => {
    if (hash) {
      setState(prevValues => ({
        ...prevValues,
        selectedCategoryId: parseInt(hash.replace('#', ''), 10),
      }));

      removeHash();
    } else {
      setState(prevValues => ({
        ...prevValues,
        selectedCategoryId: null,
      }));
    }
  };

  const confirmStoreSelection = async store => {
    startTracks(store, history);
    console.log('# replace: ', `/loja/${createStoreEncodedName(store.name)}`);
    history.replace(`/loja/${createStoreEncodedName(store.name)}`);
    if (store.googleAnalyticsCode) {
      ReactGA4.send({
        hitType: 'pageview',
        page: `/loja/${createStoreEncodedName(store.name)}`,
      });
    }

    if (store.id !== (getSelectedStore() || {})?.id) {
      clearCart();
    }
    storeSelectedStore(store);

    setState(prevValues => ({
      ...prevValues,
      addressSelectModal: false,
      selectStoreModal: false,
    }));

    await loadCatalogByStore();
  };

  const openStoreSelection = () =>
    setState(prevValues => ({
      ...prevValues,
      selectStoreModal: true,
      selectTab: 1,
      addressSelectModal: false,
    }));

  useEffect(() => {
    if (stores && stores.length > 0) {
      const analyticsUnicode = new Map();
      const gtm = new Map();
      const pixel = new Map();
      stores.forEach(store => {
        analyticsUnicode.set(store.googleAnalyticsCode, true);
        gtm.set(store.gtmCode, true);
        pixel.set(store.pixelCode, true);
      });
      if (
        Array.from(analyticsUnicode.keys()).length <= 1 &&
        Array.from(gtm.keys()).length <= 1 &&
        Array.from(pixel.keys()).length <= 1
      ) {
        startTracks(stores[0], history);
      }
    }
  }, [history, stores]);

  useEffect(() => {
    const promo = new URLSearchParams(window.location.search).get('promo');
    if (promo) {
      const [salesTrackId, mainPhone, promocodeId] =
        decodeURI(promo).split('|');
      setIsLoadingCart(true);
      getSaleCart({
        salesTrackId,
        mainPhone,
        promocodeId,
      })
        .then(data => {
          const { cart: dataCard } = data;
          if (dataCard?.discount) {
            updateCart(dataCard);
            storeSelectedStore(dataCard.selectedStore);
            storeSelectedAddress(dataCard.selectedAddress);
          }
          console.log('cart:', dataCard);
          setIsLoadingCart(false);
        })
        .catch(e => {
          console.error(e);
          setIsLoadingCart(false);
        });
    } else {
      if (!getSelectedStore() && !pathname.includes('cardapio')) {
        history.push('/');
        return;
      }
      if (pathname.includes('cardapio')) {
        if (getSelectedStore()) {
          removeSelectedStore();
        }
        const { hostname } = window.location;
        if (hostname.indexOf('obarao.tem.delivery') !== -1) {
          const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
          const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: true, // enable logs
          };
          import('react-facebook-pixel')
            .then(module => module.default)
            .then(ReactPixel => {
              ReactPixel.init('4170217696424263', advancedMatching, options);
              ReactPixel.grantConsent();
              ReactPixel.pageView();
            });

          const tagManagerArgs = {
            gtmId: 'GTM-PNVP75SN',
          };
          TagManager.initialize(tagManagerArgs);

          ReactGA4.initialize('UA-205141931-1');
          ReactGA4.send({
            hitType: 'pageview',
            page: window.location.pathname,
          });
        }
      }
      if (tableAccountMode && !getStorageUser()) {
        setModalGetNameUser(true);
      }
      console.log('# stateLocation: ', stateLocation);
      // if (stateLocation?.openStoreSelection) {
      //   openStoreSelection();
      // }
      handleScroll();
      fetchInitialData();
    }
    window.addEventListener('scroll', debounce(handleScroll, 50));
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', debounce(handleScroll, 50));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideCategories = useMemo(
    () => config && config.hideNotSelectedCategories === 'YES',
    [config]
  );

  /**
   *
   * @param {object} addressInformation
   */
  const confirmAddressSelection = async addressInformation => {
    storeSelectedAddress(addressInformation);
    setState(prevValues => ({
      ...prevValues,
      addressSelectModal: false,
      selectStoreModal: true,
    }));
  };

  /**
   * @param {object} categoryToFilterRedeemData
   * @returns {Promise<void>}
   */
  const categoryOnChange = async categoryToFilterRedeemData => {
    setState(prevValues => ({ ...prevValues, loadingModal: true }));

    /**
     * @param {object} category
     * @returns {Array}
     */
    const getFilteredData = category => {
      if (category.value === 'only-discount') {
        return redeem.options.filter(option => option.discountValue);
      }
      if (category.value === null) {
        return redeem.options;
      }
      return redeem.options.filter(
        option => option.categoryId === category.value
      );
    };

    const redeemOptionsFilteredData = getFilteredData(
      categoryToFilterRedeemData
    );
    setRedeemOptions(redeemOptionsFilteredData);

    setState(prevValues => ({
      ...prevValues,
      loadingModal: false,
      setCategoryToFilterRedeem: categoryToFilterRedeemData,
      categoryToFilterRedeem: categoryToFilterRedeemData,
    }));
  };

  const simpleBannersElement = useMemo(
    () =>
      campaigns &&
      campaigns.map(campaign => (
        <BannerSimpleView
          key={`campaing_${campaign.id}`}
          bannerIndex={campaign.id}
          onClick={image => {
            if (campaign.imagesFull?.length > 1) {
              const dataImage = campaign.imagesFull.find(
                item => item.image === image
              );
              if (dataImage.productId) {
                const productOnCategory = findProductOnCategories(
                  dataImage.productId,
                  categories
                );
                if (productOnCategory) {
                  showProductModal(productOnCategory)();
                  return;
                }
              }
              if (dataImage.categoryId && !dataImage.productId) {
                const { category, parentCategoryId } = findCategory(
                  dataImage.categoryId,
                  categories
                );

                if (!category) return;

                if (parentCategoryId) {
                  setState(prevValues => ({
                    ...prevValues,
                    selectedCategoryId: parentCategoryId,
                  }));
                  scrollTo(category);
                } else {
                  setState(prevValues => ({
                    ...prevValues,
                    selectedCategoryId: category.id,
                  }));
                  scrollTo(category);
                }
              }
              if (dataImage.urlExternal) {
                window.open(dataImage.urlExternal, '_blank');
              }
            }
            if (campaign.imagesFull?.length === 1) {
              const dataImage = campaign.imagesFull[0];
              if (dataImage.categoryId) {
                const { category, parentCategoryId } = findCategory(
                  dataImage.categoryId,
                  categories
                );

                if (!category) return;

                if (parentCategoryId) {
                  setState(prevValues => ({
                    ...prevValues,
                    selectedCategoryId: parentCategoryId,
                  }));
                  scrollTo(category);
                } else {
                  setState(prevValues => ({
                    ...prevValues,
                    selectedCategoryId: category.id,
                  }));
                  scrollTo(category);
                }
              }
              if (dataImage.productId) {
                const productOnCategory = findProductOnCategories(
                  dataImage.productId,
                  categories
                );
                if (productOnCategory) showProductModal(productOnCategory)();
              }
              if (dataImage.urlExternal) {
                window.open(dataImage.urlExternal, '_blank');
              }
            }
            if (!campaign.imagesFull) {
              if (campaign.categoryId) {
                const { category, parentCategoryId } = findCategory(
                  campaign.categoryId,
                  categories
                );

                if (!category) return;

                if (parentCategoryId) {
                  setState(prevValues => ({
                    ...prevValues,
                    selectedCategoryId: parentCategoryId,
                  }));
                  scrollTo(category);
                } else {
                  setState(prevValues => ({
                    ...prevValues,
                    selectedCategoryId: category.id,
                  }));
                  scrollTo(category);
                }
              }
              if (campaign.productId) {
                const productOnCategory = findProductOnCategories(
                  campaign.productId,
                  categories
                );
                if (productOnCategory) showProductModal(productOnCategory)();
              }
              if (campaign.urlExternal) {
                window.open(campaign.urlExternal, '_blank');
              }
            }
          }}
          campaign={campaign}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaigns, categories]
  );

  const bannersElement = useMemo(
    () =>
      categories
        .filter(
          item => item.categories && item.categories.length > 0 && item.image
        )
        .map(parent => (
          <BannerView
            configColor={config}
            key={`categoryIndex_parent_${parent.id}`}
            category={parent}
            onClick={() => {
              const { category, parentCategoryId } = findCategory(
                parent.id,
                categories
              );

              if (!category) return;

              if (parentCategoryId) {
                setState(prevValues => ({
                  ...prevValues,
                  selectedCategoryId: parentCategoryId,
                }));

                scrollTo(category);
              } else {
                setState(prevValues => ({
                  ...prevValues,
                  selectedCategoryId: category.id,
                }));

                scrollTo(category);
              }
            }}
          />
        )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, categories]
  );

  const showProductUrl = productData => () => {
    console.log('# customerCookie: ', customerCookie);
    if (
      config.saveCustomerInfoInCookie === 'YES' &&
      (!customerCookie.name || !customerCookie.phone)
    ) {
      setProductToShow(productData);
      console.log('# productData: ', productData);
      setModalCustomerInfoCookie(true);
      return;
    }

    trackEvent({
      name: 'openProductDetail',
      data: {
        productId: productData.id,
        productName: productData.name,
        user: getUser(),
      },
    });

    if (isDefaultCatalogMode) {
      setState(prevValues => ({
        ...prevValues,
        selectStoreModal: true,
      }));
      return;
    }

    const url = `/loja/${createStoreEncodedName(
      getSelectedStore().name
    )}/${productData.name.split(' ').join('-').split('/').join('+')}`;

    window.history.replaceState(null, null, url);

    if (getSelectedStore()?.googleAnalyticsCode) {
      ReactGA4.send({
        hitType: 'pageview',
        page: url,
      });
    }
    // }
    // if (process.env.REACT_APP_PIXEL) {

    showProductModal(productData)();
  };

  const setInputSearchValue = value =>
    setState(prevValues => ({ ...prevValues, inputSearchValue: value }));

  /**
   * @returns {void}
   */
  const onAddProductCallback = () => {
    setState(prevState => ({
      ...prevState,
      selectedCategoryId: null,
      wizardIndex: 0,
      product: {},
      cartResumeShow: false,
      wizardSteps: [],
      simple: false,
      productDetail: false,
    }));
  };

  if (!config || isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Modal isOpen={modalGetNameUser}>
        <ModalHeader>Qual é seu nome?</ModalHeader>
        <ModalBody>
          <Input
            placeholder="Nome"
            value={nameUserQRCode}
            onChange={({ target: { value } }) => setNameUserQRCode(value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setStorageUser({ name: nameUserQRCode });
              setModalGetNameUser(false);
            }}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalCustomerInfoCookie} toggle={() => {}}>
        <ModalHeader>Cadastro</ModalHeader>
        <ModalBody>
          <FormGroup
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '1rem',
              marginTop: '0.5rem',
              marginRight: '1rem',
            }}
          >
            <Label for="name">Nome</Label>
            <Input
              id="name"
              placeholder="Digite seu nome"
              value={customerNameTemp}
              onChange={({ target: { value } }) => setCustomerNameTemp(value)}
            />
          </FormGroup>
          <FormGroup
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '1rem',
              marginTop: '-1rem',
              marginRight: '1rem',
            }}
          >
            <Label for="phone" className="mt-3">
              Telefone
            </Label>
            <PatternFormat
              format="(##) #####-####"
              mask="_"
              value={customerPhoneTemp}
              name="mainPhone"
              onChange={({ target: { value } }) => setCustomerPhoneTemp(value)}
              customInput={Input}
              placeholder="Digite seu telefone"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setCustomerCookie({
                name: customerNameTemp,
                phone: customerPhoneTemp,
              });
              showProductModal(productToShow)();
              const url = `/loja/${createStoreEncodedName(
                getSelectedStore().name
              )}/${productToShow.name
                .split(' ')
                .join('-')
                .split('/')
                .join('+')}`;

              window.history.replaceState(null, null, url);

              setModalCustomerInfoCookie(false);
            }}
            disabled={!customerNameTemp || !customerPhoneTemp}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>

      {storeId && (
        <ModalStoreInfo
          isOpen={state.modalStoreInfo}
          toggle={() =>
            setState(prevState => ({
              ...prevState,
              modalStoreInfo: !prevState.modalStoreInfo,
            }))
          }
          mainColor={config.textCategoryColor}
          store={storeDetail}
          openHours={openHours}
        />
      )}
      <FixedAddress
        hideAddress={false}
        openAddress={() => {
          setState(prevValues => ({
            ...prevValues,
            addressSelectModal: true,
          }));
        }}
        onTakeAway={onTakeAway}
        onDelivery={onDelivery}
        shortHeader={state.shortHeader}
        modalStoreInfo={state.modalStoreInfo}
        toggleModalStoreInfo={() =>
          setState(prevState => ({
            ...prevState,
            modalStoreInfo: !prevState.modalStoreInfo,
          }))
        }
        StoreInfo={() =>
          setState(prevValues => ({
            ...prevValues,
            modalStoreInfo: !prevValues.modalStoreInfo,
          }))
        }
      />

      {(!isMobile ||
        (config.mobileCategoryListingHorizontally === 'NO' && isMobile)) && (
        <FixedCategoriesAndSearch
          categories={categories.filter(
            category =>
              (!hideCategories || category.id === state.selectedCategoryId) &&
              ((category.categories &&
                category.categories.filter(
                  ({ items }) => items && items.length > 0
                ).length > 0) ||
                category.items.length > 0)
          )}
          scrollTo={scrollTo}
          inputSearchValue={[state.inputSearchValue, setInputSearchValue]}
          currentCategory={currentCategory}
          shortHeader={state.shortHeader}
        />
      )}

      {config.mobileCategoryListingHorizontally === 'YES' && isMobile && (
        <MobileCategories
          shortHeader={state.shortHeader}
          categories={categories.filter(
            category =>
              (!hideCategories || category.id === state.selectedCategoryId) &&
              ((category.categories &&
                category.categories.filter(
                  ({ items }) => items && items.length > 0
                ).length > 0) ||
                category.items.length > 0)
          )}
          currentCategory={currentCategory}
          scrollTo={scrollTo}
          isScrolling={isScrolling}
        />
      )}
      {state.selectStoreModal && (
        <StoreSelectionModal
          changeAddress={() => {
            setState(prevValues => ({
              ...prevValues,
              selectStoreModal: false,
              addressSelectModal: true,
            }));
          }}
          cancelAction={() =>
            setState(prevValues => ({
              ...prevValues,
              selectStoreModal: false,
            }))
          }
          selectTab={state.selectTab}
          isOpen={state.selectStoreModal}
          confirmAction={confirmStoreSelection}
        />
      )}
      {state.addressSelectModal && (
        <AddressSelectionModal
          openNewAddress={() => {
            setState(prevValues => ({
              ...prevValues,
              addressSelectModal: true,
            }));
          }}
          cancelAction={() => {
            setState(prevValues => ({
              ...prevValues,
              addressInformation: { zip: '' },
              addressSelectModal: false,
            }));
          }}
          addressInformationValues={{
            ...state.addressInformation,
            cep: state.addressInformation.zip,
          }}
          isOpen={state.addressSelectModal}
          openStoreSelection={openStoreSelection}
          confirmAction={confirmAddressSelection}
          stores={stores}
        />
      )}
      <div>
        <ProductModal
          state={state}
          storeDetail={storeDetail}
          onAddProductCallback={onAddProductCallback}
          // TODO : REMOVER esse setState, adicionar functions de callback especificas para cada coisa
          setState={setState}
        />
      </div>
      {isMobile && storeDetail?.open !== undefined && !storeDetail?.open && (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            zIndex: 20,
            top: ' calc(100% - 120px)',
            height: 50,
            alignItems: 'center',
            justifyContent: 'center',
            width: ' 100%',
            color: config.selectedTabTextColor,
            background: config.selectedTabColor,
            boxShadow: '4px 4px 24px rgb(0 0 0 / 20%)',
          }}
        >
          <h2 style={{ fontSize: 20, margin: 'auto' }}>
            Loja fechada no momento
          </h2>
        </div>
      )}

      {isLoading && (
        <Row>
          <Col xs={12} style={{ height: '100%', marginTop: 30 }}>
            <Loading
              message="Carregando cardápio"
              messageColor={config.textBaseColor}
            />
          </Col>
        </Row>
      )}
      <div>
        {redeem &&
          !redeem.message &&
          (!cashbackConfigs.active || cashbackConfigs.active === 'NO') && (
            <LoyaltyMobileContainer colors={config}>
              <PointsBase
                cart={cart}
                redeem={redeem}
                addRedeemItem={addRedeemItem}
                categoryToFilterRedeem={state.categoryToFilterRedeem}
                categoryOnChange={categoryOnChange}
                token={getUser() && getUser().token}
                loadingModal={state.loadingModal}
                redeemOptionsFiltered={redeemOptions}
              />
            </LoyaltyMobileContainer>
          )}
        {cashbackConfigs?.active === 'YES' && (
          <LoyaltyMobileContainer colors={config}>
            <CashbackCurrent
              cart={cart}
              redeem={redeem}
              addRedeemItem={addRedeemItem}
              categoryToFilterRedeem={state.categoryToFilterRedeem}
              categoryOnChange={categoryOnChange}
              token={getUser() && getUser().token}
              loadingModal={state.loadingModal}
              redeemOptionsFiltered={redeemOptions}
            />
          </LoyaltyMobileContainer>
        )}
        <Row style={{ marginLeft: 5, marginRight: 5 }}>
          <Col
            id="categoryContainer"
            lg={8}
            xs={12}
            style={{
              padding: 5,
            }}
          >
            {!isLoading && (
              <Row>
                <Col lg={12} xs={12}>
                  {!isLoading && (
                    <div style={{ marginTop: 50 }} className="banners">
                      <Row>{simpleBannersElement}</Row>
                      <Row>{bannersElement}</Row>
                    </div>
                  )}
                </Col>
                <ProductList xs={12}>
                  {categories
                    .filter(category => {
                      let isEmptyCategory = [];
                      if (category.isParentCategory) {
                        category.categories.forEach(cat => {
                          const products = cat.items.filter(productFilter => {
                            if (!state.inputSearchValue) return true;
                            return productFilter.name
                              .toLowerCase()
                              .includes(state.inputSearchValue.toLowerCase());
                          });
                          isEmptyCategory = [...isEmptyCategory, ...products];
                        });
                      } else {
                        const products = category.items.filter(
                          productFilter => {
                            if (!state.inputSearchValue) return true;
                            return productFilter.name
                              .toLowerCase()
                              .includes(state.inputSearchValue.toLowerCase());
                          }
                        );
                        isEmptyCategory = products;
                      }
                      return (
                        (!hideCategories ||
                          category.id === state.selectedCategoryId) &&
                        isEmptyCategory.length !== 0
                      );
                    })
                    .map(category => {
                      return (
                        <Row
                          key={`${category.id}__parent`}
                          id={`${category.id}`}
                          className={`category ${
                            category.isParentCategory ? 'parent_category' : ''
                          }`}
                        >
                          <CategoryContainer>
                            {category.categories &&
                              category.categories.length > 0 && (
                                <section id={`${category.id}`}>
                                  <CategoryBigName
                                    className="category_name"
                                    config={config}
                                    style={{ marginTop: 20 }}
                                  >
                                    {category.name}
                                  </CategoryBigName>
                                </section>
                              )}
                            {(!category.categories ||
                              category.categories.length === 0) && (
                              <section>
                                <CategorySmallName
                                  className="category_name"
                                  config={config}
                                >
                                  {category.name}
                                </CategorySmallName>
                              </section>
                            )}

                            {category.categories &&
                              category.categories
                                .filter(
                                  ({ items }) => items && items.length > 0
                                )
                                .map(subCategory => {
                                  return (
                                    <Fragment
                                      key={`sub_category_key_${subCategory.id}`}
                                    >
                                      <Row
                                        key={subCategory.id}
                                        style={{
                                          justifyContent: 'center',
                                          textAlign: 'center',
                                          display: 'flex',
                                          alignItems: 'baseline',
                                        }}
                                      >
                                        <Col xs={12}>
                                          <div>
                                            <CategorySmallName
                                              id={subCategory.id}
                                              config={config}
                                              style={{
                                                marginTop: 15,
                                                marginBottom: 0,
                                                fontSize: 16,
                                                fontWeight: 500,
                                              }}
                                            >
                                              {subCategory.name || '????'}
                                            </CategorySmallName>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row
                                        style={{
                                          marginLeft: 0,
                                          marginRight: 0,
                                        }}
                                      >
                                        {subCategory.items &&
                                          subCategory.items
                                            .filter(productFilter => {
                                              if (!state.inputSearchValue)
                                                return true;
                                              return productFilter.name
                                                .toLowerCase()
                                                .includes(
                                                  state.inputSearchValue.toLowerCase()
                                                );
                                            })
                                            .map(productMap => (
                                              <ProductView
                                                key={`extra_unique_key_${productMap.id}`}
                                                product={{
                                                  ...productMap,
                                                  categoryId: subCategory.id,
                                                  categoryName:
                                                    subCategory.name,
                                                }}
                                                showProductModal={
                                                  showProductUrl
                                                }
                                                isDefaultCatalogMode={
                                                  isDefaultCatalogMode
                                                }
                                                config={config}
                                              />
                                            ))}
                                      </Row>
                                    </Fragment>
                                  );
                                })}
                            <Row style={{ marginLeft: 0, marginRight: 0 }}>
                              {category.items &&
                                category.items
                                  .filter(productFilter => {
                                    if (!state.inputSearchValue) return true;
                                    return productFilter.name
                                      .toLowerCase()
                                      .includes(
                                        state.inputSearchValue.toLowerCase()
                                      );
                                  })
                                  .map(productMap => (
                                    <ProductView
                                      key={`extra_unique_key_${productMap.id}`}
                                      product={{
                                        ...productMap,
                                        categoryId: category.id,
                                        categoryName: category.name,
                                      }}
                                      showProductModal={showProductUrl}
                                      config={config}
                                      isDefaultCatalogMode={
                                        isDefaultCatalogMode
                                      }
                                    />
                                  ))}
                            </Row>
                          </CategoryContainer>
                        </Row>
                      );
                    })}
                </ProductList>
              </Row>
            )}
          </Col>
          {!isLoading && (
            <ColCart lg={4} sm={12}>
              <CartDesktopContainer>
                {redeem &&
                  !redeem.message &&
                  (!cashbackConfigs.active ||
                    cashbackConfigs.active === 'NO') && (
                    <CardHeader
                      style={{
                        padding: 0,
                        backgroundColor: config.bottomBackgroundColor,
                      }}
                    >
                      <PointsBase
                        cart={cart}
                        redeem={redeem}
                        addRedeemItem={addRedeemItem}
                        categoryToFilterRedeem={state.categoryToFilterRedeem}
                        categoryOnChange={categoryOnChange}
                        token={getUser() && getUser().token}
                        loadingModal={state.loadingModal}
                        redeemOptionsFiltered={redeemOptions}
                      />
                    </CardHeader>
                  )}
                {cashbackConfigs?.active === 'YES' && (
                  <CardHeader
                    style={{
                      padding: 0,
                      backgroundColor: config.bottomBackgroundColor,
                    }}
                  >
                    <CashbackCurrent
                      cart={cart}
                      redeem={redeem}
                      addRedeemItem={addRedeemItem}
                      categoryToFilterRedeem={state.categoryToFilterRedeem}
                      categoryOnChange={categoryOnChange}
                      token={getUser() && getUser().token}
                      loadingModal={state.loadingModal}
                      redeemOptionsFiltered={redeemOptions}
                    />
                  </CardHeader>
                )}
                <BodyNoScroll>
                  {storeDetail?.open !== undefined && !storeDetail?.open && (
                    <Row>
                      <Col xs={12} style={{ padding: '1rem' }}>
                        <h2
                          style={{
                            marginBottom: 0,
                            padding: '0.5rem',
                            fontSize: 26,
                          }}
                        >
                          Loja fechada no momento
                        </h2>
                      </Col>
                    </Row>
                  )}
                  {(!storeDetail || (storeDetail && storeDetail.open)) && (
                    <div
                      style={{
                        height: '100%',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        padding:
                          cart.products.length === 0
                            ? '30px'
                            : '30px 30px 0 30px',
                        scrollBehavior: 'smooth',
                        maxHeight: 350,
                      }}
                      id="cart"
                    >
                      <CartBody storeDetail={storeDetail} />
                    </div>
                  )}
                </BodyNoScroll>
                {storeDetail?.open ? (
                  <CartFooter
                    removePromocode={removePromocode}
                    openStoreSelection={openStoreSelection}
                    storeDetail={storeDetail}
                  />
                ) : (
                  <div />
                )}
              </CartDesktopContainer>
            </ColCart>
          )}
        </Row>
        <Footer />
      </div>
      {config.termsUrl || config.privacyUrl ? (
        <CookieConsent
          location="bottom"
          buttonText="Estou de acordo"
          cookieName={`${process.env.REACT_APP_PROJECT}Cookie`}
          style={{
            background: '#2B373B',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '15%',
          }}
          overlayStyle={{ opacity: 1 }}
          overlay
          buttonStyle={{
            backgroundColor: config.bottomBackgroundColor,
            color: config.bottomTextColor,
            fontSize: '18px',
          }}
          expires={1500}
        >
          <div>
            Para acessar este site, você precisa estar de acordo com nossas{' '}
            <a href={config.privacyUrl || config.termsUrl}>
              Políticas de privacidade
            </a>{' '}
            e os{' '}
            <a href={config.termsUrl || config.privacyUrl}>Termos de uso</a>
          </div>
        </CookieConsent>
      ) : null}
      <div id="ponto">||</div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default Store;
