import React from 'react';

import { Container } from './styles';

const HomeBanner = ({
  merchantBannerSrc,
  headerBackgroundColor,
  merchantLogoSrc,
}) => {
  return (
    <Container
      merchantBannerSrc={merchantBannerSrc}
      headerBackgroundColor={headerBackgroundColor}
    >
      {!merchantBannerSrc && (
        <img
          src={merchantLogoSrc}
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            maxHeight: 150,
          }}
          alt="Logo"
        />
      )}
    </Container>
  );
};

export default HomeBanner;
