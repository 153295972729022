import getBrowserFingerprint from 'get-browser-fingerprint';

import { captureException } from '@sentry/react';
import { getCart, getTrackId, setTrackId, storeCart } from '../util';

const { version } = require('../../package.json');

const apiUrl = {
  DEV: 'http://localhost:6008',
  HOMOLOG: 'https://beta-api-ecommerce.tem.delivery',
  PROD: 'https://api-ecommerce.opdv.com.br',
};

export const BASE_URL = apiUrl[process.env.REACT_APP_ENVIROMENT || 'PROD'];

const defaultHeaders = {
  'Content-Type': 'application/json',
  'x-version': version,
};

let catalog = [];

export const getSaleCart = async ({ salesTrackId, mainPhone, promocodeId }) => {
  const res = await fetch(
    `/api/sales/cart/${salesTrackId}/${mainPhone}/${promocodeId}`,
    {
      headers: defaultHeaders,
    }
  );
  const data = await res.json();
  return data;
};
export const trackSale = async ({ mainPhone, storeId, cart, isCheckout }) => {
  const track = cart.salesTrackId || getTrackId();
  const res = await fetch(`/api/sales/track`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({
      mainPhone,
      storeId,
      cart,
      isCheckout,
      salesTrackId: track && track !== 'null' ? track : null,
    }),
  });
  const data = await res.json();
  if (data?.salesTrackId) {
    storeCart({ ...getCart(), salesTrackId: data.salesTrackId });
    setTrackId(data.salesTrackId);
  }
};

export const checkPromocode = async ({ code, token, storeId, orderValue }) => {
  return fetch(
    `/api/loyalty/promocodes/${code}/validate/${storeId}/${orderValue}`,
    {
      headers: {
        ...defaultHeaders,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  ).then(res => res.json());
};

export const valideteHashcode = async hash => {
  return fetch(`/api/hash/process/${hash}`, {
    headers: defaultHeaders,
  }).then(res => res.json());
};

export const retrieveSubscriptions = async storeId => {
  return fetch(`/api/subscriptions/${storeId}`, {
    headers: defaultHeaders,
  }).then(res => res.json());
};

export const retrieveCampaigns = async (storeId, digitalMerchantId) => {
  if (!storeId) {
    return fetch(`/api/campaigns/all/${digitalMerchantId}`, {
      headers: defaultHeaders,
    }).then(res => res.json());
  }
  return fetch(`/api/campaigns/${storeId}`, {
    headers: defaultHeaders,
  }).then(res => res.json());
};

const retrieveCatalog = async storeId => {
  return fetch(`/api/catalog?storeId=${storeId}`, {
    headers: defaultHeaders,
  }).then(res => res.json());
};

const retrieveDefaultCatalog = () =>
  fetch('/api/default_catalog', {
    headers: defaultHeaders,
  }).then(res => res.json());

const retrieveLocalCatalog = async storeId => {
  return fetch(`/api/local_catalog?storeId=${storeId}`, {
    headers: defaultHeaders,
  }).then(res => res.json());
};

/**
 * @param {{
 *   storeId: number,
 *   tableAccountMode: TableAccountMode,
 *   isTakeAwayMode: boolean
 * }}
 * @returns {Array}
 */
export const listCategories = async ({
  storeId,
  tableAccountMode,
  isTakeAwayMode,
}) => {
  if (tableAccountMode || isTakeAwayMode) {
    catalog = await retrieveLocalCatalog(storeId);
  } else if (!storeId) {
    catalog = await retrieveDefaultCatalog();
  } else {
    catalog = await retrieveCatalog(storeId);
  }

  return catalog.map(catalogItem => ({
    name: catalogItem.name,
    id: catalogItem.id,
    image: catalogItem.image,
    siteColor: catalogItem.siteColor,
    categories: catalogItem.categories,
    items: catalogItem.items,
  }));
};

export const listProducts = (parentCategortId, categoryId) => {
  const products = catalog
    .find(({ id }) => id === parentCategortId)
    .categories.filter(({ id }) => id === categoryId);
  let allProducts = [];
  products.forEach(product => {
    allProducts = [...allProducts, ...product.items];
  });
  return allProducts || [];
};

export const listAllProducts = async () => {
  const catalogDb = await retrieveCatalog();
  let allProducts = [];
  catalogDb.forEach(({ categories }) => {
    categories.forEach(category => {
      allProducts = [...allProducts, ...category.items];
    });
  });
  return allProducts || [];
};

export const fetchStore = storeId =>
  fetch(`/api/stores/${storeId}`, {
    headers: defaultHeaders,
  }).then(res => res.json());

export const fetchStores = digitalMerchantId =>
  fetch(`/api/${digitalMerchantId}/stores`, {
    headers: defaultHeaders,
  }).then(res => res.json());

export const trackData = async ({
  track,
  platform,
  os,
  platformVersion,
  url,
}) => {
  const fingerprint = getBrowserFingerprint();
  console.info({
    track,
    fingerprint,
    platform,
    url,
    os,
    platformVersion,
  });
  try {
    return await fetch(`${BASE_URL}/v2/register`, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({
        track,
        fingerprint,
        platform,
        url,
        os,
        platformVersion,
      }),
    });
  } catch (e) {
    return console.log(e);
  }
};

export const getConfigurations = async () => {
  const res = await fetch(`/api/config`, {
    method: 'GET',
    headers: defaultHeaders,
  });
  return await res.json();
};

export const saveFastCustomer = async customer => {
  return fetch(`/api/customer/fast`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(customer),
  }).then(res => res.json());
};

export const callLogin = async ({ username, password }) => {
  return fetch(`/api/customer/login`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({ username, password }),
  }).then(res => res.json());
};

export const createNewAddress = async ({ body, token }) => {
  return fetch(`/api/customer/address`, {
    method: 'POST',
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const fetchUserDetail = async ({ token }) => {
  return fetch(`/api/customer/me`, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const fetchUserData = ({ cpf = '', email = '' }) =>
  fetch(`/api/customer/search?cpf=${cpf}&email=${email}`, {
    headers: defaultHeaders,
  }).then(res => res.json());

export const fetchAddressInformation = cep =>
  fetch(`/api/deliveryareas/zip/${cep}/address`, {
    headers: defaultHeaders,
  }).then(res => res.json());

export const fetchDeliveryInformationV2 = async ({
  zip,
  number,
  street,
  merchantId,
  digitalMerchantId,
}) => {
  return fetch(
    `/api/coverage/${digitalMerchantId}/${zip}?number=${
      number || ''
    }&street=${encodeURIComponent(street || '')}`,
    {
      method: 'POST',
      body: JSON.stringify({ merchantId }),
      headers: defaultHeaders,
    }
  ).then(res => res.json());
};

export const fetchDeliveryInformation = cep =>
  fetch(`/api/deliveryareas/zip/${cep}/validadecoverage`, {
    headers: defaultHeaders,
  }).then(res => res.json());

export const fetchCards = (token, storeId, hasLogin) => {
  if (hasLogin === 'NO') return { cards: [] };
  return fetch(`/api/cards/${storeId}`, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const createCard = async (token, body) => {
  try {
    const res = await fetch(`/api/cards`, {
      method: 'POST',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    return await res.json();
  } catch (e) {
    return console.log('erro ao criar cartão: ', e);
  }
};

export const fetchStorePayments = storeId =>
  fetch(`/api/payments/store/${storeId}/payments`, {
    headers: defaultHeaders,
  }).then(res => res.json());

export const sendMetadata = async (orderId, body) => {
  const fingerprint = getBrowserFingerprint();
  try {
    const res = await fetch(`/api/metadata/${orderId}`, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({ ...body, fingerprint }),
    });
    return await res.json();
  } catch (e) {
    return console.error(e);
  }
};

export const sendOrder = async (
  storeId,
  body,
  token,
  hasLogin,
  salesTrackId
) => {
  // if (
  //   hasLogin === 'NO' &&
  //   body.payments[0].paymentMethod.key === 'pix_online'
  // ) {
  //   return Promise.resolve({ id: 1 });
  // }
  const headers = {
    ...defaultHeaders,
  };
  if (hasLogin !== 'NO') {
    headers.Authorization = `Bearer ${token}`;
  }
  const res = await fetch(
    `/api/stores/${storeId}/orders?salesTrackId=${salesTrackId}`,
    {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({ ...body }),
    }
  );
  return await res.json();
};

export const verifyOrderPixStatus = async ({
  storeId,
  paymentId,
  orderId,
  token,
  hasLogin,
}) => {
  return fetch(`/api/stores/${storeId}/orders/${orderId}/status/${paymentId}`, {
    method: 'GET',
    timeout: 6000000,
    headers: {
      ...defaultHeaders,
      Authorization: hasLogin === 'NO' ? '' : `Bearer ${token}`,
    },
  }).then(response => response.json());
};

export const createAccount = async body => {
  return fetch(`/api/customer/create`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const verifyAccount = async body => {
  return fetch(`/api/customer/verify`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const resetVerifyAccount = async body => {
  return fetch(`/api/customer/reset/verify`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const resetAccount = async body => {
  return fetch(`/api/customer/reset`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const fetchOrders = async token => {
  return fetch(`/api/customer/orders`, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const fetchTempOrders = async orderId => {
  return fetch(`/api/customer/order/${orderId}`, {
    headers: defaultHeaders,
  }).then(res => res.json());
};

export const doSendSmsAgain = async ({ cpf, validateToken }) => {
  return fetch(`/api/customer/reset/aws`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify({ cpf, validateToken }),
  }).then(res => res.json());
};

export const fetchRedeemOptions = async ({
  token,
  storeId,
  categoryId = null,
}) => {
  return fetch(
    `/api/loyaltypoints/redeemOptions?storeId=${storeId}${
      categoryId ? `&categoryId=${categoryId}` : ''
    }&hostname=${process.env.REACT_APP_URL || process.env.PUBLIC_URL}`,
    {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(res => res.json());
};

export const fetchCashbackConfiguration = ({ token }) => {
  if (!token) {
    return {};
  }
  return fetch(
    `/api/cashback?hostname=${
      process.env.REACT_APP_URL || process.env.PUBLIC_URL
    }`,
    {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(res => res.json());
};

export const fetchCashbackExtract = ({ token }) => {
  if (!token) {
    return {};
  }
  return fetch(
    `/api/cashback/extract?hostname=${
      process.env.REACT_APP_URL || process.env.PUBLIC_URL
    }`,
    {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(res => res.json());
};

export const fetchOpenHours = async ({ storeId, hasLogin, user }) => {
  return fetch(`/api/stores/${storeId}/openhours?hasLogin=${hasLogin}`, {
    headers: {
      ...defaultHeaders,
      Authorization: hasLogin === 'NO' || !user ? '' : `Bearer ${user?.token}`,
    },
  }).then(res => res.json());
};

export const listProductCategories = async ({ token }) => {
  const res = await fetch(`${BASE_URL}/categories`, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  return await res.json();
};

export const fetchAddressFromLatLng = async ({ latitude, longitude }) => {
  const res = await fetch(
    `/api/deliveryareas/latlng/${latitude}/${longitude}`,
    {
      method: 'GET',
      headers: defaultHeaders,
    }
  );
  return await res.json();
};

export const verifyCardIsValid = async ({
  lastDigits,
  cpf,
  mainPhone,
  token,
  hasLogin,
}) => {
  const res = await fetch(
    `${BASE_URL}/cards/validate?lastDigits=${lastDigits}&cpf=${cpf}&mainPhone=${mainPhone}`,
    {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: hasLogin === 'NO' ? '' : `Bearer ${token}`,
      },
    }
  );
  return await res.json();
};

export const sendChargeToCard = async ({ body, token, hasLogin }) => {
  try {
    const res = await fetch(`${BASE_URL}/cards/validate/charge`, {
      method: 'POST',
      headers: {
        ...defaultHeaders,
        Authorization: hasLogin === 'NO' ? '' : `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    return await res.json();
  } catch (err) {
    captureException(err);
    return {
      error: 'Ocorreu um erro ao fazer a cobrança, tente novamente',
    };
  }
};

export const sendAttemptyToCard = async ({ body, token, hasLogin }) => {
  const res = await fetch(`${BASE_URL}/cards/validate/verify`, {
    method: 'POST',
    headers: {
      ...defaultHeaders,
      Authorization: hasLogin === 'NO' ? '' : `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return await res.json();
};

export const sendOrderReview = async ({ body, token, hasLogin }) => {
  const res = await fetch('/api/review', {
    method: 'POST',
    headers: {
      ...defaultHeaders,
      Authorization: hasLogin === 'NO' ? '' : `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (!res.ok) {
    const data = await res.json();
    if (data.userMessage) {
      throw data;
    }
    throw res;
  }
  return await res.json();
};
