import { createStoreEncodedCategoryName } from '../../util';

export const processScroll = () => {
  const categoriesElements = document.querySelectorAll('.category');
  const categoriesOffset = [];
  [...categoriesElements].forEach(element => {
    const isParentCategory = element.classList.contains('parent_category');
    const calculatedOffset = isParentCategory ? 185 : 145;
    const name = createStoreEncodedCategoryName(
      element.querySelector('.category_name').innerText
    );
    const offset =
      element.offsetTop - calculatedOffset - document.documentElement.scrollTop;
    categoriesOffset.push({
      id: element.id,
      name,
      isParentCategory,
      offset,
      scrollHeight: element.scrollHeight,
    });
  });
  return categoriesOffset;
};
