import styled from 'styled-components';
import { getSelectedStore } from '../../util';

export const Container = styled.div`
  width: 100%;
  position: fixed;
  z-index: 20;
  left: 15px;
  top: 107px;

  @media (max-width: 999px) {
    top: ${props => (props.shortHeader ? '60px' : '107px')};
    transition: top 0.3s;
  }
`;

export const Fixed = styled.div`
  position: absolute;
  bottom: 0px;
`;

export const TitleTakeAway = styled.h1`
  cursor: 'pointer';
  font-size: 14px;
  color: ${({ config }) => config.textBaseColor};
  font-weight: ${() =>
    getSelectedStore() && getSelectedStore().type === 'takeaway'
      ? 'bold'
      : 'normal'};
  border-bottom: ${({ config }) =>
    getSelectedStore() && getSelectedStore().type === 'takeaway'
      ? `1px solid ${config.headerUnderlineColor}`
      : '0px'};
`;

export const TitleDelivery = styled.h1`
  cursor: 'pointer';
  font-size: 14px;
  color: ${({ config }) => config.textBaseColor};
  font-weight: ${() =>
    getSelectedStore() && getSelectedStore().type === 'delivery'
      ? 'bold'
      : 'normal'};
  border-bottom: ${({ config }) =>
    getSelectedStore() && getSelectedStore().type === 'delivery'
      ? `1px solid ${config.headerUnderlineColor}`
      : '0px'};
`;
