import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { createContext, useContextSelector } from 'use-context-selector';
import { getStorageUser, getUser } from '../util';

const cookies = new Cookies();
const CookieContext = createContext({});

/**
 * @typedef {Object} CustomerCookie
 * @property {string} name - O nome do cliente.
 * @property {string} phone - O telefone do cliente.
 */

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns {React.JSX.Element}
 */
export const CookieProvider = ({ children }) => {
  /** @type {[CustomerCookie, React.Dispatch<React.SetStateAction<CustomerCookie>>]} */
  const [customerCookie, setCustomerCookie] = useState({
    name: cookies.get('customer-name') || '',
    phone: cookies.get('customer-phone') || '',
  });

  /**
   * @param {CustomerCookie} customer - O objeto contendo o nome e o telefone do cliente.
   */
  const setCustomerCookieHandler = (customer, options = {}) => {
    cookies.set('customer-name', customer.name, { path: '/', ...options });
    cookies.set('customer-phone', customer.phone, { path: '/', ...options });
    setCustomerCookie(customer);
  };

  const removeCustomerCookie = () => {
    cookies.remove('customer-name');
    cookies.remove('customer-phone');
    setCustomerCookie({ name: '', phone: '' });
  };

  useEffect(() => {
    const user = getUser() || getStorageUser();

    const name = cookies.get('customer-name') || user?.name || '';
    const phone = cookies.get('customer-phone') || user?.mainPhone || '';
    if (!cookies.get('customer-name') && user?.name) {
      cookies.set('customer-name', user.name);
    }

    if (!cookies.get('customer-phone') && user?.mainPhone) {
      cookies.set('customer-phone', user.mainPhone);
    }

    setCustomerCookie({
      name,
      phone,
    });
  }, []);

  return (
    <CookieContext.Provider
      value={{
        customerCookie,
        setCustomerCookie: setCustomerCookieHandler,
        removeCustomerCookie,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

/**
 * @returns {{
 *   customerCookie: CustomerCookie,
 *   setCustomerCookie: (customer: CustomerCookie, options?: object) => void,
 *   removeCustomerCookie: () => void,
 * }}
 */
export const useCookieContext = () => {
  const customerCookie = useContextSelector(
    CookieContext,
    state => state.customerCookie
  );
  const setCustomerCookie = useContextSelector(
    CookieContext,
    state => state.setCustomerCookie
  );
  const removeCustomerCookie = useContextSelector(
    CookieContext,
    state => state.removeCustomerCookie
  );

  return {
    customerCookie,
    setCustomerCookie,
    removeCustomerCookie,
  };
};
