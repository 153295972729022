import React, { useEffect } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { useInternalContext } from '../../Context';
import { isMobile } from '../../util';
import { CloseButtonModal } from '../../util/style';
import {
  ModalBase,
  ModalHeaderBase,
  ModalBodyBase,
  ModalFooterBase,
} from './style';

export const Modal = props => {
  const { config } = useInternalContext();

  useEffect(() => {
    const handleKeyPress = event => {
      const { closeModal } = props;
      if (closeModal && event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ModalBase {...props} config={config} />;
};

export const ModalHeader = props => {
  const { config } = useInternalContext();

  if (props.hasCloseButton) {
    return (
      <ModalHeaderBase
        {...props}
        className="modal-header"
        isMobile={isMobile()}
        config={config}
      >
        <CloseButtonModal color="black" onClick={props.closeModal}>
          <AiOutlineArrowDown size={30} />
        </CloseButtonModal>
        <span
          style={{
            flex: 1,
            fontSize: 17,
            fontWeight: 600,
            textAlign: 'center',
            letterSpacing: '-0.03em',
          }}
        >
          {props.title || 'DETALHES'}
        </span>
        {props.children && (
          <h5 style={{ marginBottom: '0px' }}>{props.children}</h5>
        )}
      </ModalHeaderBase>
    );
  }

  return (
    <ModalHeaderBase
      {...props}
      className="modal-header"
      isMobile={isMobile()}
      config={config}
    >
      {props.children && (
        <h5 style={{ marginBottom: '0px' }}>{props.children}</h5>
      )}
    </ModalHeaderBase>
  );
};

export const ModalBody = props => {
  const { config } = useInternalContext();

  return <ModalBodyBase {...props} config={config} />;
};

export const ModalFooter = props => {
  const { config } = useInternalContext();

  return <ModalFooterBase {...props} config={config} />;
};
