import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardFooter } from 'reactstrap';
import { useInternalContext } from '../../Context';
import { useCartContext } from '../../contexts/CartContext';
import {
  calcTotalCart,
  getDeliveryFee,
  getSelectedStore,
  getUser,
  isUserLogged,
  toBlr,
} from '../../util';
import { trackEvent } from '../../util/track';
import { Button } from '../button';
import { ProductPrice, ProductPriceRow } from './style';

export const CartFooterTableAccountMode = ({
  openStoreSelection,
  storeDetail,
  fixed = false,
}) => {
  const [heightCartFooter, setHeightCartFooter] = useState(0);
  const { config } = useInternalContext();
  const { cart } = useCartContext();
  const history = useHistory();
  const cartFooter = useRef(null);
  const deliveryFee = getDeliveryFee(cart.deliveryFee);

  useEffect(() => {
    if (cartFooter?.current?.clientHeight) {
      setHeightCartFooter(cartFooter.current.clientHeight);
    }
  }, [cartFooter, cart]);

  const goPayment = async () => {
    trackEvent({
      name: 'willNavigateToCart',
      data: {
        isLoggedUser: isUserLogged(),
        user: getUser(),
      },
    });
    if (!getSelectedStore()) {
      openStoreSelection();
      return;
    }

    const paymentFunctions = {
      false: () => history.push('/login'),
      true: () => history.push('/checkout'),
    };
    paymentFunctions[config.hasLogin === 'NO' || isUserLogged()]();
  };

  if (cart?.products?.length === 0) {
    return <div />;
  }

  return (
    <div
      ref={cartFooter}
      style={
        fixed
          ? {
              width: '100%',
              position: 'fixed',
              top: `calc(100% - (70px + ${heightCartFooter}px))`,
              background: 'white',
              margin: ' 0 -15px',
            }
          : {}
      }
    >
      <CardFooter>
        {cart && (
          <>
            <ProductPriceRow>
              <span>Subtotal: </span>
              <ProductPrice>
                {calcTotalCart({
                  cartProducts: [...cart.products],
                })}
              </ProductPrice>
            </ProductPriceRow>
            {(deliveryFee > 0 || cart.zeroDelivery) && (
              <ProductPriceRow>
                <span>Frete: </span>
                <ProductPrice>{toBlr(deliveryFee)}</ProductPrice>
              </ProductPriceRow>
            )}
            {!!cart.discount && (
              <ProductPriceRow>
                <span>Desconto: </span>
                <ProductPrice>{toBlr(cart.discount)}</ProductPrice>
              </ProductPriceRow>
            )}
            <ProductPriceRow style={{ fontWeight: 500 }}>
              <span>Total: </span>
              <ProductPrice style={{ fontWeight: 500 }}>
                {calcTotalCart({
                  cartProducts: [...cart.products],
                  deliveryFee,
                  discount: cart.discount,
                })}
              </ProductPrice>
            </ProductPriceRow>
          </>
        )}
      </CardFooter>
      {storeDetail && cart.products.length > 0 && (
        <CardFooter>
          <Button disabled={!storeDetail.open} onClick={goPayment}>
            Fazer pedido
          </Button>
        </CardFooter>
      )}
    </div>
  );
};
