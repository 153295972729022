import React from 'react';
import { FooterStyled } from './style';

import { useInternalContext } from '../../Context';
import opdvImage from './images/o-pdv-restaurante.png';

const Footer = () => {
  const { config } = useInternalContext();
  return config ? (
    <FooterStyled>
      <span style={{ fontSize: 14, letterSpacing: 1 }}>
        Plataforma desenvolvida por{' '}:{' '}
      </span>
      <a
        target="_blank"
        href="https://opdv.com.br"
        alt="Sistema para restaurante OPDV"
        rel="noopener noreferrer"
      >
        <img src={opdvImage} width="150" alt="OPDV" />
      </a>
    </FooterStyled>
  ) : null;
};

export default Footer;
