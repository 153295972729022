import React from 'react';
import { Rating } from 'react-simple-star-rating';

export const StarRating = ({
  value,
  onClick,
  size = 30,
  readonly = false,
  showTooltip = false,
}) => {
  return (
    <Rating
      size={size}
      transition
      initialValue={value}
      onClick={rating => onClick(rating)}
      readonly={readonly} // modo leitura
      showTooltip={showTooltip}
      tooltipDefaultText="Escolha de 1 a 5 estrelas"
      tooltipArray={[
        'Muito ruim',
        'Abaixo do esperado',
        'Razoável',
        'Bom',
        'Excelente',
      ]}
      tooltipStyle={{
        margin: 0,
        minWidth: '220px',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%)',
        background: 'none',
        color: '#6c757d',
      }}
      style={{
        margin: '8px',
        position: 'relative',
        height: `${showTooltip ? '75px' : 'auto'}`,
      }}
      fillColorArray={['#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']}
    />
  );
};
