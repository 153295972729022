import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createContext, useContextSelector } from 'use-context-selector';
import {
  calcTotalCart,
  getCart,
  getCashback,
  getSelectedStore,
  setTrackId,
  storeCart,
  storeCashback,
} from '../util';
import { track } from '../util/logUtil';
import { useCookieContext } from './CookieContext';

const CartContext = createContext({});

const initialCartDetail = {
  products: [],
  discount: 0,
  percent: 0,
  cashback: 0,
  promocodeId: null,
  discountDescription: null,
};

const cartWithDiscountRemoved = {
  promocodeId: null,
  discountId: null,
  discount: 0,
  percent: 0,
  discountDescription: null,
  zeroDelivery: false,
};

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns {React.JSX.Element}
 */
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(getCart() || initialCartDetail);
  const [cashbackConfigs, setCashbackConfigs] = useState(getCashback());
  const { customerCookie } = useCookieContext();

  const updateCart = data => {
    setCart(data);
    if (data.products.length === 0) {
      track({ ...data, salesTrackId: null }, false, customerCookie.phone);
      setTrackId(null);
    } else {
      track(data, false, customerCookie.phone);
    }
  };
  const updateCartRemovingPromocode = cartReceived => {
    if (cart.promocodeId) {
      const cartBase = {
        ...cartReceived,
        ...cartWithDiscountRemoved,
      };

      if (getSelectedStore()?.deliveryFee) {
        cartBase.deliveryFee = getSelectedStore().deliveryFee;
      } else {
        cartBase.deliveryFee = undefined;
      }

      cartBase.total = calcTotalCart({
        cartProducts: cartReceived.products,
        deliveryFee: cartReceived.deliveryFee,
        format: false,
      });

      updateCart(cartBase);
      toast.info('O cupom de desconto foi removido');
    } else {
      const cartBase = {
        ...cartReceived,
      };
      cartBase.total = calcTotalCart({
        cartProducts: cartReceived.products,
        deliveryFee: cartReceived.deliveryFee,
        format: false,
      });

      updateCart(cartBase);
    }
  };

  const setFromCookie = dataFromCookie => updateCart(dataFromCookie);

  const replaceToCart = product => {
    const productIndex = cart.products.findIndex(
      ({ cartId }) => cartId === product.cartId
    );
    const productsToCart = [...cart.products];
    productsToCart[productIndex] = product;

    updateCartRemovingPromocode({
      ...cart,
      ...cartWithDiscountRemoved,
      products: [...productsToCart],
      cashback: 0,
    });
  };

  const addToCart = product => {
    console.log('# here ');
    toast.success('Produto adicionado!', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const cartBase = {
      ...cart,
      cashback: 0,
      products: [
        ...cart.products,
        {
          cartId: `${moment().format("YYYYMMDDHHmmssSSSSSSSSS'")}`,
          timeCreation: moment().valueOf(),
          ...product,
        },
      ],
    };
    cartBase.total = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: cartBase.deliveryFee,
      format: false,
    });

    updateCartRemovingPromocode(cartBase);
  };

  const sortCartProducts = (a, b) => b.timeCreation - a.timeCreation;

  const removeFromCart = cartId => {
    const newProducts = cart.products
      .filter(product => product.cartId !== cartId)
      .sort(sortCartProducts);
    if (newProducts.length === 0) {
      setTrackId(null);
    }
    const cartBase = {
      ...cart,
      salesTrackId: newProducts.length === 0 ? null : cart.salesTrackId,
      percent: 0,
      discount: 0,
      cashback: 0,
      discountDescription: '',
      products: newProducts,
    };
    cartBase.total = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: cartBase.deliveryFee,
      format: false,
    });

    updateCartRemovingPromocode(cartBase);
  };

  const subFromCart = cartId => {
    const cartBase = {
      ...cart,
      cashback: 0,
      products: cart.products
        .map(product => {
          return {
            ...product,
            quantity:
              product.cartId === cartId
                ? product.quantity - 1
                : product.quantity,
          };
        })
        .sort(sortCartProducts),
    };

    if (cartBase.total < 0) {
      updateCartRemovingPromocode({
        ...cartBase,
        products: cartBase.products.filter(
          ({ redeemId, price }) => (redeemId && price === 0) || !redeemId
        ),
      });
      return;
    }

    updateCartRemovingPromocode(cartBase);
  };

  const sumFromCart = cartId => {
    const cartBase = {
      ...cart,
      cashback: 0,
      products: cart.products
        .map(product => {
          return {
            ...product,
            quantity:
              product.cartId === cartId
                ? product.quantity + 1
                : product.quantity,
          };
        })
        .sort(sortCartProducts),
    };

    updateCartRemovingPromocode(cartBase);
  };

  const addRedeemItem = redeem => {
    const redeemProduct = {
      name: redeem.discountName || redeem.productName,
      id: redeem.productId,
      points: redeem.pointsToRedeem,
      quantity: 1,
      redeemId: redeem.id || redeem.productName,
      wizardSteps: [],
      cartId: `${moment().format("YYYYMMDDHHmmssSSSSSSSSS'")}`,
      timeCreation: moment().valueOf(),
      price: redeem.discountId ? parseInt(redeem.discountValue, 10) * -1 : 0,
    };
    const cartBase = {
      ...cart,
      products: [
        ...cart.products,
        {
          ...redeemProduct,
        },
      ].sort(sortCartProducts),
    };
    updateCart({
      ...cartBase,
      total: calcTotalCart({
        cartProducts: cartBase.products,
        deliveryFee: cartBase.deliveryFee,
        format: false,
      }),
    });
  };

  const applyZeroDelivery = ({ promocodeId, discountDescription }) => {
    const cartBase = {
      ...cart,
      deliveryFee: 0,
      zeroDelivery: true,
      discountDescription: null,
      discount: null,
      promocodeId: null,
      discountId: null,
    };
    cartBase.total = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: 0,
      format: false,
    });
    updateCart({
      ...cartBase,
      discountDescription,
      promocodeId,
      zeroDelivery: true,
    });
    toast.success('Cupom aplicado com sucesso!');
  };

  const applyPromocode = ({
    percent,
    money,
    promocodeId,
    discountDescription,
  }) => {
    const cartBase = {
      ...cart,
      discountDescription,
      discount: null,
      promocodeId,
      discountId: null,
    };
    cartBase.total = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: cartBase.deliveryFee,
      format: false,
    });
    const productTotal = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: 0,
      format: false,
    });

    const isPercentPromocode = !money;
    const moneyFixed = money > productTotal ? productTotal : money;

    updateCart({
      ...cartBase,
      discount: isPercentPromocode
        ? Math.round(
            calcTotalCart({
              cartProducts: cartBase.products,
              format: false,
            }) * percent
          )
        : moneyFixed,
      discountDescription,
      promocodeId,
      percent,
    });
    toast.success('Cupom aplicado com sucesso!');
  };

  const removePromocode = () => {
    const cartBase = {
      ...cart,
      ...cartWithDiscountRemoved,
    };

    if (getSelectedStore()?.deliveryFee) {
      cartBase.deliveryFee = getSelectedStore().deliveryFee;
    } else {
      cartBase.deliveryFee = undefined;
    }

    cartBase.total = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: cartBase.deliveryFee,
      format: false,
    });

    updateCart(cartBase);
    toast.success('Cupom removido com sucesso!');
  };

  const applyCashback = () => {
    const { currentCustomerValue } = cashbackConfigs;
    const cartBase = {
      ...cart,
      cashback: null,
    };

    if (getSelectedStore()?.deliveryFee) {
      cartBase.deliveryFee = getSelectedStore().deliveryFee;
    } else {
      cartBase.deliveryFee = undefined;
    }

    cartBase.total = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: cartBase.deliveryFee,
      format: false,
    });

    updateCart({
      ...cartBase,
      cashback:
        currentCustomerValue >= cartBase.total
          ? cartBase.total - cartBase.discount
          : currentCustomerValue,
    });
    toast.success('Cashback aplicado com sucesso!');
  };

  const removeCashback = (isLogout = false) => {
    const cartBase = {
      ...cart,
      cashback: 0,
    };

    if (getSelectedStore()?.deliveryFee) {
      cartBase.deliveryFee = getSelectedStore().deliveryFee;
    } else {
      cartBase.deliveryFee = undefined;
    }

    cartBase.total = calcTotalCart({
      cartProducts: cartBase.products,
      deliveryFee: cartBase.deliveryFee,
      format: false,
    });

    updateCart(cartBase);
    if (!isLogout) toast.success('Cashback removido com sucesso!');
  };

  const clearCart = () => {
    updateCart(initialCartDetail);
  };

  useEffect(() => {
    storeCashback(cashbackConfigs);
  }, [cashbackConfigs]);

  useEffect(() => {
    storeCart(cart);
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        applyPromocode,
        removePromocode,
        applyZeroDelivery,
        setFromCookie,
        addToCart,
        removeFromCart,
        subFromCart,
        addRedeemItem,
        sumFromCart,
        clearCart,
        replaceToCart,
        updateCart,
        applyCashback,
        removeCashback,
        cashbackConfigs,
        setCashbackConfigs,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const cart = useContextSelector(CartContext, state => state.cart);
  const applyPromocode = useContextSelector(
    CartContext,
    state => state.applyPromocode
  );

  const removePromocode = useContextSelector(
    CartContext,
    state => state.removePromocode
  );
  const applyZeroDelivery = useContextSelector(
    CartContext,
    state => state.applyZeroDelivery
  );
  const setFromCookie = useContextSelector(
    CartContext,
    state => state.setFromCookie
  );
  const addToCart = useContextSelector(CartContext, state => state.addToCart);
  const removeFromCart = useContextSelector(
    CartContext,
    state => state.removeFromCart
  );
  const subFromCart = useContextSelector(
    CartContext,
    state => state.subFromCart
  );
  const addRedeemItem = useContextSelector(
    CartContext,
    state => state.addRedeemItem
  );
  const sumFromCart = useContextSelector(
    CartContext,
    state => state.sumFromCart
  );
  const clearCart = useContextSelector(CartContext, state => state.clearCart);
  const replaceToCart = useContextSelector(
    CartContext,
    state => state.replaceToCart
  );
  const updateCart = useContextSelector(CartContext, state => state.updateCart);
  const applyCashback = useContextSelector(
    CartContext,
    state => state.applyCashback
  );

  const removeCashback = useContextSelector(
    CartContext,
    state => state.removeCashback
  );

  return {
    cart,
    applyPromocode,
    removePromocode,
    applyZeroDelivery,
    setFromCookie,
    addToCart,
    removeFromCart,
    subFromCart,
    addRedeemItem,
    sumFromCart,
    clearCart,
    replaceToCart,
    updateCart,
    applyCashback,
    removeCashback,
  };
};

export const useCashbackContext = () => {
  const cashbackConfigs = useContextSelector(
    CartContext,
    state => state.cashbackConfigs
  );

  const setCashbackConfigs = useContextSelector(
    CartContext,
    state => state.setCashbackConfigs
  );

  return {
    cashbackConfigs,
    setCashbackConfigs,
  };
};
