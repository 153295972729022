import styled from 'styled-components';

export const Container = styled.div`
  height: 200px;
  background-color: ${props => props.headerBackgroundColor};
  width: 100%;
  background-image: ${props => `url(${props.merchantBannerSrc})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
`;
