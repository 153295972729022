export const isRequiredFilled = step => {
  if (step.min === 0) {
    return true;
  }
  if (step.optionType === 'MULTIPLE') {
    const sumOptionsQuantity = step.options.reduce(
      (acc, option) => acc + option.quantity,
      0
    );
    return sumOptionsQuantity >= step.min;
  }
  return Object.keys(step.formData).find(
    key => step.formData[key]?.checked === true
  );
};

export const getStepSubTitle = step => {
  if (step.min === 0 && step.max === 1) {
    return `Escolha até ${step.max} opção.`;
  }
  if (step.max === 1) {
    return `Escolha ${step.max} opção.`;
  }
  if (step.max > 1 && step.min !== step.max) {
    return `Escolha até ${step.max} opções.`;
  }
  if (step.max === step.min) {
    return `Escolha ${step.max} opções.`;
  }
};
