/* eslint-disable react/no-danger */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination } from 'swiper';
import { PieChart } from 'react-minimal-pie-chart';
import { useQueries } from 'react-query';
import moment from 'moment';
import {
  Badge,
  CashbackTransaction,
  Container,
  ContainerExtract,
  ContainerStep,
  Date,
  DetailsButtonTransaction,
  EmptyTransactions,
  Extract,
  ExtractTotals,
  HeaderCashback,
  InfosTransaction,
  Terms,
  TermsMobile,
  TotalLine,
  TotalTransaction,
  Transaction,
  Transactions,
  TransactionsFilters,
  TransactionWrapper,
} from './style';
import whatsIsCashbackIcon from '../../assets/whatsiscashback.png';
import howEarnCreditsIcon from '../../assets/howearncredits.svg';
import howUseCreditsIcon from '../../assets/howusecredits.svg';
import { ButtonNoStyle } from '../../util/style';
import { useInternalContext } from '../../Context';
import { Button } from '../../components/button';
import { getUser, toBlr } from '../../util';
import {
  fetchCashbackConfiguration,
  fetchCashbackExtract,
} from '../../service';
import Loading from '../../components/loading';
import { ModalOrder } from '../../components/modalOrder';

const infos = [
  {
    title: 'O que é Cashback?',
    description: `Parte do valor de compra volta como créditos para você usar como desconto em outros pedidos`,
  },
  {
    title: 'Como ganhar créditos?',
    description: `Na compra de qualquer produto você ganha
    <strong>??% do valor de volta</strong> para usar em próximos
    pedidos. Exceto para pagamentos feito com Cashback.`,
  },
  {
    title: 'Como utilizar meus créditos?',
    description: `Os créditos podem ser utilizados ao escolher na escolha da forma
    de pagamento no fechamento do pedido`,
  },
];

const pathIcons = index => {
  const objectPaths = {
    0: whatsIsCashbackIcon,
    1: howEarnCreditsIcon,
    2: howUseCreditsIcon,
  };
  return objectPaths[index];
};

const Cashback = () => {
  const history = useHistory();
  const { search } = useLocation();
  const swiperRef = useRef();
  const [showExplanation, setShowExplanation] = useState(
    search.includes('explanation')
  );
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState(null);
  const [orderDetail, setOrderDetail] = useState(null);

  const {
    config: { textBaseColor, headerBackgroundColor },
    isMobile,
    user,
  } = useInternalContext();

  const [
    { data, isFetched, isLoading },
    {
      data: cashbackConfigs,
      isLoading: isLoadingCashbackConfigs,
      isFetched: isFetchedCashbackConfigs,
    },
  ] = useQueries([
    {
      queryKey: 'cashback_extract',
      queryFn: () => fetchCashbackExtract({ token: user?.token }),
      enabled: !!user,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'cashback',
      queryFn: () => fetchCashbackConfiguration({ token: user?.token }),
      enabled: !!user,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  ]);

  const dataPieChart = useMemo(() => {
    if (!data || !cashbackConfigs) return [];
    const { totalInputs, totalOutputs } = data;
    const currentValue = cashbackConfigs.currentCustomerValue;

    const isEmptyData =
      currentValue === 0 && totalInputs === 0 && totalOutputs === 0;

    if (isEmptyData) {
      return [];
    }

    return [
      {
        title: 'Saldo',
        value: currentValue,
        color: '#1590E9',
      },
      {
        title: 'Entradas',
        value: totalInputs,
        color: 'green',
      },
      {
        title: 'Saídas',
        value: totalOutputs,
        color: 'red',
      },
    ];
  }, [data, cashbackConfigs]);

  const nextStep = () => {
    const lastSlideIndex = infos.length - 1;
    const currentSlideIndex = swiperRef.current?.activeIndex;

    if (isMobile && currentSlideIndex < lastSlideIndex) {
      swiperRef.current.slideNext();
      return;
    }

    setShowExplanation(false);
  };

  useEffect(() => {
    if (data?.orders) {
      if (filter) {
        setTransactions(
          filter === 'inputs'
            ? data.orders.filter(
                transaction => transaction.creationType === 'STORE'
              )
            : data.orders.filter(
                transaction => transaction.creationType === 'BUYER'
              )
        );
        return;
      }
      setTransactions(data.orders);
    }
  }, [data, filter]);

  useEffect(() => {
    const { id: userId } = getUser();
    if (showExplanation) {
      localStorage.setItem(`userSeenExplanation-${userId}`, `true`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    isLoading ||
    isLoadingCashbackConfigs ||
    !isFetchedCashbackConfigs ||
    !isFetched
  ) {
    return <Loading />;
  }

  if (showExplanation) {
    return (
      <Container bulletColor={headerBackgroundColor}>
        <HeaderCashback>
          <ButtonNoStyle type="button" title="Voltar" onClick={history.goBack}>
            <FiArrowLeft size={30} />
          </ButtonNoStyle>
          <h1>Cashback</h1>
        </HeaderCashback>
        <Swiper
          onSwiper={swiper => {
            swiperRef.current = swiper;
          }}
          spaceBetween={50}
          modules={[Pagination]}
          slidesPerView={1}
          pagination={{ clickable: true }}
          allowTouchMove={!!isMobile}
          breakpoints={{
            1000: {
              slidesPerView: 3,
            },
          }}
          style={{ padding: '3rem' }}
        >
          {infos.map((info, index) => (
            <SwiperSlide key={info.title}>
              <ContainerStep>
                <Badge
                  backgroundColor={headerBackgroundColor}
                  color={textBaseColor}
                >
                  <span style={{ position: 'relative', top: '-2px' }}>
                    {index + 1}
                  </span>
                </Badge>
                <img src={pathIcons(index)} alt="" />
                <h2>{info.title}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: info.description.replace(
                      '??',
                      cashbackConfigs.divValue
                    ),
                  }}
                />
              </ContainerStep>
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
          type="button"
          onClick={nextStep}
          style={{ padding: '0.5rem 7rem' }}
        >
          Próximo
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      <ModalOrder
        isOpen={orderDetail}
        setIsOpen={setOrderDetail}
        order={orderDetail}
      />
      <HeaderCashback>
        <ButtonNoStyle type="button" title="Voltar" onClick={history.goBack}>
          <FiArrowLeft size={30} />
        </ButtonNoStyle>
        <h1>Cashback</h1>
      </HeaderCashback>
      {cashbackConfigs?.terms && (
        <TermsMobile>
          <a
            style={{ fontSize: '1rem', fontWeight: 500 }}
            href={cashbackConfigs.terms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos de Uso
          </a>
        </TermsMobile>
      )}

      <ContainerExtract>
        <Extract
          isEmpty={transactions.length < 0}
          borderColor={headerBackgroundColor}
        >
          {dataPieChart.length > 0 && (
            <PieChart
              data={dataPieChart}
              lineWidth={50}
              style={{
                width: isMobile ? 150 : 250,
                height: isMobile ? 150 : 250,
                marginBottom: isMobile ? '1rem' : 0,
              }}
            />
          )}
          <ExtractTotals>
            <TotalLine>
              <span>Saldo</span>
              <span style={{ color: '#1590E9' }}>
                {toBlr(cashbackConfigs?.currentCustomerValue || 0)}
              </span>
            </TotalLine>
            <hr />
            <TotalLine>
              <span>Entradas</span>
              <span style={{ color: 'green' }}>
                + {toBlr(data.totalInputs)}
              </span>
            </TotalLine>
            <TotalLine>
              <span>Saídas</span>
              <span style={{ color: 'red' }}>- {toBlr(data.totalOutputs)}</span>
            </TotalLine>
          </ExtractTotals>
        </Extract>

        <Transactions>
          <TransactionsFilters>
            <ButtonNoStyle
              style={{ borderBottom: filter === null && '2px solid' }}
              type="button"
              onClick={() => setFilter(null)}
            >
              Todas
            </ButtonNoStyle>
            <ButtonNoStyle
              style={{ borderBottom: filter === 'inputs' && '2px solid' }}
              type="button"
              onClick={() => setFilter('inputs')}
            >
              Entradas
            </ButtonNoStyle>
            <ButtonNoStyle
              style={{ borderBottom: filter === 'outputs' && '2px solid' }}
              type="button"
              onClick={() => setFilter('outputs')}
            >
              Saídas
            </ButtonNoStyle>
          </TransactionsFilters>

          <TransactionWrapper>
            {transactions.length === 0 && (!filter || filter === 'inputs') && (
              <EmptyTransactions>
                <span>Você não tem pedidos com cashback ainda</span>
                <Button
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Fazer pedido
                </Button>
              </EmptyTransactions>
            )}
            {transactions.length === 0 && filter === 'outputs' && (
              <EmptyTransactions>
                <span>
                  Você não tem pedidos resgatando um valor de cashback ainda
                </span>
                <Button
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Fazer pedido
                </Button>
              </EmptyTransactions>
            )}
            {transactions.map(order => (
              <Transaction key={order.orderId}>
                <Date>
                  {moment(order.createdDateOrder).format('DD/MM/YYYY')}
                </Date>
                <InfosTransaction>
                  <TotalTransaction>
                    <span>Total da compra</span>
                    <span style={{ fontWeight: '600' }}>
                      {toBlr(order.totalOrder)}
                    </span>
                  </TotalTransaction>
                  <CashbackTransaction>
                    <span>Cashback</span>
                    <span
                      style={{
                        color: order.creationType === 'STORE' ? 'green' : 'red',
                        fontWeight: '600',
                      }}
                    >
                      {order.creationType === 'STORE' ? '+' : '-'}{' '}
                      {toBlr(order.movementValue)}
                    </span>
                  </CashbackTransaction>
                  <DetailsButtonTransaction
                    onClick={() => {
                      setOrderDetail({
                        ...order,
                        cashback:
                          order.creationType === 'BUYER'
                            ? order.movementValue
                            : 0,
                      });
                    }}
                  >
                    Detalhes
                  </DetailsButtonTransaction>
                </InfosTransaction>
              </Transaction>
            ))}
          </TransactionWrapper>
        </Transactions>
      </ContainerExtract>
      {cashbackConfigs?.terms && (
        <Terms>
          <a
            style={{ fontSize: '1rem', fontWeight: 500 }}
            href={cashbackConfigs.terms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos de Uso
          </a>
        </Terms>
      )}
    </Container>
  );
};

export default Cashback;
