import React, { useEffect, useState } from 'react';
import { Row, Col, Input, FormText } from 'reactstrap';
import { isValid, isExpirationDateValid } from 'creditcard.js';
import { PatternFormat } from 'react-number-format';

import { captureException } from '@sentry/react';
import { NewAddress } from '../../components/address';
import { Button } from '../../components/button';
import { fetchAddressInformation } from '../../service';
import { validateCpf } from '../../util';

const formatCardNumber = value => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (_, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter(group => !!group).join(' ')
  );
};

const CreditCardSection = ({
  onSaveAddress,
  billingAddress,
  setNumberError,
  card,
  setCVVError,
  updateCardInfo,
  validationCreditCard,
  cpfCreditCard,
  setCpfCreditCard,
}) => {
  const [creditCardNumber, setCreditCardNumber] = useState(
    formatCardNumber(card?.number || '')
  );
  const [creditCardExpiry, setCreditCardExpiry] = useState(card?.expiry || '');
  const [creditCardName, setCreditCardName] = useState(card?.name || '');
  const [creditCardCVV, setCreditCardCVV] = useState(card?.cvv || '');
  const [addressInformation, setAddressInformation] = useState({});
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [credtCardNumberError, setCreditCardNumberError] = useState(false);
  const [credtCardExpiryError, setCreditCardExpiryError] = useState(false);
  const [creditCardCPFInvalid, setCreditCardCPFInvalid] = useState(false);
  const [isAmexCard, setIsAmexCard] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setNumberError(credtCardNumberError), [credtCardNumberError]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCVVError(credtCardExpiryError), [credtCardExpiryError]);

  const formatCardExpiry = value => {
    const regex = /^(\d{0,2})(\d{0,2})$/g;
    const onlyNumbers = value.replace(/[^\d]/g, '');

    return onlyNumbers.replace(regex, (_, $1, $2) =>
      [$1, $2].filter(group => !!group).join('/')
    );
  };

  const loadAddressFromCep = async zipCode => {
    setLoadingCep(true);
    const addressInformationData = await fetchAddressInformation(zipCode)
      .then(addressInformationDB => {
        return {
          ...addressInformationDB,
          canEditStreet:
            !addressInformationDB.street ||
            addressInformationDB.street.trim() === '',
          street: addressInformationDB?.street?.trim() || '',
          number: '',
          complement: '',
        };
      })
      .catch(err => {
        captureException(err);
        return {
          error: 'Cep não encontrado',
        };
      });
    setLoadingCep(false);
    setAddressInformation(addressInformationData);
  };

  const validateCreditCard = () =>
    setCreditCardNumberError(!isValid(creditCardNumber));

  const validateCreditCardExpiryDate = () =>
    setCreditCardExpiryError(
      !isExpirationDateValid(
        creditCardExpiry.split('/')[0],
        creditCardExpiry.split('/')[1]
      )
    );

  const validateAmexCard = value => {
    const regexAmex = /^3[47]\d{13}$/;
    const onlyNumbers = value.replace(/[^\d]/g, '');

    if (regexAmex.test(onlyNumbers)) {
      setIsAmexCard(true);
    } else {
      setIsAmexCard(false);
    }
  };

  return (
    <form>
      <NewAddress
        createAddress={event => {
          event.preventDefault();
          onSaveAddress(addressInformation);
          setIsBillingModalOpen(false);
        }}
        isOpen={isBillingModalOpen}
        onChange={field => value => {
          if (
            field === 'cep' &&
            value.replace('_', '').replace('-', '').length === 8
          ) {
            setAddressInformation(prevState => ({
              ...prevState,
              zip: value,
            }));
            const parsedCep = value.replace('_', '').replace('-', '');
            loadAddressFromCep(parsedCep);
          }
          if (field !== 'cep') {
            setAddressInformation(prevState => ({
              ...prevState,
              [field]: value,
            }));
          }
        }}
        addressInformation={addressInformation}
        toggle={() => setIsBillingModalOpen(false)}
        loadingCep={loadingCep}
      />

      <Row style={{ marginTop: 28 }}>
        <Col xs={12}>
          <span style={{ fontWeight: 'bold' }}>
            Informe os dados do cartão de crédito
          </span>
        </Col>
        <Col xs={12} style={{ marginTop: 12 }}>
          <Input
            name="name"
            maxLength={45}
            autoComplete="cc-name"
            value={creditCardName}
            placeholder="Titular do cartão"
            className="credit-card-input"
            onChange={({ target: { name, value } }) => {
              updateCardInfo(name, value);
              setCreditCardName(value);
            }}
          />
        </Col>
        <Col xs={12} style={{ marginTop: 4 }}>
          <Input
            name="number"
            onChange={({ target: { name, value } }) => {
              if (value.length === 20) return;
              setCreditCardNumber(formatCardNumber(value));
              updateCardInfo(name, value);
              validateAmexCard(value);
            }}
            autoComplete="cc-number"
            type="tel"
            maxLength={23}
            value={creditCardNumber}
            onBlur={validateCreditCard}
            placeholder="Número do cartão de crédito"
            className="credit-card-input"
          />
          {credtCardNumberError && (
            <FormText color="red">Número do cartão é inválido</FormText>
          )}
        </Col>
        <Col xs={6} style={{ marginTop: 4 }}>
          <Input
            name="expiry"
            type="tel"
            maxLength={5}
            onChange={({ target: { value, name } }) => {
              setCreditCardExpiry(formatCardExpiry(value));
              updateCardInfo(name, value);
            }}
            value={creditCardExpiry}
            placeholder="VENC. Mês/Ano"
            className="credit-card-input"
            onBlur={validateCreditCardExpiryDate}
          />
          {credtCardExpiryError && (
            <FormText color="red">Informe uma data válida</FormText>
          )}
        </Col>
        <Col xs={6} style={{ marginTop: 4 }}>
          <Input
            name="cvv"
            type="tel"
            value={creditCardCVV}
            onChange={({ target: { value, name } }) => {
              setCreditCardCVV(value);
              updateCardInfo(name, value);
            }}
            maxLength={isAmexCard ? 4 : 3}
            placeholder="CVV"
            className="credit-card-input"
          />
        </Col>
        {validationCreditCard && (
          <>
            <Col xs={12} style={{ marginTop: 4 }}>
              <span style={{ fontWeight: 'bold' }}>
                Informe o CPF do titular do cartão
              </span>
            </Col>
            <Col xs={12} style={{ marginTop: 4 }}>
              <PatternFormat
                format="###.###.###-##"
                mask="_"
                value={cpfCreditCard}
                onChange={({ target: { value } }) => setCpfCreditCard(value)}
                onBlur={() =>
                  validateCpf(
                    cpfCreditCard.replaceAll('-', '').replaceAll('.', '')
                  )
                    ? setCreditCardCPFInvalid(false)
                    : setCreditCardCPFInvalid(true)
                }
                customInput={Input}
              />
              {creditCardCPFInvalid && (
                <FormText color="red">CPF Inválido</FormText>
              )}
            </Col>
          </>
        )}
        <Col xs={12} style={{ marginTop: 4 }}>
          <span style={{ fontWeight: 'bold' }}>
            Informe o endereço de cobrança do cartão de crédito
          </span>
        </Col>
        <Col xs={12} style={{ marginTop: 4 }}>
          {!billingAddress && (
            <Button
              outline
              onClick={() => setIsBillingModalOpen(oldValue => !oldValue)}
            >
              Novo endereço
            </Button>
          )}
          {billingAddress && (
            <>
              <span style={{ fontWeight: 'bold' }}>Endereço de cobrança: </span>
              <span>{`${billingAddress.street}, ${billingAddress.number} `}</span>
              <Button
                outline
                onClick={() => setIsBillingModalOpen(oldValue => !oldValue)}
              >
                Trocar endereço
              </Button>
            </>
          )}
        </Col>
      </Row>
    </form>
  );
};
export default CreditCardSection;
